import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowLeft from "../../assets/components-icons/ArrowLeft";
import ArrowSmall from "../../assets/components-icons/ArrowSmall";

const BackButton = () => {
  const history = useHistory();
  const location = useLocation();
  const disable = location.pathname === "/" ? " display-none" : "";
  return (
    <button
      className={`AloharBButton${disable}`}
      onClick={() => history.goBack()}
    >
      <ArrowSmall color={"#FF4E00 "} size={"30px"}/>
    </button>
  );
};

export default BackButton;
