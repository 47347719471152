export const seasonValidateForm = (form) => {
  let errors = {};
  if (form.description.length >= 15) {
    errors.description =
      "La descripcion debe tener un maximo de 15 caracteres.";
  }
  if (form.description.length < 1) {
    errors.description = "La temporada debe tener una descripcion.";
  }
  if (form.percentage <= 0) {
    errors.description = "Debe ser un porcentaje valido";
  }
  return errors;
};
