// Librerías
import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/Route/PrivateRoute";
import AdminRoute from "./components/Route/AdminRoute";

// Auth
import SocialLoginPage from "./containers/SocialLoginPage";
import RegisterContainer from "./containers/Users/RegisterContainer";
import LoginContainer from "./containers/Users/LoginContainer";
import ConfirmPage from "./containers/Users/EmailConfirm";
// Recover
import UserChangePassword from "./containers/Users/ChangePassword";

// Public Routes
import RentLanding from "./containers/RentLanding";
import SearchPage from "./containers/SearchPage";
import Intermediate from "./containers/IntermediateContainer";
import PropertyPage from "./containers/PropertyPage";
import PublicProfilePage from "./containers/PublicProfilePage";
import Checkout from "./containers/CheckoutContainer";
import CheckoutComponentMobile from "./components/Checkout/CheckoutComponentMobile";
import FAQ from "./containers/FAQ";
import FAQMobile from "../src/components/FAQ/FAQComponentMobile";
import Terms from "./containers/Terms";
import BaproLegals from "./containers/BaproLegals";
import Error404 from "./containers/Error404";

// Componentes
import Alohar from "./containers/Alohar";
import EditAloharPage from "./containers/EditAloharPage";
import UserProfilePage from "./containers/UserProfilePage";
import UserEdit from "./containers/UserEdit";
import UserTravelsPage from "./containers/UserTravelsPage";
import UserFavoritesPage from "./containers/Users/UserFavoritesPage";
import UploadDocument from "./containers/UploadDocumentContainer";

import SocialLoginContainer from "./containers/Users/SocialLoginContainer";

import MyHousingsPage from "./containers/MyHousingsPage";
import MessengersPage from "./containers/Users/MessengersPage";
import Confirmed from "./containers/ConfirmedContainer";
import ConfirmedMobile from "./containers/ConfirmedContainerMobile";
import ConfirmedContainerAlohador from "./containers/ConfirmedContainerAlohador";
import ConfirmReservations from "./containers/ConfirmReservations";
import MercadoPagoConfirmed from "./containers/MercadoPagoConfirmed";

// Valuations
import ValuationContainer from "./containers/ValuationContainer";

// ----
// BackOffice Routes Starts
// ----
import Dashboard from "./containers/BackOffice/Dashboard";
// Reservations Resources
import AdminHousing from "./containers/BackOffice/Housing/Housing";
import AdminHousingView from "./containers/BackOffice/Housing/View";
import AdminHousingTransfer from "./containers/BackOffice/Housing/Transfer";
import AdminHousingStatusPoints from "./containers/BackOffice/Housing/StatusPointsPage";
import AdminPreviaje from "./containers/BackOffice/Housing/Previaje";
// Users Resources
import AdminUsers from "./containers/BackOffice/Users/Users";
import AdminUser from "./containers/BackOffice/Users/User";
import AdminUserEdit from "./containers/BackOffice/Users/Edit";
import TransferReservation from "./containers/BackOffice/TransferReservations";
// Locations Resources
import AdminLocations from "./containers/BackOffice/Locations/Locations";
import AdminLocationsCreate from "./containers/BackOffice/Locations/Create";
import AdminLocationsEdit from "./containers/BackOffice/Locations/Edit";
// Reservations Resources
import AdminReservations from "./containers/BackOffice/Reservations/Reservations";
import AdminConfirmedReservations from "./containers/BackOffice/Reservations/ConfirmedReservations";
import AdminNewLinks from "./containers/BackOffice/Reservations/NewLinksContainer";
import AdminCancel from "./containers/BackOffice/Reservations/CancelContainer";
// ----
// BackOffice Routes End
// ----
// Para cuando estamos offline
import Working from "./containers/Working";
import SuccessReservation from "./containers/landings/success-reservation-googleTag";
import SuccessRegister from "./containers/landings/success-register";
import ThankYouPageRegister from "./containers/landings/than-you-page-register.js";
import Editorial from "./containers/editorial";
import TermsConditions from "./components/terms-conditions";
import WhitenPassword from "./containers/BackOffice/whiten-password";
import ViewMessages from "./containers/BackOffice/view-messages";
import LandingHome from "./components/LandingPage";
import AloharCreateApp from "./containers/AloharCreateApp.js";
import MyHousingsPageAPP from "./containers/MyHousingsPageAPP.js";
import EditAloharPageApp from "./containers/EditAloharPageApp.js";
import Pricing from "./components/LandingPage/PricingApp/index.js";
import PricingApp from "./components/LandingPage/PricingApp/index.js";

import BackOfficeHotSale from "./containers/BackOffice/hot-sale/index.js";
import ReservationSuccess from "./containers/reservation-success/index.js";

const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={"/working"} component={Working} />
        <Route exact path={"/"} component={LandingHome} />
        <Route exact path={"/terminos"} component={Terms} />
        <Route exact path={"/bapro"} component={BaproLegals} />
        <Route exact path={"/sociallogin"} component={SocialLoginPage} />
        {/* onlyapp */}
        <Route exact path={`/alohar_create_app`} component={AloharCreateApp} />
        <Route
          exact
          path={`/app/profile/housing`}
          component={MyHousingsPageAPP}
        />
        <Route
          exact
          path={`/app/edit/:editStep/:id`}
          component={EditAloharPageApp}
        />
        <Route exact path={`/app/pricing/:housingId`} component={PricingApp} />
        {/* onlyapp */}
        <Route
          exact
          path={"/paymentonlymobile"}
          component={CheckoutComponentMobile}
        />
        <Route
          exact
          path={"/confirmedMobile/:id"}
          component={ConfirmedMobile}
        />
        {/* <PrivateRoute exact path={`/editorial`} component={Editorial} /> */}
        {/* <PrivateRoute exact path={`/editorial`} component={Editorial} /> */}
        <PrivateRoute exact path={`/alohar`} component={Alohar} />
        <PrivateRoute
          exact
          path={`/edit/:editStep/:id`}
          component={EditAloharPage}
        />
        <PrivateRoute exact path={`/pricing/:housingId`} component={Pricing} />
        <Route exact path={`/quiero-ser-alohador`} component={RentLanding} />
        <PrivateRoute
          exact
          path="/profile"
          restricted
          component={UserProfilePage}
        />
        <Route exact path={`/users/show/:id`} component={PublicProfilePage} />
        {/* <PrivateRoute exact path={`/profile/editar/`} restricted component={UserEditProfilePage} /> */}
        <PrivateRoute
          exact
          path={`/profile/edit/`}
          restricted
          component={UserEdit}
        />
        <PrivateRoute
          exact
          path={`/profile/password`}
          component={UserChangePassword}
        />
        <PrivateRoute exact path={`/profile/dni`} component={UploadDocument} />
        <PrivateRoute
          exact
          path={`/profile/travels`}
          component={UserTravelsPage}
        />
        <PrivateRoute
          exact
          path={`/profile/favorites`}
          component={UserFavoritesPage}
        />
        <PrivateRoute
          exact
          path={`/profile/housing`}
          component={MyHousingsPage}
        />
        <PrivateRoute exact path={`/messengers`} component={MessengersPage} />
        <PrivateRoute
          exact
          path={`/housing/reservations/:id`}
          restricted
          component={ConfirmReservations}
        />
        <PrivateRoute
          exact
          path={`/alohador/reservations/:id`}
          restricted
          component={ConfirmedContainerAlohador}
        />
        <Route
          exact
          path={`/valuation/:type/:token`}
          component={ValuationContainer}
        />

        <Route
          exact
          path={`/adentrate/geolocralizacion`}
          component={Editorial}
        />
        <Route exact path={`/:id/search/`} component={SearchPage} />
        <Route
          exact
          path={`/:cityName/property/:id`}
          component={PropertyPage}
        />
        <Route exact path={`/terms-conditions`} component={TermsConditions} />
        <Route exact path={`/terms-privacy`} component={TermsConditions} />

        <Route exact path={`/property/:id`} component={PropertyPage} />
        <Route exact path={`/intermediate-step/:id`} component={Intermediate} />
        <PrivateRoute
          exact
          path={`/property/:id/checkout`}
          restricted
          component={Checkout}
        />
        <PrivateRoute
          exact
          path={`/reservation-success`}
          component={ReservationSuccess}
        />
        <PrivateRoute exact path={`/confirmed/:id`} component={Confirmed} />
        <PrivateRoute
          exact
          path={`/mp-confirmed`}
          component={MercadoPagoConfirmed}
        />
        <Route exact path={"/ayuda"} component={FAQ} />
        <Route exact path={"/ayudaMobile"} component={FAQMobile} />
        <Route exact path={`/login/`} component={LoginContainer} />
        <Route exact path={`/sociallogin/`} component={SocialLoginContainer} />
        <Route exact path={`/register/`} component={RegisterContainer} />
        <Route exact path={`/confirm/`} component={ConfirmPage} />
        <Route exact path={`/recover/`} component={UserChangePassword} />
        <Route
          exact
          path={`/transferencia-reserva-confirmada`}
          component={SuccessReservation}
        />
        <Route
          exact
          path={`/debito-reserva-confirmada`}
          component={SuccessReservation}
        />
        <Route
          exact
          path={`/credito-reserva-confirmada`}
          component={SuccessReservation}
        />
        <Route exact path={`/register-success`} component={SuccessRegister} />
        <Route
          exact
          path={`/gracias-registro-completo`}
          component={ThankYouPageRegister}
        />
        {/* ---------- */}
        {/* BackOffice */}
        {/* ---------- */}
        <AdminRoute
          exact
          path={`/back-office/whiten-password`}
          component={WhitenPassword}
        />
        <AdminRoute
          exact
          path={`/back-office/view-messages`}
          component={ViewMessages}
        />
        <AdminRoute
          exact
          path={`/back-office/hot-sale`}
          component={BackOfficeHotSale}
        />
        <AdminRoute exact path={`/back-office/`} component={Dashboard} />
        {/* Users */}

        <PrivateRoute
          exact
          path={`/back-office/users`}
          component={AdminUsers}
        />
        <PrivateRoute
          exact
          path={`/back-office/users/:id`}
          component={AdminUser}
        />
        <PrivateRoute
          exact
          path={`/back-office/users/edit/:id`}
          component={AdminUserEdit}
        />
        {/* Housing */}
        <PrivateRoute
          exact
          path={`/back-office/transfer`}
          component={TransferReservation}
        />
        <PrivateRoute
          exact
          path={`/back-office/housing`}
          component={AdminHousing}
        />
        <PrivateRoute
          exact
          path={`/back-office/housing/:id`}
          component={AdminHousingView}
        />
        <PrivateRoute
          exact
          path={`/back-office/housing/transfer/:id`}
          component={AdminHousingTransfer}
        />
        <PrivateRoute
          exact
          path={`/back-office/housing/status-points/:id`}
          component={AdminHousingStatusPoints}
        />
        <PrivateRoute
          exact
          path={`/back-office/housing/previaje/:id`}
          component={AdminPreviaje}
        />
        {/* Locations */}
        <PrivateRoute
          exact
          path={`/back-office/locations`}
          component={AdminLocations}
        />
        <PrivateRoute
          exact
          path={`/back-office/locations/create`}
          component={AdminLocationsCreate}
        />
        <PrivateRoute
          exact
          path={`/back-office/locations/edit/:id`}
          component={AdminLocationsEdit}
        />
        {/* Reservations */}
        <PrivateRoute
          exact
          path={`/back-office/reservations`}
          component={AdminReservations}
        />
        <PrivateRoute
          exact
          path={`/back-office/reservations/confirmed`}
          component={AdminConfirmedReservations}
        />
        <PrivateRoute
          exact
          path={`/back-office/reservations/nuevos-links/:id`}
          component={AdminNewLinks}
        />
        <PrivateRoute
          exact
          path={`/back-office/reservations/cancel/:id`}
          component={AdminCancel}
        />
        <PrivateRoute
          exact
          path={`/back-office/reservations/cancel-transfer/:id`}
          component={AdminCancel}
        />
        {/* Esto siempre al final */}
        <Route path="*" component={Error404} />
      </Switch>

      {/* <Version frontend="06/11/2020" backend="05/11/2020" /> */}
    </React.Fragment>
  );
};

export default Routes;
