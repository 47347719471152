// Librerías
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { BASE_URL } from "../../../Helpers";
import AxiosAuth from "../../../services/requestApi";
// Componentes
import MainContainer from "../Utils/MainContainer";
import Pill from "../Utils/Pill";
import PaginationBackOffice from "../pagination";
import LoadingGift from "../../../assets/components-icons/LoadingGift";

const columns = [
  {
    name: "ID",
    selector: (row) => row["idReserva"],
  },
  {
    name: "Estado",
    cell: (row) => <Pill status={row.status} />,
  },
  {
    name: "Código",
    selector: (row) => row["locator"],
  },
  {
    name: "operationId",
    selector: (row) => row["mobbexOperationId"],
  },
  {
    name: "Fecha de Reserva",
    selector: (row) => row["issued"],
  },
  {
    name: "Check In",
    cell: (row) => ` ${moment(row.chackin).format("DD-MM-YYYY")}`,
  },
  {
    name: "Check Out",
    cell: (row) => ` ${moment(row.checkout).format("DD-MM-YYYY")}`,
  },
  {
    name: "Noches",
    selector: (row) => row["nights"],
  },
  {
    name: "Día de Pago",
    cell: (row) => ` ${moment(row.diaPago).format("DD-MM-YYYY")}`,
  },
  {
    name: "Precio Base",
    cell: (row) => `$ ${row.precioBase}`,
  },
  {
    name: "Limpieza",
    cell: (row) => `$ ${row.limpieza}`,
  },
  {
    name: "FEE Huésped",
    cell: (row) => `$ ${row.tarifaHuesped}`,
  },
  {
    name: "FEE Alohador",
    cell: (row) => `$ ${row.tarifaHost}`,
  },
  {
    name: "Desc semanal",
    cell: (row) => `$ ${row.descSemana !== null ? row.descSemana : "0"} `,
  },
  {
    name: "Desc mensual",
    cell: (row) => `$ ${row.descMes !== null ? row.descMes : "0"}`,
  },
  {
    name: "Desc cupones",
    cell: (row) => `$ ${row.descCupon}`,
  },
  {
    name: "Desc transfer",
    cell: (row) => `$ ${row.descuentoPorTransferencia}`,
  },

  // {
  //   name: "Precio Total transfer",
  //   cell: (row) => `$ ${row.precioTotalConDescuento}`,
  // },
  {
    name: "Precio Total",
    cell: (row) => `$ ${row.precioTotal}`,
  },
  {
    name: "Precio Con Int",
    cell: (row) => `$ ${row.precioConIntereses}`,
  },
  {
    name: "Costo fin",
    cell: (row) => `$ ${row.costoFin}`,
  },
  {
    name: "Pago al Alohador",
    cell: (row) => `$ ${row.pagarAlHost}`,
  },
  {
    name: "Housing ID",
    selector: (row) => row["housingId"],
  },
  {
    name: "ID Huésped",
    selector: (row) => row["guestId"],
  },
  {
    name: "Huésped",
    selector: (row) => row["guestName"],
  },
  {
    name: "Mail Huésped",
    selector: (row) => row["guestMail"],
  },
  {
    name: "Cel Huésped",
    selector: (row) => row["guestPhone"],
  },
  {
    name: "CUIT Huésped",
    selector: (row) => row["guestCuit"],
  },
  {
    name: "Host",
    selector: (row) => row["hostName"],
  },
  {
    name: "Host Mail",
    selector: (row) => row["hostMail"],
  },
  {
    name: "Cel Host",
    selector: (row) => row["hostPhone"],
  },
  {
    name: "CUIT Host",
    selector: (row) => row["hostCuit"],
  },
  // {
  //   name: "Dirección de Facturación",
  //   selector: (row) => row["billingAddress"],
  // },
  {
    name: "CBU Host",
    selector: (row) => row["hostCbu"],
  },
  {
    name: "Banco",
    selector: (row) => row["hostBank"],
  },
  {
    name: "Tipo de Reserva",
    selector: (row) => row["reservationType"],
  },
];


const ConfirmedReservationList = (props) => {
  const [nextPage, setNextPage] = useState({
    page: 1,
    perPage: 10,
  });
  const [loading, setLoading] = useState({
    loading: false,
    csv: false,
  });
  const [reservations, setReservations] = useState([]);
  const [filter, setFilter] = useState();
  const [q] = useState("");
  const [searchColumns] = useState([
    "idReserva",
    "locator",
    "guestName",
    "hostName",
  ]);
  const getReservationsConfirm = () => {
    setLoading(true);
    const url = `${BASE_URL}/backoffice/reservations/all-reservations-confirmed?page=${nextPage.page}&perPage=${nextPage.perPage}`;
    AxiosAuth.get(url)
      .then((response) => {
        const props = response.data;
        setReservations(props);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        // router.push("/");
        console.log(e);
      });
  };
  useEffect(() => {
    getReservationsConfirm();
  }, [nextPage]);

  const search = (rows) => {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] !== null &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  const convertArrayOfObjectsToCSV = (data) => {
    if (data == null || !data.length) {
      return null;
    }

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    const keys = Object.keys(data[0]);

    let result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
      keys.forEach((key, index) => {
        if (index > 0) {
          result += columnDelimiter;
        }

        let value = item[key];
        if (value === undefined || value === null) {
          value = "null"; // Reemplazar con 'null' si es necesario
        }

        result += value;
      });
      result += lineDelimiter;
    });

    return result;
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  const downloadCSV = () => {
    setLoading({
      ...loading,
      csv: true,
    });
    const url = `${BASE_URL}/backoffice/download-reservations?status=confirm`;
    AxiosAuth.get(url)
      .then((response) => {
        const link = document.createElement("a");
        let csv = convertArrayOfObjectsToCSV(response.data);
        if (csv == null || undefined) return;

        const filename = "export.csv";

        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
        setLoading({
          ...loading,
          csv: false,
        });
      })
      .catch((e) => {
        setLoading({
          ...loading,
          csv: false,
        });
      });
    setLoading({
      ...loading,
      csv: false,
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // La tecla "Enter" fue presionada, realizar búsqueda
      setLoading(true);
      if (filter && filter.length > 0) {
        const url = `${BASE_URL}/search-reservation?search=${filter}&page=${nextPage.page}&perPage=${nextPage.perPage}`;
        AxiosAuth.get(url)
          .then((response) => {
            setReservations(response.data);
            setLoading(false);
          })
          .catch((e) => {
            getReservationsConfirm();
            setLoading(false);
            console.log(e);
          });
      }
      if (!filter) {
        getReservationsConfirm();
      }
    }
  };
  return (
    <MainContainer title="Listado de Reservas Confirmadas">
      <div>
        <div className="ActionBar">
          <input
            className="ActionBar__input"
            type="text"
            placeholder="Búsqueda"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <div className="ActionBar__btns">
            <button
              className="w-52 ActionBar__btns__btn"
              onClick={() => downloadCSV()}
            >
              {loading.csv ? <LoadingGift size={25} /> : "Descargar CSV"}
            </button>
          </div>
        </div>
        {!loading.loading ? (
          <>
            <DataTable
              columns={columns}
              data={search(reservations)}
              paginationPerPage={10}
            />
            <PaginationBackOffice
              setNextPage={setNextPage}
              nextPage={nextPage}
            />
          </>
        ) : (
          <LoadingGift size={50} />
        )}
      </div>
    </MainContainer>
  );
};

export default ConfirmedReservationList;
