import React, { useEffect } from "react";
import LazyLoad from "react-lazyload";

const ImageLazyLoad = ({ src, alt, className, classNameContent }) => {
  useEffect(() => {
    const img = new Image();
    img.src = src;
  }, [src]);

  return (
    <LazyLoad offset={200} className={classNameContent}>
      <img src={src} alt={alt} className={`${className}`} loading="lazy" />
    </LazyLoad>
  );
};

export default ImageLazyLoad;