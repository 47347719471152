import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import Navbar from "../../../Navbar/Navbar";
import UserAvatar from "../../../ui/userAvatar";
import BoxItem from "./../../components/box-item";
import ContentTitle from "./../../components/content-title";
import SendMessage from "./../../components/send-message";
import { useEffect } from "react";
import CrossIcon from "../../../../assets/components-icons/CrossIcon";
import SectionDetail from "../../components/section-detail";
import ArrowLeft from "../../../../assets/components-icons/ArrowLeft";
import "./../../messengers-page.sass";
import ArrowSmall from "../../../../assets/components-icons/ArrowSmall";
import { loadUserData } from "../../../../Helpers";

import globalJson from "../../../../utilities/traduccGlobal.json";
import useCurrentLanguage from "../../../../services/currentlenguaje";

const MessaggesMobile = ({ users }) => {
  const userdata = loadUserData();

  const { handleFilterLang } = useCurrentLanguage();

  const router = useHistory();
  const [openDetails, setOpenDetails] = useState(false);
  const [chatMobile, setChatMobile] = useState(false);
  const [housingMobile, setHousingMobile] = useState(false);
  const [usersMobile, setUsersMobile] = useState(true);
  const [refMessages, setRefMessages] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [userHousing, setUserHousing] = useState([]);

  const getUser = (userInfo) => {
    setUserInfo(userInfo);
  };
  const viewDetails = () => {
    if (userHousing.id) {
      setChatMobile(false);
      setOpenDetails(true);
      setHousingMobile(true);
    }
  };
  const viewChat = () => {
    setOpenDetails(false);
    setChatMobile(true);
    setHousingMobile(false);
  };
  const buttonBack = () => {
    if (chatMobile) {
      setChatMobile(false);
      setUsersMobile(true);
    }
    if (housingMobile) {
      setChatMobile(true);
      setHousingMobile(false);
    }
  };
  const refClickUser = useRef(null);

  useEffect(() => {
    if (refClickUser.current) {
      refClickUser.current.focus();
    }
  }, [userHousing]);
  useEffect(() => {
    setOpenDetails(false);
  }, [userInfo]);

  return (
    <div
      className={
        router.location.pathname.includes("host-mode")
          ? "layoutMessagesHost"
          : "layoutMessages "
      }
    >
      <Navbar messages={true} />
      <button
        className={`fixed bg-white left-[20px] z-50 top-[20px]`}
        onClick={() => {
          !chatMobile && !housingMobile ? router.push("/") : buttonBack();
        }}
      >
        <ArrowSmall color={"#FF4E00 "} size={"35px"} />
      </button>
      <div className=" layoutMessages__container">
        {usersMobile && (
          <div className="layoutMessages__container__section ">
            <div className={"layoutMessages__container__section__header"}>
              <ContentTitle title={handleFilterLang(globalJson).mensajes} />
            </div>
            <div className={"layoutMessages__container__section__chats"}>
              {users.map((e) => (
                <BoxItem
                  refMessages={refMessages}
                  getUser={getUser}
                  messageInfo={e.lastMessage}
                  refClickUser={refClickUser}
                  setChatMobile={setChatMobile}
                  setUsersMobile={setUsersMobile}
                />
              ))}
            </div>
          </div>
        )}

        <>
          {chatMobile && (
            <div className="layoutMessages__container__section">
              <div
                onClick={() => viewDetails()}
                className={
                  router.location.pathname.includes("host-mode")
                    ? "layoutMessages__container__section__headerHost"
                    : "layoutMessages__container__section__header  "
                }
              >
                {userInfo && <UserAvatar userData={userInfo} size={40} />}
                <ContentTitle title={userInfo.firstName} />
                <p className="flex items-center gap-1 text-xs ">
                  {handleFilterLang(globalJson).verAlohamiento}
                  <ArrowLeft size={16} className={"rotate-180"} />
                </p>
              </div>
              <SendMessage
                user={userInfo}
                setUserHousing={setUserHousing}
                refClickUser={refClickUser}
                setRefMessages={setRefMessages}
              />
            </div>
          )}

          {housingMobile && (
            <div
              className={
                openDetails
                  ? "layoutMessages__container__section"
                  : "layoutMessages__container__sectionDetail"
              }
            >
              {openDetails && (
                <>
                  <div
                    className={
                      router.location.pathname.includes("host-mode")
                        ? "layoutMessages__container__section__headerHost"
                        : "layoutMessages__container__section__header"
                    }
                  >
                    <div
                      className="layoutMessages__container__section__header__icon"
                      onClick={() => viewChat()}
                    >
                      <CrossIcon />
                    </div>
                    <ContentTitle
                      title={handleFilterLang(globalJson).detalles}
                    />
                  </div>
                  <SectionDetail
                    userHousing={userHousing}
                    userInfo={userInfo}
                  />
                </>
              )}
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default MessaggesMobile;
