import React from "react";
import BackButton from "./components/Buttons/BackButton";
import Routes from "./Routes";
import { useHistory } from "react-router";
import { useEffect } from "react";
import "../src/assets/styles/index.css";
import { BASE_URL } from "./Helpers";
const App = () => {
  console.log("BASE_URL");
  console.log(BASE_URL);

  const router = useHistory();
  useEffect(() => {
    const staticContent = document.getElementById("estatic-home");
    if (staticContent) {
      staticContent.innerHTML = "";
    }
    // if (router.location.pathname.includes("amigos")) {
    //   const newUrl = router.location.pathname.replace("/amigos", "");
    //   router.push(newUrl);
    //   if (
    //     router.location.pathname.includes("property") ||
    //     router.location.pathname.includes("search")
    //   ) {
    //     router.push("/");
    //   }
    // }
  }, []);

  useEffect(() => {
    const role = window.localStorage.getItem("role");
    if (!role) {
      const roleString = JSON.stringify({ role: "user" });
      window.localStorage.setItem("role", roleString);
    }
  }, []);

  return (
    <>
      {/* <BackButton /> */}
      <Routes />
    </>
  );
};

export default App;
