// Librerías
import React from 'react';
import StarRatings from 'react-star-ratings';
// Recursos
import '../../../assets/styles/Valuation/utils/__starRatings.sass';

class StarsRating extends React.Component {

  constructor(props) {
    super(props);

		this.changeRating = this.changeRating.bind(this);
    this.state = {
      name: this.props.name,
      code: this.props.code,
      rating: this.props.rating || 0
    };
  }

  changeRating(rating) {
    this.setState({
      rating: rating
    })
    this.props.ratingChange(this.state.code,rating);
  }

  render() {
    return(
      <div className="StarRating" >
        <p className="StarRating__title">{this.state.name}</p>
        <div className="StarRating__rating">
          <StarRatings
            rating={this.state.rating}
            starRatedColor="#FF4E00"
            starHoverColor="#FF4E00AA"
            changeRating={this.changeRating}
            numberOfStars={5}
            name={this.state.name}
            starDimension="22px"
            starSpacing="3px"
          />
        </div>
      </div>
    );
  }
}

export default StarsRating;