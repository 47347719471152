import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
import "./success-reservation.sass";

const SuccessReservation = () => {
  const myRefname = useRef(null);
  const [pathName, setPathName] = useState("");
  const paymentSuccess = () => {
    const getLocalStorage = localStorage.getItem("typeReservation");

    switch (getLocalStorage) {
      case "transfer":
        setPathName("boton-transferencia-reserva-confirmada");
        break;

      case "debit":
        setPathName("boton-debito-reserva-confirmada");
        break;

      case "credit" :
        setPathName("boton-credito-reserva-confirmada");
        break;

      default:
        setPathName("none");
        break;
    }

  };
  useEffect(() => {
    buttonClick();
  }, []);

  const buttonClick = () => {
    paymentSuccess();
    setTimeout(() => {
      myRefname.current.click();

    }, 1000);
  };
  

  return (
    <div className="successPayment">
      <p className="successPayment__title" ref={myRefname}>
        {pathName}
      </p>
      <button
        style={{ color: "transparent", background: "transparent" }}
        onClick={() => paymentSuccess()}
      >
        boton automatico
      </button>
    </div>
  );
};

export default SuccessReservation;
