// Librerías
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { BASE_URL } from "../../../Helpers";
import AxiosAuth from "../../../services/requestApi";
// Componentes
import MainContainer from "../Utils/MainContainer";

const columns = [
  {
    name: "ID",
    selector: (row) => row["id"],
    sortable: true,
  },
  {
    name: "Lugar",
    selector: (row) => row["location"],
    sortable: true,
  },
  {
    name: "Latitud",
    selector: (row) => row["lat"],
  },
  {
    name: "Longitud",
    selector: (row) => row["lng"],
  },
  {
    name: "Provincia",
    selector: (row) => row["province"]["province"],
    sortable: true,
  },
  {
    name: "Acciones",
    cell: (row) => (
      <>
        <Link to={`locations/edit/${row.id}`} className="btn">
          <p className="btn__text">Editar</p>
        </Link>
      </>
    ),
  },
];

const paginationOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsText: "Todos",
};

const Locations = (props) => {
  const [locations, setLocations] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["location"]);
  const router = useHistory();
  useEffect(() => {
    console.clear();
    const url = `${BASE_URL}/backoffice/locations`;
    AxiosAuth.get(url)
      .then((response) => {
        const props = response.data;
        setLocations(props);
      })
      .catch((e) => {
        router.push("/");
        console.log(e);
      });
  }, []);

  const search = (rows) => {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] !== null &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  return (
    <MainContainer title="Listado de Ubicaciones Habilitadas">
      <div>
        <div className="ActionBar">
          <input
            className="ActionBar__input"
            type="text"
            placeholder="Localidad"
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
          <div className="ActionBar__btns">
            <Link
              className="ActionBar__btns__btn"
              to="/back-office/locations/create"
            >
              Agregar Localidad
            </Link>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={search(locations)}
          pagination
          paginationComponentOptions={paginationOptions}
        />
      </div>
    </MainContainer>
  );
};

export default Locations;
