import React from "react";
import CrossIcon from "../../../assets/components-icons/CrossIcon";
import SecondaryButton from "../../ui/secondary-button";

import "./modal-not-found.sass";
const ModalNotFound = ({
  titleError,
  title,
  titleButton,
  onClick,
  backgroundImage,
  onClose,
}) => {
  return (
    <div
      className="modalNotFound"
      style={{
        backgroundImage: backgroundImage && `url( ${backgroundImage})`,
        background: backgroundImage ? backgroundImage : "#FF4E00",
        backgroundSize: backgroundImage && "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div onClick={onClose} className="modalNotFound__close">
        <CrossIcon color={"#fff"} />
      </div>
      <div className="modalNotFound__container">
        <p className="modalNotFound__container__titleError">{titleError}</p>
        <p className="modalNotFound__container__title">{title}</p>
      </div>
      <SecondaryButton onClick={onClick ? onClick : onClose} title={titleButton} />
    </div>
  );
};

export default ModalNotFound;
