// Librerías
import React from 'react';
import { Link } from 'react-router-dom';
// Recursos
import '../../assets/styles/PlaceCard/_cardv2.sass';

const FinishedCard = ({url, img, price, title, guests, rooms, beds, bathrooms}) => {
  const imagen = {
    backgroundImage: `url(${img})`
  };
  return(
    <React.Fragment>
      <Link to={ url }>
        <div className="Card">
          <div className="Card__img" style={ imagen }>
            <div className="Card__img__price">
              ARS ${ price } LA NOCHE
            </div>
          </div>
          <h3 className="Card__title">{ title }</h3>
          <p className="Card__description">{ guests } huéspedes • { rooms } dormitorio • { beds } cama • { bathrooms } baño</p>
        </div>
      </Link>
    </React.Fragment>
  );
}

export default FinishedCard;