// Librerías
import React from 'react'

const Arrow = (props) => {    
    return(
        <svg 
            className={ props.className }
            height={ props.height }
            width={ props.width }
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10.74 9.46"
        >
            <path 
                fill={props.fill}
                d="M4.86,9.17.08.89A.59.59,0,0,1,.59,0h9.56a.59.59,0,0,1,.51.89L5.88,9.17a.59.59,0,0,1-1,0"
            />
        </svg>
    );
}

export default Arrow;