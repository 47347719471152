import React from "react";
// import loading from "../static/loading.gif";
import loading from "../static/spinner.gif";
const LoadingGift = ({ size }) => {
  return (
    <div
      className="component-animationFadeIn"
      style={{
        width: size ? size : "100%",
        height: size ? size : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
      }}
    >
      <img
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
        src={loading}
        alt={"cargando"}
      />
    </div>
  );
};

export default LoadingGift;
