// Librerías
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { BASE_URL } from "../../../Helpers";
// Sass
import "../../../assets/styles/Utils/Cards/_profilehousingcard.sass";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import useCurrentLanguage from "../../../services/currentlenguaje";
import globalJson from "../../../utilities/traduccGlobal.json"

const ProfileHousingCard = (props) => {
  const { handleFilterLang } = useCurrentLanguage();

  const [cityName, setCityName] = useState([]);
  const router = useHistory();
  useEffect(() => {
    const url = `${BASE_URL}/location/search`;
    axios.get(url).then((response) => {
      const city = response.data;
      setCityName(city);
    });
  }, []);
  const pushLocation = (location, housingId) => {
    const filterCity = cityName.find((e) => e.id === location);
    const city = filterCity.location.replace(/\s+/g, "-");
    router.push(`/${city}/property/${housingId}`);
  };
  const housing = props.housing;
  const cover = housing.housingMedia.filter((i) => i.cover);
  const img =
    cover.length > 0
      ? BASE_URL + "/" + cover[0].media.replace("public/", "")
      : housing.housingMedia.length > 0
      ? BASE_URL + "/" + housing.housingMedia[0].media.replace("public/", "")
      : "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";

  return (
    <div className={`ProfileHousingCard`}>
      <div
        disabled={cityName.length > 0 ? false : true}
        // to={`/property/${housing.id}`}
        onClick={() => pushLocation(housing.locationId, housing.id)}
      >
        <div className="ProfileHousingCard__housing">
          <img
            className="ProfileHousingCard__housing__img"
            src={img}
            alt={housing.title}
          />
        </div>
        <div className="ProfileHousingCard__housingData">
          {`$${housing.basePrice} 
              ${handleFilterLang(globalJson).porNoche}
          
               • ${
            housing.rooms === 1
              ? `${housing.rooms} 
              ${handleFilterLang(globalJson).habitacion}
              `
              : `${housing.rooms} 
              ${handleFilterLang(globalJson).habitaciones}
              `
          } • ${
            housing.bathrooms === 1
              ? `${housing.bathrooms} 
              ${handleFilterLang(globalJson).baño}
              `
              : `${housing.bathrooms} 
              ${handleFilterLang(globalJson).baños}
              `
          } `}
        </div>
        <h2 className="ProfileHousingCard__title">{housing.title}</h2>
      </div>
    </div>
  );
};

export default ProfileHousingCard;
