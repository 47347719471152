// Librerías
import React from 'react'

const Pill = ({ status }) => {
  const setColor = (status) => {

    switch(status){
      case 'cancelled' :
        return '#FF3D00';
      case 'confirmed' :
        return '#4CAF50';
      case 'pending':
        return '#F0AD4E';
    }
  }

  const style = {
    backgroundColor: setColor(status),
    fontSize: '.75rem',
    borderRadius: '20px',
    color: '#FFF',
    padding: '.25rem .5rem'
  }
  return (
    <span style={ style }>
      { status }
    </span>
  );
}

export default Pill
