// Librerías
import React, { useState, useRef } from 'react'
// Componentes
import Arrow from './Arrow';
// Recursos
import '../../assets/styles/Utils/_accordion.sass';

const Accordion = (props) => {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");

    const content = useRef(null);

    function toggleAccordion(){
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
    }
    
    return (
        <div className="Accordion">
            <button className={`Accordion__btn ${setActive}`} onClick={ toggleAccordion }>
                <p className="Accordion__btn__title">{ props.title }</p>
                <Arrow className={`Accordion__btn__icon ${setActive === "active" ? 'rotate' : ''}`} height="10" width="10" fill={`${setActive === "active" ? '#FF4E00' : '#777'}`} />
            </button>
            <div ref={ content } style={{ maxHeight: `${setHeight}`}} className="Accordion__content">
                <div 
                    className="Accordion__content__text"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                />
            </div>
        </div>
    );
}

export default Accordion;