// Librerías
import React, { useState } from 'react';
// Componentes
import Checkbox from './FilterCheckBox';
// Recursos
import '../../../assets/styles/SearchPage/Utils/_neighborhoodfilter.sass';
import caretdown from '../../../assets/static/caret-down.svg';

const NeighborhoodFilter = ({title, allOptions, clickEventCustom}) => {
    const [active, setActive] = useState(false);
    const switchActive = () => (active) ? setActive(false) : setActive(true);
    const listOptions = allOptions.map((item) => {
        return(
            <Checkbox
                key={item.id}
                id={item.id}
                name={item.name}
                title={item.name}
                value={item.id}
                checked={item.checked}
                customClickEvent={clickEventCustom}
                last={(item.id === allOptions[allOptions.length - 1].id) ? true : false}
            />
        );
    });

    return(
        <React.Fragment>
            <button className={`NeighborhoodFilter ${(active) ? 'active' : ''}`} onClick={ switchActive }>
                {title}
                <img class={`NeighborhoodFilter__icon ${(active) ? 'active' : ''}`} src={caretdown} alt="Down"/>
                <div className={`NeighborhoodFilter__options ${(active) ? 'active' : ''}`} >
                    { allOptions && listOptions }
                </div>
            </button>
        </React.Fragment>
    );
}

export default NeighborhoodFilter;