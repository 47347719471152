// Librerías
import React from 'react';
import { connect, useDispatch } from 'react-redux';
// Componentes
import { closeModal } from '../../store/modal/actions'
// Recursos
import x from '../../assets/static/Modals/x.png';
import '../../assets/styles/Modals/_promotion_modal.sass';



const BankPromotionModal = ({promotions}) => {
    const dispatch = useDispatch()

    return(
        <div className="BankPromotionModal">
            <header className="BankPromotionModal__header"> 
                <p>Promociones de pago</p>
                <>
                <img onClick={() => dispatch(closeModal())} alt="x" src={x} className="x"/>
                </>
            </header>
            <section className="BankPromotionModal__section">
                <ul>
                    {promotions.map((plan)=>{
                        const title = plan.supplier === "default" ? "Con todas las tarjetas" : plan.supplier;

                        const banks = plan.banks.map((b)=>{  
                          const bankTitle = b.bank === "default" ? "" : b.bank
                  
                          const plans = b.plans.map((p)=>{
                            const name = bankTitle === "" ? "default" : bankTitle;
                            const descripcion = [`TNA: ${p.tna}%, CFT: ${p.cft}%`]
                            return <div>
                                      <span>{p.planDescription}</span>
                                      <span>{descripcion}</span>
                                    </div>
                          })
                  
                        return <div className="Checkout__form__title__planConainer__planCuotas__bankTitle">{bankTitle}{plans}</div>
                        })
                        
                      return <div className="Checkout__form__title__planConainer">
                                <div className="Checkout__form__title__planConainer__planCuotas">{title}</div>
                                {banks}
                            </div>
                    })}
                    
                </ul>
                
            </section>
            <section className="BankPromotionModal__buttons">
                <button className="button_orange" onClick={() => dispatch(closeModal())}>Aceptar</button>
            </section>
        </div>
    );
}

export default connect(null,{closeModal})(BankPromotionModal);
