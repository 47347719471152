import React from "react";
import WhatsApp from "../../assets/static/Icons/wpp-icon.svg";
import "../../assets/styles/Buttons/btn.sass";

const WhatsAppButton = () => {
  return (
    <>
      <div className="conteinerWppMobile">
        <div className="whatsappImageMobile">
          <a
            href="https://api.whatsapp.com/send?phone=5492233018749&text=¡Hola Alohar! Quisiera que me contacten."
            className="flex items-center justify-center "
            rel="noopener noreferrer"
            target="_blank"
          >
            <img alt="WhatsApp" src={WhatsApp} className="wpp_iconMobile " />
            <span className="animationMobile"></span>
          </a>
        </div>
      </div>
    </>
  );
};
export default WhatsAppButton;
