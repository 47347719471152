/* eslint-disable no-unused-vars */
// Librerías
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
// Componentes
import Rating from "./Utils/StarRating";
import ErrorMessage from "../Alerts/ErrorAlert";

// Recursos
import { BASE_URL } from "../../Helpers";
import FinishedModal from "../Modals/FinishedModal";
import ModalGlobal from "../Modals/modal-global";
import ModalNotFound from "../Modals/modal-not-found";
import backgroundModal from "../../assets/static/backgrounds/background-feet.png";
import ButtonPrimary from "../ui/button-primary";

const HostValuation = ({
  name,
  url,
  img,
  title,
  startDate,
  endDate,
  template,
}) => {
  const [privateMessage, setPrivateMessage] = useState("");
  const [publicComment, setPublicComment] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalTyoe] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [rating, setRating] = useState({});
  const [active, setactive] = useState(false);
  const [close, setClose] = useState(false);
  const [limitCharacter, setLimitCharacter] = useState(1000);
  const { type, token } = useParams();
  const url_template = `${BASE_URL}/valuations/`;
  const handleRatingChange = (code, rating) => {
    setRating((prevState) => ({
      ...prevState,
      [code]: rating,
    }));
  };

  const handleValidate = () => {
    let valid = true;
    const _errors = {};
    if (privateMessage.length < 5) {
      valid = false;
      _errors["privateMessage"] = "El mensaje privado debe ser mas largo";
    }
    if (publicComment.length < 5) {
      valid = false;
      _errors["publicComment"] = "El mensaje público debe ser mas largo";
    }
    if (Object.keys(rating).length !== template.length) {
      valid = false;
      _errors["rating"] = "Por favor complete todas las opciones de rating.";
    }
    valid ? handleSend() : setErrors(_errors);
  };

  const handleSend = () => {
    setErrors({});
    setLoading(true);
    const evaluations = Object.keys(rating).map((r) => {
      return { code: r, rate: rating[r] };
    });
    const valuation = {
      valuationToken: token,
      valuations: evaluations,
      privateMessage: privateMessage,
      publicMessage: publicComment,
    };

    const url = `${BASE_URL}/valuations/`;
    setModalTyoe("");
    axios
      .post(url, valuation)
      .then((response) => {
        setModalTyoe("SUCCESS");

        if (response.status === 201 || "201") {
          setModalTyoe("SUCCESS");
        }
        if (response.status === 200) {
          setModalTyoe("SUCCESS");
        }
        setLoading(false);
        setClose(true);
        setOpenModal(true);
        selectModal();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 201) {
          setModalTyoe("SUCCESS");
        }
        if (error.response.status === 406) {
          setModalTyoe("DUPLICATE_VALUATION");
        }
        if (error.response.status === 405) {
          setModalTyoe("ERROR_HOUSING_TOPIC_NOT_EXIST");
        }
        if (error.response.status === 403) {
          setModalTyoe("RESERVATION_NOT_EXIST");
        }
        if (error.response.status === 401) {
          setModalTyoe("ERROR_NOT_HAVE_PERMISSION");
        }
        if (error.response.status === 400) {
          setModalTyoe("UNKNOWN_ERROR");
        }
        setLoading(false);
        setClose(true);
        setOpenModal(true);
        selectModal();
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "privateMessage") setPrivateMessage(value);
    if (name === "publicComment") setPublicComment(value);
  };

  const router = useHistory();

  const linkWpp = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5492233018749&text=¡Hola Alohar! Quisiera que me contacten.",
      "_blank"
    );
  };
  const selectModal = (modalType) => {
    switch (modalType) {
      case "UNKNOWN_ERROR":
        return (
          <ModalGlobal close={close}>
            <ModalNotFound
              onClose={() => setClose(false)}
              title={"Ups, algo salió mal. inténtelo nuevamente"}
              titleError={"400"}
              backgroundImage={backgroundModal}
              titleButton={"Volver"}
            />
          </ModalGlobal>
        );
      case "DUPLICATE_VALUATION":
        return (
          <FinishedModal
            setCloseModal={() => setClose(false)}
            closeModal={close}
            title={"Su valoración ya fue enviada correctamente. Gracias."}
            link={"/"}
            buttonTitle={"Aceptar"}
          />
        );
      case "RESERVATION_NOT_EXIST":
        return (
          <ModalGlobal close={close}>
            <ModalNotFound
              onClose={() => setClose(false)}
              title={"Ups... ha ocurrido un error, intente nuevamente"}
              titleError={"403"}
              backgroundImage={backgroundModal}
              titleButton={"Volver"}
            />
          </ModalGlobal>
        );
      case "ERROR_NOT_HAVE_PERMISSION":
        return (
          <ModalGlobal close={close}>
            <ModalNotFound
              onClick={() => linkWpp()}
              title={`Ha habido un inconveniente con la identificación de la reserva, por favor póngase en contacto con nuestro servicio de atención al cliente vía WhatsApp `}
              titleError={"401"}
              onClose={() => setClose(false)}
              backgroundImage={backgroundModal}
              titleButton={"Contactanos"}
            />
          </ModalGlobal>
        );
      case "ERROR_HOUSING_TOPIC_NOT_EXIST":
        return (
          <ModalGlobal close={close}>
            <ModalNotFound
              onClick={() => linkWpp()}
              title={`Usuario no autorizado para realizar esta valoración. Por favor contactenos`}
              titleError={"406"}
              onClose={() => setClose(false)}
              backgroundImage={backgroundModal}
              titleButton={"Contactanos"}
            />
          </ModalGlobal>
        );

      case "SUCCESS":
        return (
          <FinishedModal
            setCloseModal={() => setClose(false)}
            closeModal={close}
            title={"¡Gracias por tu opinión!"}
            link={"/"}
            buttonTitle={"Aceptar"}
          />
        );
      default:
        return "";
    }
  };

  return (
    <div className="Valuation__container">
      <div className="Valuation__floating">
        <h2 className="Valuation__container__title">Evaluar al Alohador</h2>
        <div className="Valuation__container__userData">
          <div className="Valuation__container__userData__imgcontainer">
            <img
              src={img}
              alt="User Avatar"
              className="Valuation__container__userData__imgcontainer__img"
            />
          </div>
          <p className="Valuation__container__userData__name">{name}</p>
          <p className="Valuation__container__userData__text">
            Te quedaste en{" "}
            <Link to={url} className="Valuation__container__userData__link">
              {title}
            </Link>{" "}
            del {startDate} al {endDate}
          </p>
        </div>
        <div className="Valuation__container__separator"></div>
        <div className="Valuation__container__ratings">
          {template
            ? template.map((t) => {
                return (
                  <Rating
                    name={t.title}
                    code={t.code}
                    rating={rating[t.code]}
                    ratingChange={handleRatingChange}
                  />
                );
              })
            : ""}
        </div>
        {errors["rating"] ? <ErrorMessage message={errors["rating"]} /> : ""}
        <div className="Valuation__container__inputContainer">
          <label
            htmlFor="privateMessege"
            className="Valuation__container__inputContainer__label"
          >
            Describe de forma privada con el alohador tu experiencia en el lugar
          </label>
          <textarea
            maxLength={1000}
            className="Valuation__container__inputContainer__textarea"
            name="privateMessage"
            id="privateMessege"
            rows="3"
            placeholder="Éste mensaje lo verá el alohador como retroalimentación de forma privada"
            value={privateMessage}
            onChange={handleChange}
          ></textarea>
          <span
            className={
              privateMessage.length >= 1000
                ? "Valuation__container__inputContainer__counterLimit"
                : "Valuation__container__inputContainer__counter"
            }
          >
            {privateMessage.length}
          </span>
          <span
            className={
              privateMessage.length >= 1000
                ? "Valuation__container__inputContainer__counterLimit"
                : "Valuation__container__inputContainer__counter"
            }
          >
            /1000
          </span>
          {errors["privateMessage"] ? (
            <ErrorMessage message={errors["privateMessage"]} />
          ) : (
            ""
          )}
        </div>
        <div className="Valuation__container__inputContainer">
          <label
            htmlFor="publicComment"
            className="Valuation__container__inputContainer__label"
          >
            Describe de forma pública tu experiencia con el alohador
          </label>
          <textarea
            className="Valuation__container__inputContainer__textarea"
            maxLength={1000}
            name="publicComment"
            id="publicComment"
            rows="3"
            placeholder="Éste será el comentario que se verá en la plataforma de manera pública"
            value={publicComment}
            onChange={handleChange}
          ></textarea>
          <span
            className={
              publicComment.length >= 1000
                ? "Valuation__container__inputContainer__counterLimit"
                : "Valuation__container__inputContainer__counter"
            }
          >
            {publicComment.length}
          </span>
          <span
            className={
              publicComment.length >= 1000
                ? "Valuation__container__inputContainer__counterLimit"
                : "Valuation__container__inputContainer__counter"
            }
          >
            /1000
          </span>
          {errors["publicComment"] ? (
            <ErrorMessage message={errors["publicComment"]} />
          ) : (
            ""
          )}
        </div>
        <ButtonPrimary
          disabled={loading ? true : false}
          title={"Enviar"}
          onClick={handleValidate}
        />
      </div>

      {openModal ? selectModal(modalType) : ""}
    </div>
  );
};

export default HostValuation;
