import axios from "axios";
import { BASE_URL } from "../Helpers";

export const getSearchedCities = async () => {
  const url = `${BASE_URL}/location/searchv2`;

  try {
    const response = await axios.get(url, {
      cache: { maxAge: 15 * 60 * 1000 },
    }); // Cache for 15 minutes
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNeighbors = async (location) => {
  const url = `${BASE_URL}/location/neighborhoods/${location}`;
  return await axios.get(url).then((response) => {
    return response;
  });
};

export const getCity = async (provinceId) => {
  const url = provinceId
    ? `${BASE_URL}/location/province/${provinceId}`
    : `${BASE_URL}/location`;
  return await axios.get(url).then((response) => {
    return response;
  });
};
export const getCityCountry = async (countryId) => {
  const url = `${BASE_URL}/province/country/${countryId}`;
  return await axios.get(url).then((response) => {
    return response;
  });
};

export const getCountry = async () => {
  const url = `${BASE_URL}/country`;
  return await axios.get(url).then((response) => {
    return response;
  });
};

export const getProvinces = async (countryId) => {
  const url = countryId
    ? `${BASE_URL}/country/province/${countryId}`
    : `${BASE_URL}/location/province`;
  return await axios.get(url).then((response) => {
    return response;
  });
};