// Librerías
import React, { useEffect } from "react";
// Components
import ConfirmedComponent from "../components/Confirmed/ConfirmedComponent";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const ConfirmedContainer = () => {
  const { id } = useParams();
  const eventSnippet = `
  gtag('event', 'conversion', {
    'send_to': 'AW-1014273093/u6FRCP29jckZEMWo0uMD',
    'transaction_id': '${id}'
  });
`;

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = eventSnippet;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [id]);
  
  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: eventSnippet }}
        />
      </Helmet>

      <Navbar />
      <ConfirmedComponent />
      <Footer />
    </React.Fragment>
  );
};

export default ConfirmedContainer;
