// Librerías
import React, { useState } from 'react';
// Componentes
import Checkbox from './FilterCheckBox';
// Recursos
import '../../../assets/styles/SearchPage/Utils/_listfilter.sass';
import caretdown from '../../../assets/static/caret-down.svg';

const FilterTypeSpace = ({title, spaceType, clickEventCustomTypeSpace}) => {
    const [active, setActive] = useState(false);

    const switchActive = () => (active) ? setActive(false) : setActive(true);
    const listOptions = spaceType.map((item) => {
        return(
            <Checkbox
                key={item.name}
                id={item.name}
                name={item.name}
                title={item.title}
                value={item.value}
                checked={item.checked}
                customClickEvent={clickEventCustomTypeSpace}
                last={(item.name === spaceType[spaceType.length - 1].name) ? true : false}
            />
        );
    });

    return(
        <React.Fragment>
            <button className={`ListFilter ${(active) ? 'active' : ''}`} onClick={ switchActive }>
                {title}<img class={`ListFilter__icon ${(active) ? 'active' : ''}`}src={caretdown} alt="Down"/>
                <div className={`ListFilter__options ${(active) ? 'active' : ''}`}>
                    { spaceType && listOptions }
                </div>
            </button>
        </React.Fragment>
    );
}

export default FilterTypeSpace;