import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  openContactAlohadorModal,
  openCancelReservationModal,
} from "../../store/modal/actions";
import Travel from "./Travel";
import ModalWrapper from "../Modals/ModalWrapper";
import MessengerModal from "../Modals/MessengerModal";
import CancelReservationModal from "../Modals/CancelReservationModal";
import "../../assets/styles/UserTravelsPage/_user_travel_page.sass";
import moment from "moment";

moment.locale("es");

const ListEvaluations = ({ reservations }) => {
  const [typeTravel, seTypeTravel] = useState("next");
  const [housingId, setHousingId] = useState();
  const [firstName, setFirstName] = useState();
  const [userId, setUserId] = useState();
  const [reservationId, setReservationId] = useState();
  const [typeReservation, setTypeReservation] = useState(null);
  const [cancelationType, setCancelationType] = useState();
  const hoy = moment().utc(true);
  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const dispatch = useDispatch();

  const selectModal = (modalType) => {
    switch (modalType) {
      case "CONTACT_ALOHADOR":
        return (
          <ModalWrapper key="contacto_modal">
            <MessengerModal
              firstName={firstName}
              housingId={housingId}
              userId={userId}
            />
          </ModalWrapper>
        );
      case "CANCELATION_RESERVATION":
        return (
          <ModalWrapper key="cancel_modal">
            <CancelReservationModal
              reservations={reservations}
              reservationId={reservationId}
              cancellationType={cancelationType}
              typeReservation={typeReservation}
              hasPenalty
            />
          </ModalWrapper>
        );
      default:
        return "";
    }
  };

  const handleOpenContactAlohadorModal = (firstName, housingId, userId) => {
    setHousingId(housingId);
    setUserId(userId);
    setFirstName(firstName);
    dispatch(openContactAlohadorModal());
  };

  const handleCancelationModal = (id, type) => {
    setReservationId(id);
    setCancelationType(type);
    dispatch(openCancelReservationModal());
  };

  return (
    <div className="wrapper_travels">
      <div className="wrap_type_travels">
        <div className="from_travels">
          <div
            className={`from_travel ${typeTravel === "next" ? "select" : ""}`}
            onClick={() => seTypeTravel("next")}
          >
            Próximos
          </div>
          <div
            className={`from_travel ${typeTravel === "past" ? "select" : ""}`}
            onClick={() => seTypeTravel("past")}
          >
            Pasados
          </div>
          <div
            className={`from_travel ${
              typeTravel === "canceled" ? "select" : ""
            }`}
            onClick={() => seTypeTravel("canceled")}
          >
            Canceladas
          </div>
          <div
            className={`from_travel ${
              typeTravel === "pending" ? "select" : ""
            }`}
            onClick={() => seTypeTravel("pending")}
          >
            Pendientes
          </div>
          <div
            className={`from_travel ${
              typeTravel === "confirmed" ? "select" : ""
            }`}
            onClick={() => seTypeTravel("confirmed")}
          >
            Confirmadas
          </div>
        </div>
        <div className="list_travels">
          {reservations
            .filter((r) => {
              const checkin = moment(r.checkin).utc(true);
              const checkout = moment(r.checkout).utc(true);
              if (typeTravel === "next")
                return checkin >= hoy || checkout >= hoy;
              if (typeTravel === "past") return checkout < hoy;
              if (typeTravel === "canceled") return r.status === "cancelled";
              if (typeTravel === "pending") return r.status === "pending";
              if (typeTravel === "confirmed") return r.status === "confirmed";
            })
            .sort((a, b) => {
              const a_checkin = moment(a.checkin).utc(true);
              const b_checkin = moment(b.checkin).utc(true);
              return a_checkin >= b_checkin ? 1 : -1;
            })
            .map((e) => (
              <Travel
                key={e.id}
                locator={e.locator}
                status={e.status}
                housing={e.housing}
                housingMedia={e.housing.housingMedia}
                title={e.housing.title}
                checkin={e.checkin}
                checkout={e.checkout}
                address={e.housing.adress}
                persons={e.guests}
                cancellationType={e.cancellationType}
                id={e.id}
                setTypeReservation={() => setTypeReservation(e)}
                handleOpenContactAlohadorModal={handleOpenContactAlohadorModal}
                handleCancelationModal={handleCancelationModal}
              />
            ))}
        </div>
      </div>
      {modalState.isOpen ? selectModal(modalState.type) : ""}
    </div>
  );
};

export default ListEvaluations;
