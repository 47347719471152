// Librerías
import React from 'react';
// Componentes
import StatusPoints from '../../../components/BackOffice/Housing/StatusPoints';

const StatusPointsPage = () => {
    return(
        <StatusPoints />
    );
}

export default StatusPointsPage;