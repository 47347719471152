import React, { useState } from "react";
import { useEffect } from "react";
import CrossIcon from "../../../assets/components-icons/CrossIcon";
import "./notification-housing.sass";
import useCurrentLanguage from "../../../services/currentlenguaje";
import globalJson from "../../../utilities/traduccGlobal.json"


const NotificationHousing = ({ titleHousing, id, housingListPending }) => {
  const [close, setClose] = useState(true);
  const [housingFilter, setHousingFilter] = useState([]);
  const [key, setKey] = useState()
  const { handleFilterLang } = useCurrentLanguage();


  useEffect(() => {
    const housingList = housingListPending.filter((e) => e.housing.id === id);
    setHousingFilter(housingList);
    setKey(id)
  }, []);
  return (
    <div
      key={key}
      className={close ? "notificationHousing" : "notificationHousingNone"}
    >
      <a
        className="notificationHousing__container"
        href={`housing/reservations/${id}`}
      >
        <p className="notificationHousing__container__text">
          {housingFilter.length > 1
            ? `${handleFilterLang (globalJson).tienes} ${housingFilter.length} ${handleFilterLang (globalJson).reservasPendientes} `
            : `${handleFilterLang (globalJson).tienes} ${housingFilter.length} ${handleFilterLang (globalJson).reservaPendiente} `}
        </p>
        <p className="notificationHousing__container__text">{titleHousing}</p>
        <p className="notificationHousing__container__text">
        {handleFilterLang (globalJson).clickAqui}
          </p>
      </a>
      <div
        className={"notificationHousing__close"}
        onClick={() => setClose(false)}
      >
        <CrossIcon color={"#9CA3AF"} />
      </div>
    </div>
  );
};

export default NotificationHousing;
