// Librerías
import React from 'react';
import { useParams } from "react-router-dom";
// Componentes
import AloharFormEdit from '../components/AloharForm/AloharFormEdit';
import Navbar from '../components/Navbar/Navbar';
import AloharFormOnlyApp from '../components/AloharForm/AloharFormOnlyApp';
import AloharFormOnlyAppEdit from '../components/AloharForm/AloharFormOnlyAppEdit';


const EditAloharPageApp = () => {
  const { editStep, id } = useParams();
  console.log("🚀 ~ file: EditAloharPageApp.js:12 ~ editStep:", editStep)
  console.log("🚀 ~ file: EditAloharPageApp.js:12 ~ id:", id)

  return(
    <div className="AloharForm">
      {/* <Navbar /> */}
      <AloharFormOnlyAppEdit editStep={editStep} id={id}/>
    </div>
  );
}

export default EditAloharPageApp;