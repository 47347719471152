// Librerías
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
// Componentes
import MainContainer from "../Utils/MainContainer";
import ExpandedComponent from "../Utils/ExpanderUser";

const datatableColumns = [
  {
    name: "ID",
    selector: (row) => row["id"],
    sortable: false,
  },
  {
    name: "Nombre",
    selector: (row) => row["name"],
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row["email"],
    sortable: true,
  },
  {
    name: "Mail Confirmado",
    sortable: true,
    cell: (row) => `${row.emailConfirmed === 0 ? "Pendiente" : "Confirmado"}`,
  },
  {
    name: "Celular Confirmado",
    sortable: true,
    cell: (row) =>
      `${row.phoneNumberConfirmed === 0 ? "Pendiente" : "Confirmado"}`,
  },
  {
    name: "Acciones",
    sortable: true,
    cell: (row) => (
      <>
        <Link to={`users/${row.id}`} className="btn">
          <p className="btn__text">Ver sus datos</p>
        </Link>
      </>
    ),
  },
];

const paginationOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsText: "Todos",
};

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns] = useState(["name", "email", "id"]);
  const router = useHistory();
  useEffect(() => {
    const url = `${BASE_URL}/backoffice/users`;

    AxiosAuth.get(url)
      .then((response) => {
        const people = response.data;
        setUsers(people);
      })
      .catch((e) => {
        router.push("/");
        console.log(e);
      });
  }, []);

  const search = (rows) => {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] !== null &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  return (
    <MainContainer title="Lista de Usuarios">
      <div>
        <div className="ActionBar">
          <input
            className="ActionBar__input"
            type="text"
            placeholder="Búsqueda"
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
        </div>
        <DataTable
          columns={datatableColumns}
          data={search(users)}
          pagination
          paginationComponentOptions={paginationOptions}
          expandableRows
          expandableRowDisabled={(row) => row.disabled}
          expandableRowsComponent={<ExpandedComponent />}
        />
      </div>
    </MainContainer>
  );
};

export default Users;
