// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
// Recursos
import "../../../assets/styles/AloharForm/Utils/_buttons.sass";
import AxiosAuth from "../../../services/requestApi";
import SuccessModal from "../../Modals/SuccessModal";
import ErrorModal from "../../Modals/ErrorModal";
import ModalWrapper from "../../Modals/ModalWrapper";
import FinishedModal from "../../Modals/FinishedModal";

class ButtonEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: this.props.validate,
      openModal: false,
      modalType: "",
      errorMessenger: "",
      message: "",
      modalClose: false,
      // role: JSON.parse(localStorage.getItem('role')).role || "housing",
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.selectModal = this.selectModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate });
    }
  }

  async sendData(url, bodyParameters) {

    const send = await AxiosAuth.post(url, bodyParameters)
      .then((data) => {
        return data.status === 201;
      })
      .catch((e) => {
        if (e.response) {
          this.setState({
            statusCode: e.response.data.statusCode,
            message: e.response.data.message,
          });
        }
        return false;
      });

    return send;
  }

  async handleSave(from) {
    /* Set scroll to top*/
    window.scrollTo(0, 0);
    const hid = this.props.housingId;
    const currentStep = this.props.step;
    const nextStep =
      currentStep < 16 && currentStep > 2 ? currentStep + 1 : currentStep;
    const backStep =
      currentStep > 2 && currentStep < 16 ? currentStep - 1 : currentStep;
    const nextUrl = `/edit/${nextStep}/${this.props.housingId}`;
    const backtUrl = `/edit/${backStep}/${this.props.housingId}`;
    if (from === "back") {
      window.location.href = backtUrl;
    } else if (currentStep !== 8) {
      // Step 8 Image, independient method
      const bodyParameters = this.props.steps[currentStep].bodyParameters;
      const url = this.props.steps[currentStep].endopoit;
      bodyParameters.id = this.props.housingId;
      const send = await this.sendData(url, bodyParameters);

      if (send) {
        if (from === "exit") {
          this.setState({ openModal: true, modalType: "SUCCESS" });
          this.setState({ modalClose: true });
        }
        setTimeout(() => {
          if (from === "next") window.location.href = nextUrl;
        }, 50);
      } else {
        this.setState({ openModal: true, modalType: "ERROR" });
      }
    } else {
      /* El paso de imagenes resuelve todas las llamadas al servidor desde el propio componente*/
      this.setState({ modalClose: true });

      if (from === "next") window.location.href = nextUrl;
    }
  }

  handleExit = () => {
    localStorage.removeItem("housingPublicProcessData");
    window.location.href = "/profile/housing";
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  selectModal = (modalType) => {
    switch (modalType) {
      case "ERROR":
        return (
          <ModalWrapper key="error_modal">
            <ErrorModal
              handleCloseModal={this.handleCloseModal}
              title={"Código de error: " + this.state.statusCode}
              text={
                "Hubo un error y los datos no se pudieron guardar. <br/>" +
                this.state.message
              }
            />
          </ModalWrapper>
        );
      default:
        return "";
    }
  };
  componentDidMount() {
    localStorage.removeItem("housingPublicProcessData");
  }
  render() {
    return (
      <React.Fragment>
        <FinishedModal
          setCloseModal={() => this.setState({ modalClose: false })}
          buttonTitle={"Volver a mis alojamientos"}
          link={`/profile/housing`}
          closeModal={this.state.modalClose}
        />
        {this.state.openModal ? this.selectModal(this.state.modalType) : ""}
        <div className="Buttons">
          {/* <button className="Buttons__btn-secondary"  onClick={this.handleExit}>Salir</button> */}
          <button
            className="Buttons__btn-secondary"
            onClick={() => {
              this.handleSave("back");
            }}
          >
            Anterior
          </button>
          <button
            className="Buttons__btn-primary"
            disabled={!this.state.validate}
            onClick={() => {
              this.handleSave("exit");
            }}
          >
            Guardar y salir
          </button>
          <button
            disabled={!this.state.validate}
            className="Buttons__btn-primary"
            onClick={() => {
              this.handleSave("next");
            }}
          >
            Siguiente
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps, aloharFormActions)(ButtonEdit);
