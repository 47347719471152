import React from "react";

const ArrowLeft = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      width={size ? size : "28"}
      height={size ? size : "27"}
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3133 13.5H6.58398"
        stroke={color ? color : "#2B2B2B"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4486 21.375L6.58398 13.5L14.4486 5.625"
        stroke={color ? color : "#2B2B2B"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
