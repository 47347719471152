import React from "react";

const InputPrice = ({ value, onChange, prefix, title, name, disabled }) => {
  return (
    <div
      className={`w-full Prices__container__inputContainer__price ${
        disabled ? "opacity-50" : "opacity-100"
      }`}
    >
      <label
        className="Prices__container__inputContainer__label"
        htmlFor={name}
      >
        {title}
      </label>
      <span className={`absolute left-0 top-2/4 translate-x-2/4 `}>
        {prefix}
      </span>
      <input
        disabled={disabled}
        className={`w-full  pl-4 py-1  border-b-2 border-primary flex justify-between`}
        onChange={onChange}
        id={name}
        value={value}
        name={name}
        type="number"
        min="1"
      />
    </div>
  );
};

export default InputPrice;
