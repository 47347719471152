export const termsAndPrivacy = [
  {
    title: "POLÍTICA DE PRIVACIDAD DE ALOHAR",
    // subtitle: "1. INTRODUCCIÓN.",
    li: [
      "1.1. BUCHU S.A. valora a sus usuarios y está comprometida a proteger su privacidad. Para cumplir con este compromiso, BUCHU S.A. ha desarrollado esta política de privacidad que describe las prácticas de la PLATAFORMA ALOHAR en lo que se refiere a la recolección, uso, tratamiento, conservación y divulgación de la información personal recopilada de los USUARIOS cuando accedan a cualquier función de la PLATAFORMA ALOHAR, sea para registrarse como HUÉSPED o ALOHADOR, para gestionar o utilizar los SERVICIOS DE PAGO, comunicarse entre sí u ofrecer o reservar o contratar espacios para su ocupación temporal o servicios adicionales, recibir ofertas o novedades mediante su sitio web y servicios vinculados y/o a través de aplicaciones en celulares, tabletas electrónicas y demás dispositivos inteligentes, así como interfaces de programas de aplicaciones, de acuerdo con lo establecido por la Ley 25.326 de Protección de Datos Personales, el Decreto Reglamentario 1558/2001 y las Disposiciones de la Dirección Nacional de Protección de Datos Personales (DNPDP). Esta Política de Privacidad está destinada a informarlo respecto de cuales datos recopilamos, por qué los recopilamos y qué hacemos con ellos",
      "Usted, en tanto usuario de la PLATAFORMA ALOHAR, acepta las prácticas que se detallan a continuación, por lo que le recomendamos leer detenidamente la información que vamos a brindarle.",
      "1.2. El responsable de la base de datos es BUCHU S.A., identificada con la CUIT 30-71686126-7, con domicilio legal en la calle Córdoba N° 1957, piso 8°, depto. “D” de la ciudad de Mar del Plata, Provincia de Buenos Aires, República Argentina",
      "1.3. La presente Política de Privacidad concierne también a los SERVICIOS DE PAGO que INGENICO Payments le preste a usted de conformidad con las estipulaciones de los términos de pago del servicio. Cuando utilice los SERVICIOS DE PAGO, también estará facilitando sus datos, incluidos los personales, a una o varias entidades de INGENICO Payments, que también desempeñará las funciones de responsable del tratamiento de los datos de su información relacionada con los servicios de pago",
    ],
  },
  {
    title: "2. INFORMACIÓN QUE RECOPILAMOS MEDIANTE LA PLATAFORMA ALOHAR.",
    li: [
      "2.1. Datos que Ud. nos proporciona.",
      "2.1.1. Información necesaria para utilizar la PLATAFORMA ALOHAR.-",
      "En cumplimiento con lo establecido en los arts. 2, 7 y 8 de la Ley 25.326, BUCHU S.A. a través de la PLATAFORMA ALOHAR, no solicitará información que sea incompatible con la finalidad de dicho sitio web o de sus servicios, ni que directa o indirectamente revele datos sensibles de los usuarios, como ser datos que revelan origen racial y étnico, opiniones políticas, convicciones religiosas, filosóficas o morales, afiliación sindical, información referente a la salud o a la vida sexual.",
      "Nosotros solicitamos y recopilamos los siguientes datos personales sobre usted cuando utiliza la PLATAFORMA ALOHAR. Estos datos son necesarios para la correcta ejecución del contrato formalizado entre usted y nosotros y nos permiten cumplir nuestras obligaciones legales. Sin estos datos, es posible que no podamos prestarle todos los servicios solicitados",
      "A) Información de la cuenta. Al querer registrarse para poder disponer de una cuenta de USUARIO en La PLATAFORMA ALOHAR, le requeriremos determinada información como su/s nombre/s, apellido/s, dirección de correo electrónico y fecha de nacimiento",
      "B) Información de perfil de USUARIO y del ANUNCIO. Para utilizar determinadas funciones de la PLATAFORMA ALOHAR (como hacer una reserva o crear y publicar un anuncio), se le podrá pedir información adicional, pudiendo incluir su dirección, número de teléfono y una foto de perfil",
      "C) Información de verificación de identidad. Para contribuir a crear y mantener un entorno de confianza, podremos recabar información para verificar su identidad (como fotografías de su documento de identidad, pasaporte o permiso de conducir) u otros datos de autenticación.",
      "D) Información de Pago. Para utilizar determinadas funciones de la PLATAFORMA ALOHAR (como reservar u ofrecer o crear un anuncio), podemos pedirle algunos datos de carácter financiero (como los datos de su cuenta bancaria o tarjeta de crédito y/o su Clave de Identificación Tributaria) con el fin de facilitar el procesamiento de los pagos a través de INGENICO Payments.",
      "E) Comunicaciones con LA PLATAFORMA y/o con otros USUARIOS. Cuando usted se comunica con un operador de la PLATAFORMA ALOHAR o utiliza la PLATAFORMA ALOHAR para comunicarse con otros USUARIOS, nosotros recopilamos información acerca de estas comunicaciones y cualquier dato que usted opte por proporcionar.",
      "2.1.2 Información que usted elige proporcionarnos.",
      "Usted puede elegir facilitarnos datos personales adicionales para obtener una mejor experiencia de uso al utilizar la PLATAFORMA ALOHAR.",
      "A) Información adicional de perfil. Usted puede proporcionar información adicional para integrar su perfil de USUARIO en la PLATAFORMA ALOHAR (como su sexo, idioma(s) preferido(s), ciudad, una descripción personal, etc.). Algunos de estos datos, según se indique en su CUENTA DE USUARIO, forman parte de su página de perfil abierta y serán visibles públicamente",
      "B) Información de contacto del listado de direcciones. Usted podrá optar por importar los contactos de su listado de direcciones o introducir la información de sus contactos de forma manual para poder acceder a determinadas funciones de la PLATAFORMA ALOHAR e invitar a éstos a utilizarla",
      "C) Otra información. Usted podrá optar por proporcionarnos información al completar cualquier formulario, hacer una búsqueda, actualizar o añadir información a su cuenta de USUARIO, responder a encuestas, publicar en foros comunitarios, participar en promociones, comunicarse con nuestro equipo de atención al cliente, compartir su experiencia con nosotros o utilizar otras funciones de la PLATAFORMA ALOHAR",
      "D) Usted también puede publicar opiniones, comentarios y sugerencias participando voluntariamente de encuestas y/o contestando preguntas enviadas a través de correos electrónicos o desde la PLATAFORMA ALOHAR. En este caso, a fin de brindar a otros USUARIOS testimonios reales sobre experiencias de servicios, usted acepta y está de acuerdo en que todas las opiniones que nos envíe podrán ser publicadas en LA PLATAFORMA, o en otros sitios y/o aplicaciones con quienes BUCHU S.A. tenga relaciones contractuales. En tal caso, usted podrá ser identificado con nombre completo y/o apodo, foto, comentario, localidad o provincia o país de residencia.",
      "Siempre tenga presente que cuando coloca información en un área pública de la PLATAFORMA ALOHAR (avisos, grupos de chat, álbumes de fotografías electrónicos y comentarios sobre los alojamientos y/o servicios en general), la está poniendo a disposición de otros USUARIOS y al público en general, lo cual queda fuera del ámbito de control de BUCHU S.A",
      "2.1.3. Información necesaria para el uso de los Servicios de Pago",
      "El responsable del tratamiento de los datos de pago necesitará recopilar los siguientes datos, ya que son necesarios para la adecuada ejecución del contrato formalizado con usted y para el cumplimiento de la legislación aplicable. Sin estos datos, usted no podrá utilizar los servicios de pago",
      "A) Información de Pago. Cuando utilice los Servicios de Pago, el Responsable del Tratamiento de los Datos de Pago precisará de ciertos datos financieros, como los datos de su clave única de identificación tributaria, cuenta bancaria o de su tarjeta de crédito, para procesar los pagos y cumplir la legislación aplicable",
      "B) Verificación de identidad y otros datos. Si usted es un usuario ALOHADOR, el Responsable del Tratamiento de los Datos de Pago podría precisar de información para verificar su identidad, como fotografías de su documento de identidad, pasaporte o permiso de conducir u otros datos de autenticación, su fecha de nacimiento, domicilio, dirección de correo electrónico, número de teléfono y/u otros para verificar su identidad, y poder prestarle los Servicios de Pago y cumplir con la legislación aplicable",
      "2.2. Información que nosotros recopilamos.",
      "2.2.1. Automáticamente a partir del uso que usted hace de la PLATAFORMA ALOHAR y de los Servicios de Pago.-",
      "Al utilizar usted la PLATAFORMA ALOHAR y los Servicios de Pago, nosotros recopilamos información de forma automática, incluidos sus datos personales, acerca de los servicios que usted utiliza y cómo los utiliza. Esta información es necesaria para la correcta ejecución del contrato formalizado entre usted y nosotros y para el cumplimiento de nuestras obligaciones legales, a propósito de nuestro interés legítimo de prestarle y mejorar las funcionalidades de la PLATAFORMA ALOHAR y los Servicios de Pago.",
      "A) Datos de ubicación geográfica. Al usted utilizar determinadas funciones de la PLATAFORMA ALOHAR, podemos recopilar información sobre su ubicación precisa o aproximada a partir de datos como su dirección IP o el GPS de su dispositivo móvil para ofrecerle una mejor experiencia de uso. La mayoría de los dispositivos permiten controlar o deshabilitar el uso de servicios de ubicación para aplicaciones en el menú de ajustes del mismo. LA PLATAFORMA también podrá recopilar esta información cuando no esté utilizando la aplicación si los servicios de ubicación están activados en el menú de ajustes o en los permisos de su dispositivo",
      "B) Información de uso. Nosotros recopilamos información acerca de las interacciones que usted tenga con la PLATAFORMA ALOHAR, como las páginas o demás contenido que visualice, las búsquedas de anuncios que realice, las reservaciones que ha hecho y otras acciones llevadas a cabo en la PLATAFORMA ALOHAR",
      "C) Datos de registro e información sobre su dispositivo. Nosotros recabamos de manera automática información de registro y sobre su dispositivo cada vez que usted accede a la PLATAFORMA ALOHAR y la utiliza, aun cuando usted no haya creado una cuenta de USUARIO o no haya accedido a ella. Esta información incluye, entre otros datos, detalles acerca de cómo ha utilizado usted la PLATAFORMA ALOHAR (incluido si ha hecho clic en enlaces a aplicaciones de terceros), dirección IP, fechas y horas de acceso, información de hardware y software, información del dispositivo, información de eventos del dispositivo, identificadores únicos, datos sobre bloqueos, datos sobre cookies y las páginas que ha visualizado o con que ha interaccionado antes o después de utilizar la PLATAFORMA ALOHAR",
      "D) Cookies y tecnologías similares. Usamos cookies y otras tecnologías similares cuando usa la PLATAFORMA ALOHAR, usa nuestra aplicación móvil o se involucra con nuestros anuncios en línea o comunicaciones por correo electrónico. Podemos recopilar cierta información por medios automatizados utilizando tecnologías tales como cookies, Web Beacons, píxeles, herramientas de análisis del navegador, registros del servidor e identificadores móviles. Los USUARIOS conocen y aceptan de plena conformidad que BUCHU S.A. a través de la PLATAFORMA ALOHAR podrá utilizar un sistema de seguimiento mediante el uso de cookies. Las Cookies son archivos que se alojan en el disco rígido, con una duración limitada en el tiempo que ayudan a personalizar los servicios. Las mismas permitirán recoger datos a efectos de la autenticación de usuarios, validación de identidad y para evaluar el comportamiento y la demografía de los usuarios con el fin de ofrecerles un mejor servicio. Asimismo, podrán utilizarse con fines estadísticos tales como: fecha de la primera visita, número de veces que se ha visitado, fecha de la última visita, URL y dominio de la que proviene, explorador utilizado, resolución de la pantalla, entre otras acciones del USUARIO. También son utilizadas las Cookies para promover y hacer cumplir las reglas y seguridad del sitio. Por su parte, las 'web beacons' son imágenes insertadas en una página de Internet o correo electrónico, que pueden ser utilizadas para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros. ",
      "Toda esta información no será asociada a los datos personales de los USUARIOS. No obstante, el USUARIO puede desactivar y/o eliminar estas cookies a través de su navegador de Internet, sabiendo que con esta opción, es posible que no se puedan acceder a ciertos contenidos de la PLATAFORMA ALOHAR. En ocasiones, puede haber existencia de cookies o archivos de similares características instalados por terceros en algunos enlaces con el sitio web que no son controlados por BUCHU S.A. a través de la PLATAFORMA ALOHAR. BUCHU S.A. BUCHU S.A. es responsable solamente por la utilización de Cookies por su propio sitio web y no por el uso de Cookies por parte de terceros. En muchos casos, la información que recopilamos utilizando cookies y otras herramientas sólo se utiliza en un no identificable sin referencia a la información personal. Podemos utilizar la información que recopilamos para comprender mejor los patrones de tráfico del sitio web y optimizar su experiencia en la PLATAFORMA ALOHAR. En algunos casos, asociamos la información que recopilamos utilizando cookies y otra tecnología con su información personal.",
      "E) Pixeles y SDKs: Los terceros, incluido Facebook, pueden utilizar cookies, Web Beacons y otras tecnologías de almacenamiento para recopilar o recibir información de nuestros sitios web y de otras partes de Internet y usar esa información para proporcionar servicios de medición y anuncios de destino. Terceros, incluido Facebook, pueden recopilar o recibir información de su aplicación y otras aplicaciones y usar esa información para proporcionar servicios de medición y anuncios dirigidos. Los usuarios pueden optar por no participar en la recopilación y el uso de la información para la segmentación de anuncios actualizando su configuración.",
      "F) Información Transaccional de Pagos. INGENICO Payments recopila información relativa a sus transacciones de pago realizadas a través de la PLATAFORMA ALOHAR, incluido el instrumento de pago utilizado, la fecha y la hora, el importe pagado, la fecha de expiración del instrumento de pago y el código de seguridad y postal de facturación, su dirección y otros datos relacionados transaccionales. Estos datos son necesarios para la adecuada ejecución del contrato formalizado entre usted e INGENICO Payments y para permitir la prestación de los Servicios de Pago",
      "2.2.2. Información que nosotros recopilamos de terceros",
      "BUCHU S.A. a través de la PLATAFORMA ALOHAR e INGENICO Payments podrán recabar información, incluidos datos personales, que otros proporcionen acerca de usted cada vez que éstos usen la PLATAFORMA ALOHAR y los Servicios de Pago, o bien obtener información de otras fuentes y combinarla con la que nosotros recopilamos a través de la PLATAFORMA ALOHAR y los Servicios de Pago. Nosotros no controlamos, supervisamos ni respondemos por el modo en que los terceros que proporcionen su información procesan sus datos personales y cualquier solicitud de información relativa a la divulgación de sus datos personales que se nos haya realizado deberá ir dirigida a dichos terceros.",
      "A) Servicios de Terceros. Si usted asocia o conecta su cuenta de USUARIO de la PLATAFORMA ALOHAR con el servicio de un tercero (p. ej., Google, Facebook, WeChat) o si inicia sesión en ésta para poder acceder al mismo, el servicio del tercero podrá enviarnos información como datos de su registro, lista de amigos y perfil pertenecientes a dicho servicio. Esta información varía y se controla a través de tal servicio o según lo autorice usted, mediante los ajustes de privacidad del mismo.",
      "B) Sus referencias. Si alguien le ha escrito o hecho una referencia, podrá salir la publicación en la página de su perfil público de la PLATAFORMA ALOHAR con su consentimiento. ",
      "C) Referencias. Si fuera invitado a utilizar la PLATAFORMA ALOHAR, quien lo invitó puede enviar información personal sobre usted, como su dirección de correo electrónico u otra información de contacto",
      "D) Otras fuentes. Nosotros podremos recibir información complementaria sobre usted, como datos demográficos o datos de ayuda para la detección de fraude y por cuestiones de seguridad, de parte de terceros proveedores de servicios o cuentas asociadas y combinarla con la información de la que dispongamos sobre usted. Por ejemplo, advertencias sobre fraude de parte de servicios de verificación de la identidad a efectos de nuestras labores de prevención de prácticas fraudulentas y evaluación de riesgos. Nosotros podremos recibir información acerca de usted y de sus actividades dentro y fuera de la PLATAFORMA ALOHAR a través de nuestras cuentas asociadas o información acerca de las experiencias y las interacciones que usted haya tenido a través de nuestra red por parte de cuentas asociadas de anunciantes",
      "2.2.3. Datos de menores.",
      "Nuestros sitios web y aplicaciones no están dirigidos a menores de 18 años y no recopilamos deliberadamente ninguna información personal directamente de menores de 18 años. Si usted cree que procesamos la información personal relacionada con un menor de manera inapropiada, lo tomamos muy en serio y le instamos a que se comunique con nosotros",
    ],
  },
  {
    title: "3. COMO UTILIZAMOS LA INFORMACIÓN QUE RECOLECTAMOS.",
    li: [
      "Nosotros podremos utilizar, almacenar y procesar información personal, para abastecer, comprender, enriquecer y desarrollar la PLATAFORMA ALOHAR; para crear y mantener un entorno de confianza y con mejores condiciones de seguridad (por ejemplo, para cumplir con nuestras obligaciones legales y garantizar el cumplimiento de las políticas de BUCHU S.A.), y para proveer, personalizar, medir y mejorar nuestra publicidad y marketing",
      "3.1. Abastecer, comprender, enriquecer y desarrollar la PLATAFORMA ALOHAR",
      "Con el propósito de ofrecer mejores servicios o proveer a los USUARIOS información relacionada con sus preferencias, BUCHU S.A. a través de la PLATAFORMA ALOHAR podrá también desarrollar estudios internos sobre los intereses, comportamientos y demografía de los USUARIOS, para comprender mejor sus necesidades e intereses, mejorar nuestras iniciativas comerciales y promocionales, personalizar sus contenidos, su presentación y servicios, mostrar publicidad o promociones de interés para los USUARIOS.",
      "Podremos utilizar la información personal para abastecer, comprender, enriquecer y desarrollar a la PLATAFORMA ALOHAR:",
      "A) Para permitirle a usted acceder a la PLATAFORMA ALOHAR y utilizarla;",
      "B) Para permitirle a usted comunicarse con otros USUARIOS;",
      "C) Para gestionar las reservas y disponibilidades de alojamiento, llevar a cabo las transacciones que usted haya iniciado y procesar facturas u otra documentación fiscal;",
      "D) Para ofrecerle productos y servicios y responder a sus preguntas y comentarios;",
      "E) Remitirle confirmaciones y actualizaciones sobre su experiencia como USUARIO sea HUÉSPED o ALOHADOR, así como cualquier información relevante al respecto.",
      "F) Para realizar encuestas, estadísticas o análisis sobre hábitos de consumo o preferencias, notificarle por correo electrónico las ofertas especiales y los productos y servicios de alojamiento ofrecidos y relacionados con lugares que podrían serle de interés, salvo que usted no lo desee",
      "G) Para operar, proteger, mejorar y optimizar la PLATAFORMA ALOHAR y la experiencia de ALOHAR, por ejemplo, mediante labores de análisis e investigación;",
      "H) Para prestar servicios de atención al cliente;",
      "I) Para enviarle a usted mensajes sobre el servicio o sobre soporte, actualizaciones, alertas de seguridad y notificaciones de cuenta",
      "J) Si usted nos facilita su información de contacto, nosotros podremos procesarla para facilitarle sus invitaciones por preferencias; para enviar sus solicitudes de preferencias; para realizar labores de detección y prevención de actividades fraudulentas; y, para cualquier fin que usted autorice en el momento de la recopilación",
      "K) Para operar, proteger, mejorar y optimizar la PLATAFORMA ALOHAR y la experiencia de ALOHAR, así como para personalizar y optimizar su experiencia y facilitar reclamaciones, analizamos sus interacciones con la PLATAFORMA ALOHAR, sus búsquedas y su historial de ofertas y reservaciones, su información de perfil y sus preferencias, así como otros contenidos que usted envía a la PLATAFORMA ALOHAR",
      "Nosotros procesamos esta información personal con estos propósitos dado nuestro interés legítimo en mejorar la PLATAFORMA ALOHAR y la experiencia de nuestros USUARIOS en ella, y siempre que sea necesario para la buena ejecución del contrato formalizado con usted",
      "3.2. Crear y mantener un entorno de confianza y con mejores condiciones de seguridad: Podemos utilizar la información personal para crear y mantener un entorno confiable y seguro.",
      "A) Para detectar y prevenir actividades de fraude, correo no deseado (“spam”), insultos, incidencias de seguridad y demás actos lesivos;",
      "B) Para realizar investigaciones de seguridad y evaluaciones de riesgos;",
      "C) Para verificar o autenticar información o identificaciones que usted haya facilitado (p. ej., para verificar su dirección de alojamiento o comparar la foto que le identifica con otra foto que usted aporte)",
      "D) Para realizar cotejos con bases de datos y demás fuentes de información, con sujeción a su consentimiento, cuando proceda;",
      "E) Para cumplir nuestras obligaciones legales; ",
      "F) Para resolver cualquier controversia surgida con cualquiera de nuestros USUARIOS y exigir el cumplimiento de nuestros contratos con terceros;",
      "G) Para exigir el cumplimiento de nuestros Términos y Condiciones de Servicio.",
      "En relación con las actividades supra expuestas, podemos analizar las interacciones que realice con la PLATAFORMA ALOHAR, su información de perfil y cualquier otro contenido que usted remita a la PLATAFORMA ALOHAR, así como la información obtenida de terceros. En determinados casos, los procesos automatizados podrían restringir o suspender el acceso a la PLATAFORMA ALOHAR si dichos procesos detectan algún USUARIO o actividad que pueda suponer un riesgo para la seguridad o de otro tipo para la PLATAFORMA ALOHAR, nuestra comunidad o terceros.",
      "Tratamos esta información personal para estos propósitos, dado nuestro interés legítimo en proteger la PLATAFORMA ALOHAR y el desempeño adecuado de nuestro contrato con usted, y para cumplir con las leyes aplicables.",
      "3.3. Suministrar, personalizar, medir y mejorar nuestra publicidad y comercialización: Para suministrar, personalizar, medir y mejorar nuestra publicidad y marketing, podemos utilizar la información personal:",
      "A) Para enviarle mensajes promocionales, contenido de marketing, publicidad y otro tipo de información que pudiera interesarle en función de sus preferencias (incluida información relativa a la PLATAFORMA ALOHAR o a campañas y servicios para USUARIOS), así como publicidad a través de las plataformas de comunicación sociales como Facebook o Google;",
      "B) Para personalizar, medir y mejorar nuestra publicidad",
      "C) Para administrar programas de recomendación, premios, encuestas, sorteos, concursos u otras actividades o eventos promocionales patrocinados o gestionados por la PLATAFORMA ALOHAR o por nuestras cuentas asociadas;",
      "D) Para optimizar características suyas y preferencias (a partir de la información que usted nos proporcione, sus interacciones con la PLATAFORMA ALOHAR, la información obtenida de terceros y su historial de búsquedas y de reservaciones) para enviarle mensajes promocionales, de marketing, publicitarios, así como otra información que consideremos que puede ser de su interés;",
      "E) Para facilitarle la inscripción en una suscripción de correo electrónico, tenga en cuenta que es posible no pueda beneficiarse de ciertas promociones si no tiene una cuenta de USUARIO en La PLATAFORMA ALOHAR;",
      "F) Para invitarle a eventos y oportunidades relevantes",
      "Procesaremos su información personal para los fines destacados en el presente apartado teniendo en cuenta nuestro interés legítimo por realizar actividades de marketing, para ofrecerle productos o servicios que puedan ser de su interés, pudiendo darse de baja de estas comunicaciones de marketing siguiendo las instrucciones al respecto que figuran en las mismas comunicaciones de marketing",
      "3.4 Cómo utiliza la información recopilada el Responsable del Tratamiento de los Datos de Pago. Podemos utilizar la información personal como parte de los servicios de pago:",
      "A) Para permitirle acceder y utilizar los Servicios de Pago;",
      "B) Para detectar y prevenir fraude, abusos, incidentes de seguridad y otras actividades dañinas;",
      "C) Para realizar investigaciones de seguridad y evaluaciones de riesgos;",
      "D) Para realizar comprobaciones de bases de datos y otras fuentes de información",
      "E) Para cumplir con obligaciones legales (como las normativas de prevención del lavado de     dinero);",
      "F) Para aplicar los Términos de Pago y otras políticas de pago;",
      "G) Con su consentimiento, para enviarle mensajes promocionales, de marketing, publicitarios y     otra información que pueda ser de su interés en función de sus preferencias.",
      "El Responsable del Tratamiento de los Datos de Pago procesa esta información personal     teniendo en cuenta su interés legítimo por mejorar los servicios de pago y la experiencia de sus     usuarios con ellos, y en la medida en que resulte necesario para la adecuada ejecución del     contrato formalizado con usted, así como para cumplir la legislación aplicable.",
    ],
  },
  {
    title: "4. A QUIÉN COMPARTIMOS Y/O DIVULGAMOS LA INFORMACIÓN RECOPILADA.",
    li: [
      "4.1 Publicidad y medios sociales, con su consentimiento",
      "Cuando usted haya dado su consentimiento, nosotros podremos compartir su información, incluidos sus datos personales, tal y como se describen éstos en el momento en que haya dado su consentimiento, como cuando usted autoriza que una aplicación o sitio web de terceros acceda a su cuenta de USUARIO en la PLATAFORMA ALOHAR o cuando participa en actividades promocionales llevadas a cabo por USUARIOS de la PLATAFORMA ALOHAR o terceros",
      "Podemos usar cierta información personal limitada sobre usted, como su dirección de correo electrónico, y compartirla con plataformas de redes sociales, como Facebook o Google, para generar clientes potenciales, impulsar el tráfico a nuestros sitios web o de otro modo promocionar nuestros productos y servicios o la PLATAFORMA ALOHAR. Estas actividades de procesamiento se basan en nuestro interés legítimo en emprender actividades de marketing para ofrecerle productos o servicios que puedan ser de su interés.",
      "BUCHU S.A. no controla ni supervisa las plataformas de redes sociales con las que podamos compartir su información personal. Por lo tanto, cualquier pregunta sobre cómo su proveedor de servicios de plataforma de redes sociales procesa su información personal debe dirigirse a dicho proveedor. ",
      "Tenga en cuenta que puede, en cualquier momento, solicitar que BUCHU S.A. a través de la PLATAFORMA ALOHAR deje de procesar sus datos para estos propósitos de marketing directo, enviando un correo electrónico a esos efectos. ",
      "4.2 Compartir información entre USUARIOS.-",
      "Para ayudar a facilitar la formalización de anuncios o reservaciones u otras interacciones entre USUARIOS, nosotros podríamos tener que compartir determinada información, incluidos sus datos personales, con otros USUARIOS, en la medida en que sea necesario para la adecuada ejecución del contrato formalizado entre usted y nosotros, de las formas descritas a continuación:",
      "A) Cada vez que en calidad de HUESPED usted envía una solicitud de reservación utilizando un anuncio en la PLATAFORMA ALOHAR, determinada información acerca de usted es compartida con el ALOHADOR, incluidos su perfil, nombre completo, el nombre completo de cualquier HUESPED adicional, su historial de cancelaciones y cualquier otra información que usted como USUARIO acuerde compartir. Una vez confirmada su reservación, nosotros divulgaremos información adicional para prestar asistencia a la coordinación del viaje, como su número de teléfono. ",
      "B) Cada vez que en calidad de ALOHADOR, usted tenga una reserva confirmada de su anuncio mediante la PLATAFORMA ALOHAR, determinada información es compartida con el HUÉSPED (y los invitados adicionales que puedan invitar, si corresponde) para coordinar la reservación, como su perfil, nombre completo, número de teléfono y dirección del alojamiento o de la experiencia.",
      "C) Cada vez que en calidad de HUÉSPED usted invita a HUÉSPEDES adicionales a una reservación, sus nombres y apellidos, sus fechas de viaje, el nombre del ALOHADOR, los detalles del Anuncio, la dirección del Alojamiento y otra información relacionada se compartirán con los HUÉSPEDES adicionales.",
      "Nosotros no compartimos su información de facturación y de desembolsos con otros USUARIOS.",
      "4.3 Perfiles, anuncios y demás información pública.",
      "La PLATAFORMA ALOHAR le permite a usted como USUARIO publicar información, incluidos los datos personales, que sea visible para el público en general. Por ejemplo:",
      "A) Secciones de su página de perfil público, como su nombre, su descripción y ciudad, son visibles para el público.",
      "B) Las páginas de anuncios son visibles para el público e incluyen información como la ubicación aproximada del bien inmueble ofrecido (provincia y localidad) o la ubicación exacta (cuando haya dado su consentimiento), la disponibilidad, su foto de perfil pública, información agregada y cualquier información complementaria que usted elija compartir.",
      "C) Tras completar la reservación, los HUÉSPEDES y los ALOHADORES podrán escribir evaluaciones y valorarse los unos a los otros. Las evaluaciones y las valoraciones forman parte de su página de perfil pública y podrán reflejarse en otros lugares de la PLATAFORMA ALOHAR (como la página del anuncio).",
      "D) Si usted envía contenido a un foro de una conversación o a una comunidad, un blog o mediante una publicación en redes sociales, o utiliza una función similar en la PLATAFORMA ALOHAR, tal contenido es visible para el público.",
      "Teniendo en cuenta nuestro interés legítimo por promocionar la PLATAFORMA ALOHAR, podemos mostrar partes de LA PLATAFORMA (por ejemplo, su página de anuncio) en sitios web gestionados por socios comerciales de BUCHU S.A. Si sus anuncios aparecen en un sitio web asociado, también podrá aparecer la información pública de su página de perfil",
      "Toda información que usted comparta públicamente en la PLATAFORMA ALOHAR, podrá ser detectada a través de motores de búsqueda de terceros. En algunos casos, usted podrá deshabilitar esta función en los ajustes de su cuenta. Si usted cambia sus ajustes o su contenido público disponible, estos motores de búsqueda no podrán actualizar sus bases de datos. Nosotros no controlamos las prácticas de motores de búsqueda de terceros.",
      "4.4 Servicios Adicionales que prestan los ALOHADORES",
      "Los ALOHADORES pueden necesitar utilizar los servicios de terceros disponibles a través de la PLATAFORMA ALOHAR para prestar asistencia a la gestión de su alojamiento o a la prestación de servicios adicionales que usted como USUARIO HUESPED solicite, como servicios de limpieza o alguna reparación, etc. Los ALOHADORES podrán utilizar funciones en la PLATAFORMA ALOHAR para compartir información acerca del HUESPED (como fechas de entrada y salida, nombre, número de teléfono del HUESPED), con dichos terceros proveedores de servicios con el fin de coordinar la estadía, gestionar el alojamiento o prestar otros servicios. Los ALOHADORES son responsables de los proveedores de servicios de terceros que utilizan y garantizan que dichos proveedores de servicios procesan la información de los HUESPEDES de forma segura y de conformidad con la legislación aplicable de privacidad y protección de datos. ",
      "4.5 Cumplimiento normativo, respuesta a requerimientos legales, prevención de daños y protección de nuestros derechos",
      "BUCHU S.A. a través de su PLATAFORMA ALOHAR e INGENICO Payments, podrán divulgar su información, incluidos sus datos personales, a Jueces, Tribunales Colegiados, Fuerzas de Seguridad del Estado o agencias gubernamentales, autoridades gubernamentales, autoridades fiscales, o a terceros autorizados, en caso estemos obligados o autorizados a ello, conforme a Derecho: 1) Para cumplir nuestras obligaciones legales; 2) Para cumplir con una solicitud legal válida o para responder a reclamos presentados contra BUCHU S.A., LA PLATAFORMA ALOHAR o INGENICO Payments; 3) Para responder a una solicitud legal válida relacionada con una investigación criminal o presunta o actividad ilegal o cualquier otra actividad que pueda exponernos a nosotros, a usted o a cualquier otro de nuestros USUARIOS; 4) Para hacer cumplir y administrar nuestros Términos y Condiciones de Servicio, los Términos de Pago u otros acuerdos con los USUARIOS, o; 5) para proteger los derechos, la propiedad o la seguridad de BUCHU S.A. o de LA PLATAFORMA ALOHAR, de sus empleados o de sus USUARIOS. ",
      "Suministrar dicha información puede ser necesario para cumplir con nuestras obligaciones legales, para la protección de los intereses vitales de usted o los de otra persona o para los propósitos de nuestro interés legítimo o de un tercero en mantener la PLATAFORMA ALOHAR segura, previniendo cualquier daño o actividad criminal, defendiendo derechos, facilitando la recaudación de impuestos y la prevención del fraude fiscal. ",
      "Cuando corresponda, podremos notificar a los USUARIOS sobre los requerimientos legales que los incluyan",
      "4.6 Proveedores de servicios.",
      "Podemos utilizar terceros proveedores para ayudarnos a prestar servicios relacionados con la PLATAFORMA ALOHAR e INGENICO Payments.",
      "Los proveedores de servicios podrán ayudarnos, por ejemplo, a lo siguiente: 1) Verificar su identidad o autenticar sus documentos de identificación; 2) Cotejar información con bases de datos públicas; 3) Realizar comprobaciones para la prevención de prácticas fraudulentas y evaluación de riesgos; 4) Realizar tareas de desarrollo de producto, mantenimiento y subsanación de errores; 5) Permitir la prestación de los servicios de la PLATAFORMA ALOHAR a través de otras plataformas y herramientas de software de terceros; 6) Prestar servicios de atención al cliente, publicidad o servicios de pagos; o, 7) Procesar, manejar o evaluar reclamaciones",
      "Tales proveedores disponen de acceso limitado a su información personal y solo la utilizarán de acuerdo con nuestras instrucciones.",
      "La PLATAFORMA ALOHAR e INGENICO Payments tendrán que compartir su información, incluidos sus datos personales, para garantizar la adecuada ejecución del contrato formalizado entre usted y nosotros. ",
      "4.7 Cuestiones comerciales",
      "Si BUCHU S.A. llevase a cabo una fusión, adquisición, reorganización, venta de activos o entra en un procedimiento de declaración de quiebra o concurso preventivo de acreedores, o si esta sociedad participa en cualquiera de tales procesos, nosotros podremos vender, transmitir o compartir la totalidad o parte de nuestros activos, incluida su información relativa a dicha transacción o relacionada con la misma. En tal caso, nosotros le informaremos antes que sus datos personales sean transferidos y pasen a estar sujetos a una política de privacidad diferente. ",
      "4.8 Datos agregados.-",
      "Nosotros podremos también compartir datos agregados (información sobre nuestros usuarios que combinamos de modo que deje de identificar o aludir a un usuario concreto) y otra información anonimizada necesaria para el cumplimiento normativo, con fines de análisis sectoriales y de mercado, la búsqueda, elaboración de perfiles demográficos, marketing, publicidad y demás fines comerciales.",
    ],
  },
  {
    title: "5. OTRA INFORMACIÓN IMPORTANTE.",
    li: [
      "5.1 Análisis de sus comunicaciones.",
      "Nosotros podremos revisar, explorar o analizar las comunicaciones que usted entable en la PLATAFORMA ALOHAR a efectos de prevención de actividades fraudulentas, evaluación de riesgos, realización de averiguaciones, desarrollo de productos, desarrollo de actividades de investigación, análisis y prestación de servicios de soporte del cliente. En algunos casos, nosotros también podremos explorar, revisar o analizar mensajes para depurar, mejorar y ampliar las ofertas de productos. Nosotros empleamos métodos automatizados en los casos en que sea posible dentro de la lógica. Sin embargo, en ocasiones es posible tengamos que revisar manualmente algunas comunicaciones a efectos de la realización de investigaciones sobre actividades fraudulentas o para la prestación de servicios de soporte al cliente, o evaluar y mejorar la funcionalidad de tales herramientas automatizadas. Nosotros no revisaremos, exploraremos ni analizaremos sus comunicaciones para enviarle a usted mensajes de marketing de terceros, ni venderemos comentarios o análisis de éstas",
      "Estas actividades se llevan a cabo habida cuenta del interés legítimo de BUCHU S.A. por asegurar el cumplimiento de la legislación aplicable y de nuestros Términos y Condiciones, prevenir el fraude, promover la seguridad y mejorar y asegurar una prestación adecuada de nuestros servicios.",
      "5.2 Asociación de cuentas de terceros",
      "Usted podrá asociar una cuenta suya que tenga en un servicio de redes sociales de terceros con su cuenta de USUARIO en la PLATAFORMA ALOHAR. Cuando usted cree este enlace:",
      "A) Determinados datos que usted nos proporcione al asociar sus cuentas podrán ser publicados en el perfil de su cuenta de USUARIO en la PLATAFORMA ALOHAR;",
      "B) Sus actividades en la PLATAFORMA ALOHAR o del sitio web del tercero en cuestión, podrán mostrarse a otros USUARIOS de PLATAFORMA;",
      "C) Podrá incluirse en su perfil público de la PLATAFORMA ALOHAR un enlace al perfil público que usted posea en tal servicio de redes sociales de terceros;",
      "D) Otros USUARIOS de la PLATAFORMA ALOHAR podrán ver cuantos contactos tengan en común con usted conforme aparezcan publicados en el servicio de redes sociales con el que haya asociado su cuenta;",
      "E) Los datos que usted nos proporcione al asociar sus cuentas podrán ser almacenados, tratados y transmitidos a los efectos de prevenir el desarrollo de actividades fraudulentas y evaluar riesgos;",
      "F) La publicación y la exhibición de los datos que usted proporcione a la PLATAFORMA ALOHAR al asociar las cuentas estarán sujetas a la configuración y autorizaciones que haya elegido en LA PLATAFORMA y el sitio web del tercero. ",
      "Solo recopilamos su información de las cuentas de terceros vinculadas en la medida en que resulta necesaria para garantizar la adecuada ejecución del contrato formalizado entre usted y nosotros o para asegurar que cumplimos con la legislación aplicable, o cuando usted nos haya dado su consentimiento.",
      "5.3 Terceros e integraciones de terceros.",
      "La PLATAFORMA ALOHAR podrá contener enlaces a sitios web o Servicios de Terceros. BUCHU S.A. no es propietaria de tales Terceros ni los controla; y, cuando usted interactúe con éstos, es posible que esté facilitando información directamente al Tercero, a LA PLATAFORMA o a ambos. Tales Terceros suelen disponer sus propias normas en materia de recopilación, uso y divulgación de información. Nosotros le animamos a que revise las políticas de privacidad del resto de sitios web que visite. ",
      "Algunas partes de la PLATAFORMA ALOHAR utilizan los servicios de Google Maps/Google Earth, incluidas las API de Google Maps. El uso de Google Maps/Google Earth está sujeto a los términos de servicio Adicionales de Google Maps/Google Earth y a la Política de Privacidad de Google",
    ],
  },
  {
    title: "6. DERECHOS.",
    li: [
      "Los USUARIOS tienen la facultad de ejercer el derecho de acceso a sus datos en forma gratuita a intervalos no inferiores a seis (6) meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. La Dirección Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. Asimismo, se deja constancia que el titular de los datos podrá en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos (Ley 25.326, art. 27, inc. 3). ",
      "6.1 Administración de su información.",
      "Usted podrá acceder y actualizar algunos de sus datos a través de las funcionalidades de su cuenta. Si ha elegido vincular su cuenta de USUARIO en la PLATAFORMA ALOHAR con alguna aplicación de terceros, como Facebook o Google, puede cambiar la configuración y retirar el permiso de la aplicación cambiando las funcionalidades de su cuenta de USUARIO. Usted será responsable de mantener su información personal actualizada. ",
      "6.2 Rectificación de información incorrecta o incompleta.",
      "Usted tiene derecho a pedirnos que rectifiquemos cualquier información personal incorrecta o incompleta relativa a usted (y que no pueda modificar usted en su cuenta de USUARIO).",
      "6.3 Retención y eliminación de los datos",
      "En general, retendremos su información personal durante todo el tiempo que resulte necesario para la ejecución del contrato formalizado entre usted y nosotros y para cumplir nuestras obligaciones legales. En ciertas ocasiones, podrá solicitar que toda su información personal se elimine por completo.",
      "Tenga en cuenta que, si solicita la eliminación de su información personal:",
      "A) Podremos retener algunos de sus datos personales en la medida en que resulte necesario para nuestros legítimos intereses comerciales, como detectar y prevenir el fraude y mejorar la seguridad. Por ejemplo, si suspendemos una cuenta de USUARIO por fraude o por razones de seguridad, podemos retener cierta información de dicha cuenta en la PLATAFORMA ALOHAR para impedir que dicho USUARIO vuelva a abrir otra cuenta en LA PLATAFORMA.",
      "B) Podemos retener y utilizar su información personal en la medida en que resulte necesaria para cumplir nuestras obligaciones legales. Por ejemplo, BUCHU S.A. e INGENICO Payments podrán conservar parte de su información en cumplimiento de obligaciones fiscales, jurídicas y/o de auditoría. ",
      "C) La información que usted haya compartido con otros (por ejemplo, Evaluaciones o publicaciones en foros) podrán seguir siendo visibles al público en la PLATAFORMA ALOHAR, incluso después de que se haya cancelado su cuenta de USUARIO. Sin embargo, dicha información dejará de estar vinculada  con usted. Asimismo, es posible que algunas copias de su información (por ejemplo, datos de registro) permanezcan en nuestra base de datos, si bien quedarán desprovistos de información que le identifique personalmente.",
      "D) Puesto que nos aseguramos de proteger la PLATAFORMA ALOHAR de pérdidas o de su destrucción accidental o malintencionada, es posible que copias residuales de su información personal no sea eliminada de nuestros sistemas de copia de seguridad durante un periodo de tiempo limitado. ",
      "6.4 Revocación del consentimiento y restricción del tratamiento de los datos.",
      "En aquellos casos en los que usted haya dado su consentimiento al tratamiento de su información personal por parte de BUCHU S.A. a través de la PLATAORMA ALOHAR, usted podrá revocar su consentimiento en cualquier momento cambiando las funcionalidades de su cuenta de USUARIO o enviando una comunicación a LA PLATAFORMA indicando específicamente el consentimiento que desea revocar. Tenga en cuenta que la revocación de su consentimiento no afecta a la legalidad del tratamiento de datos sujeto a su consentimiento que se haya realizado antes de dicha revocación. ",
      "Asimismo, la legislación aplicable puede otorgarle el derecho a intervenir en el modo en que hacemos uso de su información personal, particularmente en aquellos casos en que:",
      "A) Usted impugne la veracidad de su información personal;",
      "B) Ya no necesitemos su información personal para su tratamiento pero usted solicite la información para la incoación, el ejercicio o la defensa de acciones legales;",
      "C) Usted se haya opuesto al tratamiento de conformidad con la Cláusula 6.4, en cuyo caso se verificará si BUCHU S.A. dispone de causas justificadas que prevalezcan por encima de las de usted.",
      "6.4 Oposición al procesamiento de los datos.",
      "En algunas ocasiones, la legislación aplicable podrá otorgarle el derecho a exigir a BUCHU S.A. e INGENICO Payments que no procesen su información personal para fines concretos, cuando dicho procesamiento se realice en base a un interés legítimo. Si usted se opone a dicho procesamiento, BUCHU S.A. y/o INGENICO Payments dejarán de utilizar sus datos personales para dichos fines salvo que podamos demostrar que existen causas justificadas para realizar dicho procesamiento o salvo que dicho procesamiento sea necesario para la determinación el ejercicio o la defensa de acciones legales.",
      "Si su información personal se procesa con fines de marketing directo, usted podrá pedir en cualquier momento a BUCHU S.A. mediante la PLATAFORMA ALOHAR que interrumpa dicho procesamiento de los datos para fines de marketing comercial enviándonos un correo electrónico. Usted tiene el derecho de presentar quejas sobre nuestras actividades de procesamiento de datos",
      "Nosotros no vendemos información personal a terceros, ni permitimos que terceros recopilen información personal a través de nuestro servicio y compartan información personal con terceros para los fines comerciales descritos en esta Política de Privacidad, incluyendo, entre otros, publicidad y marketing en nuestro servicio y en cualquier otro lugar en función de las actividades en línea de los USUARIOS a lo largo del tiempo y en diferentes sitios, servicios y dispositivos",
    ],
  },
  {
    title: "7. SEGURIDAD.",
    li: [
      "Nosotros adoptamos y actualizamos continuamente medidas de seguridad administrativas, técnicas y físicas para contribuir a la protección de su información e impedir que ésta sea destruida o modificada sin autorización o que se acceda a la misma sin permiso. Los cortafuegos, el cifrado de datos y los controles de acceso a la información son algunas de las salvaguardas que utilizamos para proteger su información. Si usted conoce o tiene motivos para creer que las claves o información de acceso a su cuenta de la PLATAFORMA ALOHAR han sido objeto de pérdida, sustracción, apropiación indebida o están en riesgo por algún otro motivo o si sabe o sospecha que se ha utilizado su cuenta de USUARIO sin autorización, póngase en contacto con nosotros.",
      "7.1 Seguridad de las contraseñas",
      "Para acceder y editar su perfil de USUARIO en la PLATAFORMA ALOHAR, utilizará una contraseña. BUCHU S.A. no tendrá acceso ni dispondrá en ningún momento de dicha contraseña y aconseja a los USUARIOS a consignar una contraseña alfanumérica con altos grados de seguridad y que no tenga relación con información sobre su persona a fin que no pueda ser deducida por terceros. Asimismo, se recomienda que sea modificada periódicamente. Los USUARIOS deberán guardar absoluta seguridad y confidencialidad de las contraseñas en el sitio web siendo los únicos responsables legales de todas las acciones que desde su cuenta se realicen, incluso si su contraseña fuera utilizada de cualquier forma por terceros, lo que incluye el pago de las tarifas que se devenguen o por los perjuicios que puedan sufrir otros USUARIOS o BUCHU S.A. Por dicha razón, deberán realizar el mayor esfuerzo para evitar la utilización de LA PLATAFORMA por parte de terceras personas ajenas al USUARIO.",
    ],
  },
  {
    title: "8. MODIFICACIONES DE LA PRESENTE POLÍTICA DE PRIVACIDAD.",
    li: [
      "BUCHU S.A. se reserva el derecho a modificar la presente Política de Privacidad en cualquier momento con arreglo a esta disposición. Si hacemos cambios en la presente Política de Privacidad, nosotros publicaremos la versión revisada de ésta en la PLATAFORMA ALOHAR y actualizaremos la fecha de última actualización, que figura al comienzo del presente documento. Asimismo, nosotros le notificaremos de las modificaciones enviándole un correo electrónico, como mínimo, treinta (30) días antes de que éstas entren en vigor. Si está en desacuerdo con la versión revisada de la Política de Privacidad, usted podrá cancelar su cuenta de USUARIO. Si usted no cancela su cuenta de USUARIO antes de la fecha de entrada en vigor de la versión revisada de la Política de Privacidad, el acceso continuado que usted haga a la PLATAFORMA ALOHAR así como el uso continuado de la misma por su parte constituirán aceptación de dicha versión revisada. ",
    ],
  },
  {
    title: "9. REGISTRO DE LA BASE DE DATOS.",
    li: [
      "Los datos recabados por través de la PLATAFORMA ALOHAR son incorporados a una base de datos de la cual BUCHU S.A. es responsable. La misma se encuentra registrada en la Dirección Nacional de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos de la Nación (República Argentina), en cumplimiento con lo dispuesto en el art. 3 de la Ley 25.326.",
    ],
  },
  {
    title: "10. CONTÁCTENOS",
    li: [
      "Si usted tiene alguna duda o queja acerca de la presente Política de Privacidad o sobre las prácticas de manipulación de información de BUCHU S.A., podrá ponerse en contacto con nosotros por correo electrónico, mediante las direcciones de correo electrónico proporcionadas en las secciones correspondientes o por correspondencia postal a calle Córdoba N° 1957, piso 8°, depto. “D” de la ciudad de Mar del Plata, Provincia de Buenos Aires, República Argentina",
    ],
  },
];
