// Librerías
import React from 'react';
// Recursos
import '../../assets/styles/Utils/_customToggle.sass';

const CustomToggle = ({ id, name, title, value, checked, onChange, source = ""}) => {

    const [state, setState] = React.useState({
        checked: checked
    });


    const handleChecked = async (event) => {

        if(onChange){
            const success = await onChange(event)
            if(success) setState({checked: !state.checked})
        }
        else{
            setState({
                checked: event.target.checked
            })
        }
    };

    return(
        <div className="CustomToggle">
            <label className="CustomToggle__switch" htmlFor={ `${id}-${name}-${source}` }>
                <input
                    className="CustomToggle__checkbox"
                    type="checkbox"
                    checked={state.checked}
                    value={value}
                    id={ `${id}-${name}-${source}` }
                    name={ name }
                    onChange={ handleChecked }
                />
                <span className="CustomToggle__slider"></span>
            </label>
            <p className="CustomToggle__title">
                { (state.checked) ? "activar" : "activar" }
            </p>
        </div>
    );
}

export default CustomToggle;