// Librerías
import React from 'react';
// Componentes
import HousingList from '../../../components/BackOffice/Housing/Housing';

const Housing = () => {
  return(
    <HousingList />
  );
}

export default Housing;