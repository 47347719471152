import React from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Helpers";
import AuthService from "../../services/auth";
import { logoutUser } from "../../store/user/actions";
import { restart } from "../../store/aloharForm/actions";
import { openLoginModal } from "../../store/modal/actions";
import { redirectUser } from "../../store/user/actions";
import NotificationMessage from "../MessengersPage/Utils/notification-message";
import ArrowSmall from "../../assets/components-icons/ArrowSmall";
import "../../assets/styles/Navbar/_user_menu.sass";
import CrossIcon from "../../assets/components-icons/CrossIcon";
import useCurrentLanguage from "../../services/currentlenguaje";
import userMenuJson from "./tradNavbar.json";

const UserMenu = (props) => {
  const userRol = localStorage.getItem("role");

  const { handleFilterLang } = useCurrentLanguage();

  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = (evt) => {
    localStorage.removeItem("housingPublicProcessData");
    AuthService.logout();
    dispatch(logoutUser());

    history.push("/");
  };
  const userData = JSON.parse(localStorage.getItem("userData"));

  /* const admin = JSON.parse(localStorage.getItem('role')).role === 'admin' */
  const handleNewSapce = () => {
    localStorage.removeItem("housingPublicProcessData");
    dispatch(restart());
    history.push("/alohar");
  };
  const setImg = () => {
    if (userData) {
      if (userData?.avatar && userData?.firstName) {
        if (userData?.avatar.includes("http")) {
          return (
            <img
              className="rounded-full sm:w-28 sm:h-28"
              alt={`foto`}
              src={userData?.avatar}
            />
          );
        } else {
          return (
            <img
              className="rounded-full sm:w-28 sm:h-28"
              alt={`foto`}
              src={`${BASE_URL}/${userData?.avatar}`}
            />
          );
        }
      }
      return (
        <img
          className="rounded-full sm:w-28 sm:h-28"
          alt={`foto`}
          src={`https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF7D52&color=fff&name=${userData?.firstName}`}
        />
      );
    }
    return (
      <svg
        className="w-[75%] h-[75%]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <g
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="2"
          fill="none"
          stroke="currentColor"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </g>
      </svg>
    );
  };

  return (
    <div
      className={
        props.isLanding ? "translate-y-0 right-0 top-0  user_menu" : "user_menu"
      }
    >
      <div className="flex flex-col w-full overflow-y-scroll sm:overflow-y-visible h-[600px] sm:h-auto p-[0!important]">
        <div className="w-full bg-dark50 sm:rounded-tl-14 sm:rounded-tr-14">
          <div className="user_menu__top">
            <div className=" user_menu__top__close">
              <CrossIcon className="hidden sm:block" color={"#fff"} />
              <span className="block sm:hidden sm:opacity-0">
                <ArrowSmall color={"#fff"} className={"block sm:hidden"} />
              </span>
            </div>
            <span className="flex items-center justify-between">
              <div className="user_menu__top__avatar">{setImg()}</div>
            </span>
          </div>
        </div>
        {userData?.firstName ? (
          <>
            {userRol.role === "admin" && (
              <Link
                rel="noopener noreferrer"
                className="principal"
                to="/back-office"
              >
                Back-office
              </Link>
            )}

            <div className=" bg-dark50">
              <Link
                rel="noopener noreferrer"
                className="principal border-b-[1px]   border-white  hover:rounded-none"
                to="/profile/"
              >
                <p className="flex items-center uppercase sm:text-white">
                  M
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuMis}
                  </span>
                </p>
                <p className="flex items-center uppercase sm:text-white">
                  D
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuDatos}
                  </span>
                </p>
              </Link>
            </div>

            <div className=" bg-dark50">
              <Link
                rel="noopener noreferrer"
                className="principal border-b-[1px]  border-white  hover:rounded-none"
                to="/profile/housing"
              >
                <p className="flex items-center uppercase sm:text-white ">
                  M
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuMis}
                  </span>
                </p>
                <p className="flex items-center uppercase sm:text-white">
                  A
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuAlohamientos}
                  </span>
                </p>
              </Link>
            </div>
            <div className=" bg-dark50">
              <Link
                rel="noopener noreferrer"
                className="principal border-b-[1px]  border-white  sm:hover:rounded-none"
                to="/messengers"
              >
                <p className="flex items-center uppercase sm:text-white">
                  M
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuMis}
                  </span>
                </p>
                <p className="flex items-center uppercase sm:text-white">
                  M
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuMensajes}
                  </span>
                </p>
                {props.isMenuOpen && (
                  <NotificationMessage title={props.read} userMenu={true} />
                )}
              </Link>
            </div>

            <div className=" bg-dark50 userMenuBorderWhite">
              <Link
                rel="noopener noreferrer"
                className="flex flex-col sm:hover:rounded-none principal"
                to="/profile/travels"
              >
                <p className="flex items-center uppercase sm:text-white">
                  M
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuMis}
                  </span>
                </p>
                <p className="flex items-center uppercase sm:text-white">
                  {handleFilterLang(userMenuJson).menuViajesV}

                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuViajes}
                  </span>
                </p>
              </Link>
            </div>

            <div className=" bg-dark50 userMenuBorderWhite">
              <Link
                rel="noopener noreferrer"
                className=" border-t-[1px]  border-white sm:hover:rounded-none  principal"
                to="/profile/favorites"
              >
                <p className="flex items-center uppercase sm:text-white">
                  M
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuMis}
                  </span>
                </p>
                <p className="flex items-center uppercase sm:text-white">
                  F
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuFavoritos}
                  </span>
                </p>
              </Link>
            </div>

            <div className="bg-white ">
              <Link
                rel="noopener noreferrer"
                className=" sm:hover:rounded-none principalWhite"
                onClick={() => handleNewSapce()}
              >
                <p className="flex items-center uppercase sm:text-black">
                  P
                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuPublicar}
                  </span>
                </p>
                <p className="flex items-center pl-1 uppercase sm:text-black">
                  {handleFilterLang(userMenuJson).menuEspacioE}

                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuEspacio}
                  </span>
                </p>
              </Link>
            </div>
            <div className="bg-white ">
              <Link
                rel="noopener noreferrer"
                className=" sm:hover:rounded-none principalWhite border-b-[1px]  border-dark50 "
                to="/ayuda"
              >
                <p className="flex items-center uppercase sm:text-black">
                  {handleFilterLang(userMenuJson).menuAyudaA}

                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuAyuda}
                  </span>
                </p>
              </Link>
            </div>
            <div className="bg-white rounded-bl-14 rounded-br-14">
              <Link
                rel="noopener noreferrer"
                className="  sm:hover:rounded-b-[26px] w-4/5 sm:w-full mx-auto  py-2 sm:text-center text-[15px]  flex sm:justify-center justify-start text-start gap  border-b-[1px] border-none  last"
                onClick={() => handleLogout()}
              >
                <p className="flex items-center uppercase text-primaryHome  sm:text-[black!important]">
                  {handleFilterLang(userMenuJson).menuCerrarC}

                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuCerrar}
                  </span>
                </p>
                <p className="flex items-center pl-1 uppercase text-primaryHome  sm:text-[black!important]">
                  {handleFilterLang(userMenuJson).menuSesionS}

                  <span className="uppercase sm:lowercase">
                    {handleFilterLang(userMenuJson).menuSesion}
                  </span>
                </p>
              </Link>
            </div>
          </>
        ) : (
          <>
            <Link
              rel="noopener noreferrer"
              className={"principal mobile"}
              to="/login"
              onClick={(e) => {
                e.preventDefault();
                dispatch(redirectUser(props.redirect));
                history.push("/login");
              }}
            >
              {handleFilterLang(userMenuJson).menuIniciarSesion}
            </Link>
            <Link
              rel="noopener noreferrer"
              className="principal mobile"
              to="/register"
            >
              {handleFilterLang(userMenuJson).menuRegistrar}
            </Link>
            <Link
              rel="noopener noreferrer"
              className="principal mobileNone"
              onClick={() => dispatch(openLoginModal())}
            >
              {handleFilterLang(userMenuJson).menuIniciarSesion}
            </Link>
            <Link
              rel="noopener noreferrer"
              className="principal mobileNone"
              to="/register"
            >
              {handleFilterLang(userMenuJson).menuRegistrar}
            </Link>
            <Link rel="noopener noreferrer" className="principal " to="/ayuda">
              {handleFilterLang(userMenuJson).ayuda}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(null, { logoutUser, restart })(UserMenu);
