// Librerías
import React from 'react'
// Componentes
import Feature from './Feature';
// Recursos
import '../../../assets/styles/Profile/Utils/_confirmedprofile.sass';

const ConfirmedProfile = (props) => {
  return (
    <div className="ConfirmedProfile">
      <h5 className="ConfirmedProfile__name">
        {`${props.user?.firstName} confirmó`} 
      </h5>
      {
        props.user?.dniConfirmed
        ? <Feature title="Identidad" small={true} icon={props.icon}/>
        : ''
      }

      {
        props.user?.emailConfirmed
        ? <Feature title="Correo electrónico" small={true} icon={props.icon}/>
        : ''
      }
      {
        props.user?.phoneNumberConfirmed
        ? <Feature title="Número telefónico" small={true} icon={props.icon}/>
        : ''
      }
    </div>
  )
}

export default ConfirmedProfile;