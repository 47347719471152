import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setUserAvatar } from "../../store/user/actions";
import award from "../../assets/static/Icons/award.svg";
import { BASE_URL } from "../../Helpers";
import AxiosAuth from "../../services/requestApi";
import check from "../../assets/static/Icons/check.svg";
import MainUserProfile from "../UserProfilePage/MainUserProfile";
import UserEditComponent from "../Users/UserEditComponent";
import ButtonPrimary from "../ui/button-primary";
import { myUser } from "../../services/localStorageService";
import { caclValuations } from "./calcValuations";

import "../../assets/styles/ProfileCard/_user_profile_card.sass";
import StarIcon from "../../assets/components-icons/StarIcon";
import useCurrentLanguage from "../../services/currentlenguaje";
import jsonProfileCard from "./tradProfileCard.json";

const ProfileCard = (props) => {

  const {handleFilterLang} = useCurrentLanguage ();
  
  
  const {  firstName } = props;
  const [avatar, setAvatar] = useState(false);
  const [showBankAccount, setShowBankAccount] = useState(false);
  const [valuationsLength, setValuationsLength] = useState(0);
  const [valuations, setValuations] = useState([]);

  const url = `${BASE_URL}/user/avatar`;
  const input = useRef();
  const [avatarUrl, setAvatarUrl] = useState(
    "https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff"
  );
  const dispatch = useDispatch();
  const sendPhto = (file) => {
    let data = new FormData();
    data.append("avatar", file);

    AxiosAuth.defaults.headers.post["Content-Type"] = `multipart/form-data;`;

    const url = BASE_URL + "/user/avatar";

    AxiosAuth.post(url, data)
      .then((response) => {
        if (response.status === 201) {
          setAvatar(true);
          const avatar = BASE_URL + response.data.replace("public", "");
          dispatch(setUserAvatar(avatar));
          setAvatarUrl(avatar);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAvatar = () => {
    AxiosAuth.get(url)
      .then((response) => {
        // If NULL AVATAR
        let userAvatarDefault = `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${firstName}`;
        if (response.data.avatar) {
          let avatar = response.data.avatar;
          if (avatar.includes("http" | "https")) userAvatarDefault = avatar;
          if (avatar.includes("jpg" | "jpeg" | "png"))
            userAvatarDefault =
              userAvatarDefault = `${BASE_URL}${avatar.replace("public", "")}`;
        }
        setAvatar(true);
        setAvatarUrl(userAvatarDefault);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openDialog = () => {
    input.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    const localImageUrl = URL.createObjectURL(file);
    setAvatar(localImageUrl);
    sendPhto(file);
  };

  useEffect(() => {
    if (!avatar && firstName.length) getAvatar();
  });
  const [password, setPassword] = useState(false);
  const [userCard, setUserCard] = useState(true);

  const scrollDown = () => {
    setPassword(true);
    window.scrollTo(0, 1100);
    setTimeout(() => {
      window.scrollTo(0, 1100);
    }, 1000);
  };

  const viewBankAccount = () => {
    setShowBankAccount(true);
    setPassword(false);
  };
  const viewPassword = () => {
    setShowBankAccount(false);
    setPassword(true);
  };
  const closeView = () => {
    setShowBankAccount(false);
    setPassword(false);
  };
  useEffect(() => {
    const url = `${BASE_URL}/users/${myUser.id}/valuations`;
    AxiosAuth.get(url)
      .then((response) => {
        // host_to_guest
        const valuationCleaningGuest = response.data.host_to_guest.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "CLEANING")
            .map((e) => e.rate)
        );
        const valuationComunicationGuest = response.data.host_to_guest.map(
          (e) =>
            e.valuations
              .filter((e) => e.topic.code === "COMMUNICATION")
              .map((e) => e.rate)
        );
        const valuationConductGuest = response.data.host_to_guest.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "CONDUCT")
            .map((e) => e.rate)
        );
        const valuationCleaning = response.data.guest_to_host.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "CLEANING")
            .map((e) => e.rate)
        );
        const valuationTrust = response.data.guest_to_host.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "TRUST")
            .map((e) => e.rate)
        );
        const valuationComunication = response.data.guest_to_host.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "COMMUNICATION")
            .map((e) => e.rate)
        );
        const valuationLocation = response.data.guest_to_host.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "LOCATION")
            .map((e) => e.rate)
        );
        const PriceQuality = response.data.guest_to_host.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "PRICEQUALITY")
            .map((e) => e.rate)
        );
        const checkin = response.data.guest_to_host.map((e) =>
          e.valuations
            .filter((e) => e.topic.code === "CHECKIN")
            .map((e) => e.rate)
        );

        const sumValuationsGuest =
          caclValuations(valuationConductGuest) +
          caclValuations(valuationComunicationGuest) +
          caclValuations(valuationCleaningGuest);

        const sumValuations =
          caclValuations(valuationCleaning) +
          caclValuations(valuationTrust) +
          caclValuations(valuationComunication) +
          caclValuations(valuationLocation) +
          caclValuations(PriceQuality) +
          caclValuations(checkin);

        setValuations(sumValuationsGuest / 3 + sumValuations / 6);
        setValuationsLength(
          valuationConductGuest.length + valuationCleaning.length
        );
      })

      .catch((error) => {
        console.log(error);
      });
  }, [myUser.id, valuations, valuationsLength]);
  return (
    <>
      <div className="pt-24 mt-24 ms:m-0 ms:pt-auto user">
        <div className="user__imgContainer ">
          <div className="user__imgContainer__profile">
            <img
              className="user__imgContainer__profile__avatar"
              alt="avatar"
              src={avatarUrl}
            />
            <input
              className="user__imgContainer__profile__input"
              ref={input}
              accept=".png,.jpg,.jpeg"
              type="file"
              id="user_avatar"
              name="user_avatar"
              onChange={handleChange}
            />
            <div className="user__imgContainer__profile__change">
              <svg
                className="user__imgContainer__profile__change__icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                filter=" drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4))"
              >
                <path
                  d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                  stroke="#FF4E00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                  stroke="#FF4E00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <label
                className="user__imgContainer__profile__change__label"
                htmlFor="user_avatar"
              >
{handleFilterLang(jsonProfileCard).cambiarFoto}

                
              </label>
            </div>
          </div>
          <div className="user__imgContainer__evaluation">
            <p className="user__imgContainer__evaluation__text">
              <StarIcon size={25} />
              {valuationsLength
                ? `  ${parseFloat(valuations).toFixed(
                    1
                  )} (${valuationsLength} ${handleFilterLang(jsonProfileCard).evaluaciones}
                  )`
                : `0 ${handleFilterLang(jsonProfileCard).evaluaciones}`}
            </p>
            <img
              className="user__imgContainer__evaluation__img"
              alt="icon evlauacion"
              src={award}
            />
          </div>

          <ButtonPrimary
            width={"100%"}
            secondaryColor={true}
            title={handleFilterLang(jsonProfileCard).cambiarContraseña}
            onClick={() => viewPassword()}
          />
          <ButtonPrimary
            width={"100%"}
            secondaryColor={true}
            title={handleFilterLang(jsonProfileCard).cambiarCBU}
            onClick={() => viewBankAccount()}
          />
          {/* <button
            className="user__imgContainer__password"
            onClick={() => scrollDown()}
          >
            <FontAwesomeIcon icon={faKey} />
            Cambiar contraseña
          </button> */}
        </div>
        <div className="user__iconsContainer">
          <div className="user__iconsContainer__title">
            {
          handleFilterLang(jsonProfileCard).verificaID}
</div>
          <Link to="/profile/dni" className="user__iconsContainer__icons">
            <svg
              className="user__iconsContainer__icons__user"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="2"
                fill="none"
                stroke="currentColor"
              >
                <path
                  stroke="#fff"
                  d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                ></path>
                <circle stroke="#fff" cx="12" cy="7" r="4"></circle>
              </g>
            </svg>
            <img className="user__iconsContainer__icons__check" src={check} />
          </Link>
        </div>
        <div
          className={password ? "user__componentsBig" : "user__componentsSmall"}
        >
          {userCard ? (
            <>
              <MainUserProfile
                password={password}
                showBankAccount={showBankAccount}
                closeView={closeView}
              />
              {password || showBankAccount ? (
                ""
              ) : (
                <button
                  className={userCard ? "user__button" : "user__buttonBack"}
                  onClick={() => setUserCard(!userCard)}
                >
                  {userCard ? handleFilterLang(jsonProfileCard).editar : handleFilterLang(jsonProfileCard).volver}
                </button>
              )}
            </>
          ) : (
            <>
              <UserEditComponent
                password={password}
                showBankAccount={showBankAccount}
                closeView={closeView}
              />
              {password || showBankAccount ? (
                ""
              ) : (
                <button
                  className={userCard ? "user__button" : "user__buttonBack"}
                  onClick={() => setUserCard(!userCard)}
                >
                  {userCard ? handleFilterLang(jsonProfileCard).editar : handleFilterLang(jsonProfileCard).volver}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.userReducer;
};

export default connect(mapStateToProps, { setUserAvatar })(ProfileCard);
