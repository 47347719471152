import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useState } from "react";
import { useEffect } from "react";
import Markers from "./Markers";

const LeafletMap = ({ properties }) => {
  const [latSum, setLatSum] = useState();
  const [lngSum, setLngSum] = useState();
  const latitude = properties.map((e) => e.lat);
  const longitud = properties.map((e) => e.lng);
  useEffect(() => {
    if (properties.length > 0) {
      const latitudes = properties.map((e) => parseFloat(e.lat));
      const longitudes = properties.map((e) => parseFloat(e.lng));
      const minLatitude = Math.min(...latitudes);
      const maxLongitude = Math.min(...longitudes);
      setLatSum(minLatitude);
      setLngSum(maxLongitude);
    }
  }, [latitude, longitud, latSum, lngSum, properties.length]);

  // Función para determinar el nivel de zoom basado en la distancia
  const getZoomLevel = (latSum, lngSum) => {
    const distance = Math.round(latSum + lngSum);
    if (distance < -120) return 4;
    if (distance < -100) return 5;
    if (distance < -90) return 10;
    return 6;
  };

  return (
    <>
      {latSum === undefined ? (
        ""
      ) : (
        <MapContainer
          center={[latSum, lngSum]}
          zoom={getZoomLevel(latSum, lngSum)}
          scrollWheelZoom={true}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Markers geoLat={properties} />
        </MapContainer>
      )}
    </>
  );
};

export default LeafletMap;
