// Librerías
import React from 'react';
// Recursos
import CK from "../../../assets/static/check_portada.svg";
import '../../../assets/styles/AloharForm/Utils/_firstPhotoRadio.sass';

const FirstPhotoRadio = ({ id, name, title, value, checked, customClickEvent}) => {
  
  return(
    <React.Fragment>
        <div className="FirstPhotoRadio">
            <input type="radio" className="FirstPhotoRadio__radio" checked={checked} value={value} id={ id } name={ name } onChange={customClickEvent} />
            <div className="FirstPhotoRadio__container">
                <label htmlFor={ id } className="FirstPhotoRadio__container__label">
                    <span className="FirstPhotoRadio__container__label__check">
                    </span>
                    <p className="FirstPhotoRadio__container__label__text">{title}</p>
                </label>
            </div>
        </div>
    </React.Fragment>
  );
}

export default FirstPhotoRadio;