import React from "react";
import "./modal-function-container.sass";
const ModalGlobal = ({ children, modalBackground, close }) => {
  return (
    <div
      className={close ? "modalGlobal" : "displayNone"}
      style={{ background: modalBackground ? modalBackground : "" }}
    >
      {children}
    </div>
  );
};

export default ModalGlobal;
