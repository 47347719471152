import React from "react";

const TitleAndList = ({ array }) => {
  return (
    <div className="max-w-5xl py-4 m-auto ">
      {array.map((e) => (
        <React.Fragment key={e.title}>
          <h2 className="text-2xl ">{e.title}</h2>
          <ul className="mb-10">
            {e.li.map((e) => (
              <li key={e}>
                <br />
                {e}
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </div>
  );
};

export default TitleAndList;
