import React from "react";
import "../../assets/styles/Utils/bannerTransferMobile.sass";

const BannerTransferMobile = () => {
  return (
    <div className="bannerTransferMobile">

    </div>
  );
};

export default BannerTransferMobile;
