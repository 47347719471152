import React from "react";
const CrossIcon = ({ color , width,className}) => {
  return (
    <svg
    className={className}
      width={width ? width:"15"}
      height={width ? width:"15"}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.27889 12.8859L12.8441 2.2381"
        stroke={color ? color : "#FF7D52"}
        />
      <path
        d="M2.23714 2.27889L12.885 12.8441"
        stroke={color ? color : "#FF7D52"}
      />
    </svg>
  );
};

export default CrossIcon;
