// Librerías
import React from "react";
import { ScreenClassProvider } from "react-grid-system";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";
// Componentes
import Navbar from "../../components/Navbar/Navbar";
import MessengerComponent from "../../components/MessengersPage";
import { DataProvider } from "../../store/data-context";

ReactGA.initialize("G-WM6E6JJLL6");

const MessengersPage = () => {
  return (
    <DataProvider>
      <ScreenClassProvider>
        {/* { isMobile ? ''
        : <Navbar />
        } */}
        <MessengerComponent />
      </ScreenClassProvider>
    </DataProvider>
  );
};

export default MessengersPage;
