// Librerías
import React from 'react';
// Recursos
import '../../../assets/styles/SearchPage/Utils/_filtercheckbox.sass'

const FilterCheckBox = ({id, name, title, last, value, checked, customClickEvent}) => {
    return(
        <div className={`${(last) ? 'FilterCheckboxLast' : 'FilterCheckbox'}`}>
            <input id={ id } value={ value } onChange={customClickEvent} checked={checked}  className={`${(last) ? 'FilterCheckboxLast' : 'FilterCheckbox'}__checkbox`} type="checkbox" name={ name } />
            <label htmlFor={ id } className={`${(last) ? 'FilterCheckboxLast' : 'FilterCheckbox'}__title`}>
                { title }
            </label>
        </div>
    )
}

export default FilterCheckBox;