import React from "react";
import CardDetail from "../card-detail";
import CardDetailUserValuations from "../card-detail-user-valuations";

const SectionDetail = ({ userHousing, userInfo }) => {
  return (
    <div className="layoutMessages__container__section__cardDetail">
      <CardDetail housing={userHousing} />
      {/* <CardDetailUserValuations userData={userInfo} /> */}
    </div>
  );
};

export default SectionDetail;
