// Libraries
import React from 'react';
// Assets
import "../../assets/styles/Modals/modalFilter.sass";
import close from '../../assets/static/Icons/close.png'
import useCurrentLanguage from '../../services/currentlenguaje';
import globalJson from "../../utilities/traduccGlobal.json"

const Modal = ({children, isOpen, closeModal}) => {
    const { handleFilterLang } = useCurrentLanguage();
 
    const handleModalContainerClick = e => e.stopPropagation();
    return (
        <>
            <article className={`modalFilter ${isOpen && "isOpen"}`} onClick={closeModal}> 
                <div className="modal-container" onClick={handleModalContainerClick}>
                    <div className='modalHeader'>
                        <span className='modalHeader__title'>
                        {handleFilterLang(globalJson).misFiltros}
                        </span>
                        <button className="modalHeader__btn" onClick={closeModal}>
                            <img alt="Close" className='modalHeader__btn__img' src={close} />
                        </button>
                    </div>
                        {children}
                </div>
            </article>
        </>
    )
}

export default Modal;
