import React from 'react'
import { Modal } from 'antd';

const cancelButtonStyle = {
    shape: 'round'
}

const okButtonStyle = {
    shape: 'round',
    className: 'bg-primary'
}

const ModalAnt = (props) => {
    const {title, header, message} = {...props};
    const setVisible = props.setVisible;
    const visible = props.visible;
    return (
    <Modal
        title={ title }
        visible={ visible }
        onCancel={ () => {
            setVisible(!visible)
        } }
        onOk={ () => {
            setVisible(!visible)
        } }
        cancelButtonProps={cancelButtonStyle}
        okButtonProps={okButtonStyle}
        cancelText={"Cancelar"}
        okText={"Ok"}
    >
        <h2>
            { header }
        </h2>
        <p>
            { message }
        </p>
    </Modal>
    )
}

export default ModalAnt