// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
// Componentes
import Buttons from '../Utils/Buttons';
import ErrorMessage from '../../Alerts/ErrorAlert';
import Checkbox from '../Utils/Checkbox';
import ProgressBar from '../Utils/ProgressBar';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_covid.sass';
import ButtonEditAApp from '../Utils/ButtonEditAApp';

const typeHousingSanitary = [
  {
    value:"thermometer",
    title:"Termómetro"
  },
  {
    value:"alcohol",
    title:"Alcohol en Gel"
  },
  {
    value:"soap",
    title:"Jabon antibacterial"
  },
  {
    value:"deep_cleaning",
    title:"Unidad entregada con limpieza profunda"
  },
  {
    value:"mosquito_repellent",
    title:"Repelentes de mosquitos"
  },
  {
    value:"diffuser_antimosquito",
    title:"Difusor y pastillas anti mosquitos"
  },
]

const typeHousingSecurity = [
  {
    value:"smoke_detector",
    title:"Detector de humo"
  },
  {
    value:"co_detector",
    title:"Detector de mónoxido de carbono"
  },
  {
    value:"first_aid_kit",
    title:"Botiquín de primeros auxilios"
  },
  {
    value:"fire_extinguisher",
    title:"Extintor de fuego"
  },
  {
    value:"lock_bedroom",
    title:"Cerradura con llave en la puerta del dormitorio"
  }
]

class Covid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeHousingSanitary: this.props.bodyParameters.typeHousingSanitary || [],
      typeHousingSecurity: this.props.bodyParameters.typeHousingSecurity || [],
      errors : {},
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate
    };

    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.handleValidate({showError:false});
    this.props.covidStep({
      typeHousingSanitary: this.state.typeHousingSanitary,
      typeHousingSecurity: this.state.typeHousingSecurity
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.count_validate !== prevProps.count_validate && !isNaN(this.props.count_validate)){
      this.handleValidate({showError:true});
    }
  }

  handleValidate = ({inputName,showError}) =>{
    let errors = {};
    const messengers = {
      typeHousingSanitary: "Debe ofrecer al menos una opción de higiene",
      typeHousingSecurity: "Debe ofrecer al menos una opción de seguridad",
    };
    const validate = this.state.typeHousingSanitary.length > 0 

    if(inputName){
      if(inputName === "typeHousingSanitary" && this.state.typeHousingSanitary.length === 0)
          errors['typeHousingSanitary'] = messengers["typeHousingSanitary"]
      // if(inputName === "typeHousingSecurity" && this.state.typeHousingSecurity.length === 0)
      //   errors['typeHousingSecurity'] = messengers["typeHousingSecurity"]
    }else{
      if(this.state.typeHousingSanitary.length === 0)
        errors['typeHousingSanitary'] = messengers["typeHousingSanitary"]
      // if(this.state.typeHousingSecurity.length === 0)
      //   errors['typeHousingSecurity'] = messengers["typeHousingSecurity"]
    }

    if(showError)
      this.setState({errors:errors})
    this.props.validateStep(validate);
  }

  handleChange = (event) => {

    let name = event.target.name;
    let value = event.target.value;
    let checked = event.target.checked;
    const options = this.state[name]
    let index
    if(checked){
      options.push(value)
    }else{
      index = options.indexOf(value)
      options.splice(index, 1)
    }

    if(name === "typeHousingSanitary"){
      this.setState({ typeHousingSanitary: options },()=>{
        this.props.covidStep(this.state)
        this.handleValidate({inputName:name, showError:true});
      });
    }

    if(name === "typeHousingSecurity"){
      this.setState({ typeHousingSecurity: options },()=>{
        this.props.covidStep(this.state)
        this.handleValidate({inputName:name, showError:true});
      });
    }
}

  render(){
    return(
      <React.Fragment>
        <div className='CovidResponsive'>
          <div className="Covid">
            {this.state.modeEdit ? '' : <ProgressBar title={"HIGIENE Y SEGURIDAD"} stepAt={'Paso 1: Lo esencial'} />}
            <div className="Covid__container">
              <div className="Covid__container-left">
                <h2 className="Covid__container__title">
                  Cuidados Sanitarios e Higiene
                </h2>
                <p className="Covid__container__description">
                  Los huéspedes priorizan en este contexto el cuidado de su salud
                </p>
               
                {typeHousingSanitary.map((service,index) => (
                  <Checkbox
                    checked={this.state.typeHousingSanitary.indexOf(service.value) !== -1}
                    id={"typeHousingSanitary_"+index}
                    key={"typeHousingSanitary_"+index}
                    value={service.value}
                    name="typeHousingSanitary"
                    title={service.title}
                    customClickEvent={this.handleChange.bind(this)} />
                  ),this)}
                   {
                  this.state.errors["typeHousingSanitary"]
                  ? <ErrorMessage message={this.state.errors["typeHousingSanitary"]} />
                  : ''
                }
              </div>

              <div className="Covid__container-left">
                <h2 className="Covid__container__title">
                  Seguridad
                </h2>
                <p className="Covid__container__descriptionSecondary">
                Completa con un check en caso de que tu espacio disponga de alguna de las medidas de seguridad, <br/> no es obligatorio completar una opción
                </p>
                
                {typeHousingSecurity.map((service,index) => (
                  <Checkbox
                    checked={this.state.typeHousingSecurity.indexOf(service.value) !== -1}
                    id={"typeHousingSecurity_"+index}
                    key={"typeHousingSecurity_"+index}
                    value={service.value}
                    name="typeHousingSecurity"
                    title={service.title}
                    customClickEvent={this.handleChange.bind(this)} />
                  ),this)}
                 
              </div>
              {
                  this.state.errors["typeHousingSecurity"]
                  ? <ErrorMessage message={this.state.errors["typeHousingSecurity"]} />
                  : ''
                }
            </div>
            { this.state.modeEdit ? <ButtonEditAApp /> : <Buttons /> }
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer
};

export default connect(mapStateToProps ,aloharFormActions)(Covid);