// Librerías
import React from 'react';
// Componentes
import DashboardComponent from '../../components/BackOffice/Dashboard/DashboardComponent';

const Dashboard = () => {
  return(
    <DashboardComponent />
  );
}

export default Dashboard;