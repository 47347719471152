// Librerías
import React, { useEffect, useState } from "react";
// Componentes
import AxiosAuth from "../../../services/requestApi";
import Services from "./PropertyServices";
import ModalWrapper from "../../Modals/ModalWrapper";
import ShareModal from "../../Modals/ShareModal";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import { openShareModal } from "../../../store/modal/actions";
// Recursos
import "../../../assets/styles/PropertyPage/Utils/_info.sass";
import star from "../../../assets/static/star.svg";
import house from "../../../assets/static/house.svg";
import cuttlery from "../../../assets/static/cuttlery.svg";
import marker from "../../../assets/static/marker.svg";
import { BASE_URL } from "../../../Helpers";
import axios from "axios";
import Separator from "../../Utils/Separator";
import Heart from "./Heart";
import ButtonPrimary from "../../ui/button-primary";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import propertyPageJson from "../tradPropertyPage.json";
import globajJson from "../../../utilities/traduccGlobal.json";
import useCurrentLanguage from "../../../services/currentlenguaje";
import {useLanguage} from "../../../../src/store/lenguaje/useLenguaje";




import medallaDorada from "../../../assets/static/housing-promotions/medalla-dorada.png";
import medallaPlata from "../../../assets/static/housing-promotions/medalla-plata.png";
import moment from "moment"
const icon_1_group = [
  "wifi",
  "basic",
  "tv",
  "air_conditioning",
  "pool",
  "garage",
  "quincho",
];

const icon_2_group = ["pool", "cochera", "quincho", "garage", "wifi", "tv"];

// const services = [
//   "WIFI",
//   "Ascensor",
//   "Aire Acondicionado",
//   "Zona para trabajar con laptops",
//   "Televisor",
//   "Cocina",
//   "Elementos de desinfección",
//   "Tapabocas descartables",
//   "Jabón Blanco"
// ];

const type_roomEs = {
  kitchen: "Cocina",
  laundry: "Lavadero",
  cochera: "Cochera",
  garage: "Cochera",
  gym: "Gimnasio",
  pool: "Pileta",
  jacuzzi: "Jacuzzi",
  dryer: "Secadora",
  quincho: "Quincho",
};

const type_roomEn = {
  kitchen: "Kitchen",
  laundry: "Laundry room",
  cochera: "Garage",
  garage: "Garage",
  gym: "Gym",
  pool: "Pool",
  jacuzzi: "Jacuzzi",
  dryer: "Dryer",
  quincho: "Barbecue area"
};

const type_roomPo = {
  kitchen: "Cozinha",
  laundry: "Área de serviço",
  cochera: "Garagem",
  garage: "Garagem",
  gym: "Academia",
  pool: "Piscina",
  jacuzzi: "Hidromassagem",
  dryer: "Secadora",
  quincho: "Área de churrasco"
};


const type_serviceEs = {
  basic: "Elementos básicos (Toallas, sábanas, jabón, papel higiénico, etc.)",
  wifi: "Wifi",
  tv: "TV",
  heating: "Calefacción",
  air_conditioning: "Aire acondicionado",
  griddle: "Plancha",
  hair_dryer: "Secador de pelo",
  electric_oven: "Horno eléctrico",
  washing_machine: "Lavarropas",
};

const type_serviceEn = {
  basic: "Basic amenities (Towels, sheets, soap, toilet paper, etc.)",
  wifi: "Wifi ",
  tv: "TV",
  heating: "Heating",
  air_conditioning: "Air conditioning",
  griddle: "Griddle",
  hair_dryer: "Hair dryer",
  electric_oven: "Electric oven",
  washing_machine: "Washing machine",
};

const type_servicePo = {
  basic: "Itens básicos (Toalhas, lençóis, sabonete, papel higiênico, etc.)",
  wifi: "Wifi",
  tv: "TV",
  heating: "Aquecimento",
  air_conditioning: "Ar condicionado",
  griddle: "Chapa",
  hair_dryer: "Secador de cabelo",
  electric_oven: "Forno elétrico",
  washing_machine: "Máquina de lavar roupa",
};

const type_sanitaryEs = {
  deep_cleaning: "Unidad entregada con limpieza profunda",
};

const type_sanitaryEn = {
  deep_cleaning: "Unit delivered with deep cleaning",
};

const type_sanitaryPo = {
  deep_cleaning: "Unidade entregue com limpeza profunda",
};

const Gallery = (housing) => {
  const { handleFilterLang } = useCurrentLanguage();
    const { currentLang } = useLanguage();


    const type_room = currentLang === "es" ? type_roomEs 
    : currentLang === "en" ?  type_roomEn 
    : currentLang === "po" ?  type_roomPo 
    : type_roomEs

    const type_service = currentLang === "es" ? type_serviceEs 
    : currentLang === "en" ?  type_serviceEn 
    : currentLang === "po" ?  type_servicePo 
    : type_serviceEs

    const type_sanitary = currentLang === "es" ? type_sanitaryEs 
    : currentLang === "en" ?  type_sanitaryEn 
    : currentLang === "po" ?  type_sanitaryPo 
    : type_sanitaryEs



  let {
    title,
    guests,
    rooms,
    double_beds,
    simple_beds,
    bathrooms,
    description,
    TAD,
    housingService,
    housingRoom,
    housingSanitary,
    user,
    id,
    location,
    extraHousingService,
    extraHousingRoom,
  } = housing.housing;
  const userLevel = housing.housing?.housingPromotions[0]?.priorityLevel?.name;
  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const [average, setAverage] = useState([]);
  const [valuations, setValuations] = useState([]);
  const [liked, setLiked] = useState(false);
  const userAvatarDefault = `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${user.firstName}`;
  const userLogin = localStorage.getItem("userData");
  const dispatch = useDispatch();
  
  const dateToday = moment();
  const validFromHeighlight =  housing.housing?.housingPromotions[0] && moment( housing.housing?.housingPromotions[0].valid_from_heighlight);
  const validToHeighlight =  housing.housing?.housingPromotions[0] && moment( housing.housing?.housingPromotions[0].valid_to_heighlight);
  const valideighlight = dateToday.isBetween(
    validFromHeighlight,
    validToHeighlight
  );
  if (housingService === undefined) housingService = [];
  if (housingSanitary === undefined) housingSanitary = [];
  if (user === undefined) {
    user = {};
    user.avatar = "";
    user.firstName = "";
  } else {
    user.avatar =
      user.avatar && user.avatar.includes("http")
        ? user.avatar
        : user.avatar === null
          ? userAvatarDefault
          : `${BASE_URL}/user/avatar/${user.avatar}`;
  }

  const getUserFavorite = () => {
    const url = `${BASE_URL}/favorites/housing/${id}`;
    AxiosAuth.get(url)
      .then((response) => {
        let data = response.data;
        setLiked(data.liked);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAverage = () => {
    const urlAverage = `${BASE_URL}/housing/${id}/valuations/average`;
    axios
      .get(urlAverage)
      .then((response) => {
        if (response.data.length > 0) setAverage(response.data);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const getValuations = () => {
    const urlValuations = `${BASE_URL}/housing/${id}/valuations`;
    axios
      .get(urlValuations)
      .then((response) => {
        if (response.data.length > 0) setValuations(response.data);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const selectModal = (modalType) => {
    // if(!userState.login){
    //   return <ModalWrapper key="login_modal"><LoginModal/></ModalWrapper>
    // }
    switch (modalType) {
      case "SHARE":
        return (
          <ModalWrapper key="contacto_modal">
            <ShareModal title={title} />
          </ModalWrapper>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    getAverage();
    getValuations();
    if (userLogin) {
      getUserFavorite();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const averageValue = (obj) => {
    let count = obj.length;
    let sum = obj.reduce((a, c) => a + parseInt(c.avg), 0);
    let res = sum / count;
    if (res % 1 === 0) {
      return res;
    } else {
      return parseFloat(res).toFixed(2);
    }
  };




  
  const group1 = () => {
    const g1 = housingService.concat(housingRoom);
    const _group1 = g1.filter((g) => icon_1_group.includes(g));
    const service = type_service.hasOwnProperty(_group1[0])
      ? type_service[_group1[0]]
      : type_room[_group1[0]];

    return (
      <div className="Info__important__info">
        <div className="Info__important__info__icon">
          <img src={house} height="40" alt="Casa" />
        </div>
        <div className="Info__impotant__info__text">
          <p className="Info__important__info__text__title">
            {handleFilterLang(propertyPageJson).con}

            {service}</p>
          <p className="Info__important__info__text__description">
            {service}
            {handleFilterLang(propertyPageJson).enElAlohamiento}

          </p>
        </div>
      </div>
    );
  };

  const group2 = () => {
    const g1 = housingService.concat(housingRoom);
    const _group1 = g1.filter((g) => icon_1_group.includes(g));
    const service_1 = type_service.hasOwnProperty(_group1[0])
      ? type_service[_group1[0]]
      : type_room[_group1[0]];

    const g2 = housingService.concat(housingRoom);
    const _group2 = g2.filter((g) => icon_2_group.includes(g));
    let service_2 = type_service.hasOwnProperty(_group2[0])
      ? type_service[_group2[0]]
      : type_room[_group2[0]];

    if (service_2 === service_1) {
      service_2 = type_service.hasOwnProperty(_group2[1])
        ? type_service[_group2[1]]
        : type_room[_group2[1]];
    }

    return (
      <div className="Info__important__info">
        <div className="Info__important__info__icon">
          <img src={marker} height="40" alt="Ubicación" />
        </div>
        <div className="Info__impotant__info__text">
          <p className="Info__important__info__text__title">
            {handleFilterLang(propertyPageJson).con}
            {service_2}</p>
          <p className="Info__important__info__text__description">
            {handleFilterLang(propertyPageJson).alohamientoCon}

            {service_2}
          </p>
        </div>
      </div>
    );
  };

  const selectHousingElements = (housingService, housingRoom) => {
    const wifi = housingService.indexOf("wifi");
    housingService.slice(wifi, 1);

    return {
      first: type_service[housingService[0]],
      second: type_room[housingRoom[0]],
      third: type_service[housingService[1]],
    };
  };

  const HS =
    housingService !== undefined && housingRoom !== undefined
      ? selectHousingElements(housingService, housingRoom)
      : { first: "", second: "", third: "" };

  return (
    <React.Fragment>
      <div className="Info">
        <div className="Info__headline">
          <div className="Info__headline__data">
            <div className="Info__headline__data__location">
              {location.location}, {location.province.province},
              {location.country.country}
            </div>
            <div className="Info__headline__data__title">
              <h2 className="Info__headline__data__title__text">{title}</h2>
              <div className="Info__headline__data__title__content">
                {modalState.isOpen ? (
                  selectModal(modalState.type)
                ) : (
                  <button
                    className="Info__btnShare"
                    onClick={() => {
                      dispatch(openShareModal());
                    }}
                  >
                    {handleFilterLang(propertyPageJson).compartir}


                  </button>
                )}
                {userLogin ? <Heart housingid={id} liked={liked} /> : ""}
              </div>
              <div className="my-4 hostingMobile">
                <p className="flex items-center justify-between w-full gap-2 Info__headline__avatar__container__name ">
                  <a className="flex items-center gap-1 " href={`/users/show/${user.id}`}>
                    {valideighlight && userLevel === "gold" && (
                      <img
                        className="w-4 "
                        src={medallaDorada}
                        alt="Medalla dorada"
                      />
                    )}
                    {valideighlight && userLevel === "platinum" && (
                      <img
                        className="w-4 "
                        src={medallaPlata}
                        alt="Medalla plateada"
                      />
                    )}
                    {user.firstName} {user.lastName}
                  </a>
                  <ButtonPrimary
                    width={200}
                    secondaryColor={true}
                    onClick={() =>
                      window.open(`/users/show/${user.id}`, "_blank")
                    }
                    title={handleFilterLang(propertyPageJson).verMasAlohamientos}

                  />
                </p>
              </div>
            </div>

            <div className="Info__headline__data__quantities">
              {guests} {guests === 1 ? handleFilterLang(globajJson).huesped : handleFilterLang(globajJson).huespedes} • {rooms}{" "}
              {rooms === 1 ? handleFilterLang(propertyPageJson).dormitorio : handleFilterLang(propertyPageJson).dormitorios} •{" "}
              {double_beds + simple_beds}{" "}
              {double_beds + simple_beds === 1 ? handleFilterLang(globajJson).cama : handleFilterLang(globajJson).camas} • {bathrooms}{" "}
              {bathrooms === 1 ? handleFilterLang(globajJson).baño : handleFilterLang(globajJson).baños}
            </div>
            <div className="Info__headline__data__mainServices">
              {HS.first ? HS.first : ""} {HS.second ? "• " + HS.second : ""}{" "}
              {HS.third ? "• " + HS.third : ""}
            </div>
            <div className="Info__headline__data__reviews">
              {average && average.length !== 0 ? (
                <>
                  <img src={star} alt="" /> {averageValue(average)} (
                  {valuations?.length}{" "}
                  {valuations?.length === 1 ? handleFilterLang(propertyPageJson).evaluacion : handleFilterLang(propertyPageJson).evaluaciones})
                </>
              ) : (
                handleFilterLang(propertyPageJson).sinEvaluaciones
              )}
            </div>
          </div>
          <div className=" Info__headline__avatar">
            <div className=" Info__headline__avatar__container">
              <div className="Info__headline__avatar__container__img">
                <a href={`/users/show/${user.id}`}>
                  <img
                    src={user.avatar}
                    alt={user.firstName}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = userAvatarDefault;
                    }}
                  />
                </a>
              </div>
              <p className="flex flex-col items-center gap-2 Info__headline__avatar__container__name">
                <a
                  className="flex items-center gap-1"
                  href={`/users/show/${user.id}`}
                >
                  {valideighlight && userLevel === "gold" && (
                    <img
                      className="w-4 "
                      src={medallaDorada}
                      alt="Medalla dorada"
                    />
                  )}
                  {valideighlight &&userLevel === "platinum" && (
                    <img
                      className="w-4 "
                      src={medallaPlata}
                      alt="Medalla dorada"
                    />
                  )}
                  {user.firstName} {user.lastName}
                </a>
                <ButtonPrimary
                  width={200}
                  secondaryColor={true}
                  onClick={() =>
                    window.open(`/users/show/${user.id}`, "_blank")
                  }
                  title={handleFilterLang(propertyPageJson).verMasAlohamientos}
                />
              </p>
            </div>
            <br></br>
          </div>
        </div>
        {/* Separador */}
        <Separator />
        {/* INFORMACIÓN IMPORTANTE */}
        <div className="Info__important">
          {group1()}

          {group2()}

          <div className="Info__important__info">
            <div className="Info__important__info__icon">
              <img src={cuttlery} height="40" alt="Impecable" />
            </div>
            <div className="Info__impotant__info__text">
              <p className="Info__important__info__text__title">
                {housingSanitary.indexOf("deep_cleaning") !== 1
                  ? type_sanitary["deep_cleaning"]
                  : handleFilterLang(propertyPageJson).sinLimpieza}
              </p>
              <p className="Info__important__info__text__description">
                {/* Descripcion de limpiza profunda */}
              </p>
            </div>
          </div>
        </div>{" "}
        {/* TERMINA --------------------------------------- */}
        {/* Separador */}
        <Separator />
        {TAD && (
          <div className="Info__description">
            <p className="Info__description__title"> {handleFilterLang(propertyPageJson).habilitacionCABA}</p>
            <p className="Info__description__text">{TAD}</p>
          </div>
        )}
        <br />
        <div className="Info__description">
          <p className="Info__description__title">
            {handleFilterLang(propertyPageJson).descripcionAlohamiento}

          </p>
          <p className="Info__description__text">{description}</p>
        </div>
        <Services
          extraHousingRoom={extraHousingRoom}
          extraHousingService={extraHousingService}
          services={housingService}
          type_service={type_service}
        />
      </div>
    </React.Fragment>
  );
};
export default connect(null, { openShareModal })(Gallery);
