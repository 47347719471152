// Librerías
import React from 'react';
// Componentes
import UserComponents from '../../../components/BackOffice/Users/User';

const User = () => {
  return(
    <UserComponents />
  );
}

export default User;