// Librerías
import React from 'react';
// Components
import ConfirmedComponentAlohador from '../components/Confirmed/ConfirmedComponentAlohador';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

const ConfirmedContainerAlohador = () => {
  return(
    <React.Fragment>
      <Navbar />
      <ConfirmedComponentAlohador />
      <Footer />
    </React.Fragment>
  )
}

export default ConfirmedContainerAlohador;