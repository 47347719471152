// Librerías
import React from 'react';
// Componentes
import Transfer from '../../../components/BackOffice/Housing/Transfer';

const HousingTransfer = () => {
  return(
    <Transfer />
  );
}

export default HousingTransfer;