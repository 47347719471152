import React from "react";
import "./modal-error.sass"
const ReservationsErrorModal = () => {
  return (
    <h2 className="reservationsErrorModal">
      No es posible cancelar la reserva. Por favor, comuníquese con atención al
      cliente para poder realizar la cancelación.
    </h2>
  );
};

export default ReservationsErrorModal;
