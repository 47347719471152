// Librerías
import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AxiosAuth from '../../../services/requestApi';
import { BASE_URL } from '../../../Helpers';
// Componentes
import MainContainer from '../Utils/MainContainer';

const Update = ( props ) => {
    const { id } = useParams();

    const [userId, setUserId] = useState(0);

    const history = useHistory();

    const getHousing = (id) => {
      const url = `${BASE_URL}/backoffice/housing/${id}`;
      AxiosAuth.get(url).then((response)=>{
        let housing = response.data;
        setUserId(housing.user.id);
      }).catch((e)=>{console.log(e)});
    }


    useEffect(() => {
      getHousing(id);
    },[]);

    const handleChange = (event)  => {
      const name = event.target.name;
      const value = event.target.value;

      if(name === "userid") setUserId(value)
    }

    const sendData = () =>{
      const url = `/backoffice/housing/transfer/${id}`;
      const data = {
        userId
      }

      AxiosAuth.patch(url,data)
        .then((response) => {
          history.push(`../${id}`);
        }).catch((error) => {
          console.log(error)
        });
    }

    const handleSubmit = () =>{
      sendData();
    }

    return (
        <MainContainer title="Transferir propiedad">
          <>
            <div className="MainContainer__form">
              <div className="MainContainer__form__group">
                <label htmlFor="userid" className="MainContainer__form__label">User ID</label>
                <input
                  type="text"
                  name="userid"
                  id="userid"
                  className="MainContainer__form__input"
                  placeholder="ID del Usuario propietario"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={userId}
                  required
                />
              </div>
              <div className="UserEditComponent__btns">
                <button className="MainContainer__form__btn__outline" onClick={ () => history.push(`../${id}`) }>
                  <p className="MainContainer__form__btn__outline__text">Volver</p>
                </button>
                <button className="MainContainer__form__btn" onClick={ handleSubmit }>Guardar</button>
              </div>
            </div>
          </>
        </MainContainer>
    )
}

export default Update;
