// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
// Componentes
import Buttons from '../Utils/Buttons';
import ErrorMessage from '../../Alerts/ErrorAlert';
import Radio from '../Utils/Radio';
import ProgressBar from '../Utils/ProgressBar';
import ButtonEdit from '../Utils/ButtonEdit';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_type.sass';

// Placeholder Data
const available_space = {
  option_1: {
    id: "available_space_1",
    value: 'entire',
    name: "typeHousingSpace",
    title: "Alohamiento Entero",
    description: ["Los huéspedes tienen toda la propiedad para ellos. Normalmente incluye habitación, baño y cocina."],
  },
  option_2: {
    id: "available_space_2",
    value: 'private',
    name: "typeHousingSpace",
    title: "Habitación Privada",
    description: ["Los huéspedes tienen una habitación privada para dormir. Otras áreas se comparten."],
  },
}
const typeHousingProperty = {
  apartment: 'Departamento',
  house:'Casa',
  cabin:'Cabaña',
  country_house:'Casa Quinta',
  anex_house:'Vivienda Anexa',
  apart_hotel:'Apart Hotel',
  boutique_hotel:'Hotel Boutique',
  glamping:'Glamping'
}

/**
 * Formulario:
 * Tipo de Propiedad: property_type
 * Espacio disponible: available_space
 * Espacio exclusivo: exclusive
 */

class Type extends Component {

  constructor(props) {
    super(props);

    this.state = {
      typeHousingProperty: this.props.bodyParameters.typeHousingProperty || '',
      typeHousingSpace: this.props.bodyParameters.typeHousingSpace || '',
      errors : {},
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate
    };

  }

  componentDidMount() {
    this.handleValidate(false)
    this.props.typeStep(this.state)
  }


  handleValidate = ({inputName,showError}) =>{
    const messengers = {
      typeHousingProperty: "Debe elegir un tipo de propiedad",
      typeHousingSpace: "Debe elegir un tipo de espacio de alojamiento"
    };

    let errors = {};
    const validate = (
      this.state.typeHousingProperty !== ''
      && this.state.typeHousingSpace !== ''
    )

    if (inputName) {
      if(inputName === 'typeHousingProperty' && this.state.typeHousingProperty === '')
        errors['typeHousingProperty'] = messengers['typeHousingProperty']
      if(inputName === 'typeHousingSpace' && this.state.typeHousingSpace === '')
        errors['typeHousingSpace'] = messengers['typeHousingSpace']
    } else {
      if(this.state.typeHousingProperty === '')
        errors['typeHousingProperty'] = messengers['typeHousingProperty']
      if(this.state.typeHousingSpace === '')
        errors['typeHousingSpace'] = messengers['typeHousingSpace']
    }
    if(showError)
      this.setState({errors:errors})

    this.props.validateStep(validate);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.count_validate !== prevProps.count_validate){
      this.handleValidate({showError:true});
    }
  }

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val}, ()=>{
      this.props.typeStep(this.state)
      this.handleValidate({inputName:nam, showError:true});
    });
  }

  render(){
    return(
      <React.Fragment>
        <div className='TypeResponsive'>
          <div className="Type">
            { this.state.modeEdit ? '' : <ProgressBar title={"TIPO DE ALOHAMIENTO"}  stepAt={'Paso 1: Lo esencial'}  /> }
            <div className="Type__container">
              <h2 className="Type__title">
                ¿Qué tipo de Alohamiento quieres anunciar?
              </h2>
              <div className="Type__inputContainer">
                <label htmlFor="property_type" className="Type__inputContainer__label">Tipo de Propiedad</label>
                <select
                  className="Type__inputContainer__select"
                  name="typeHousingProperty"
                  id="property_type"
                  value={this.state.typeHousingProperty}
                  onChange={this.handleChange}
                >
                  <option value="">Seleccione un tipo de propiedad</option>
                  {
                    Object.keys(typeHousingProperty).map(key=><option value={key}>{typeHousingProperty[key]}</option>)
                  }
                </select>
                {
                  this.state.errors["typeHousingProperty"]
                  ? <ErrorMessage message={this.state.errors["typeHousingProperty"]} />
                  : ''
                }
              </div>
              <h2 className="Type__subtitle">
                ¿Cuál es el espacio disponible?
              </h2>
              <Radio
                id={available_space.option_1.id}
                value={available_space.option_1.value}
                name={available_space.option_1.name}
                title={available_space.option_1.title}
                descriptions={available_space.option_1.description}
                customClickEvent={this.handleChange.bind(this)}
                checked = {this.state.typeHousingSpace === available_space.option_1.value}
              />
              <Radio
                id={available_space.option_2.id}
                value={available_space.option_2.value}
                name={available_space.option_2.name}
                title={available_space.option_2.title}
                descriptions={available_space.option_2.description}
                customClickEvent={this.handleChange.bind(this)}
                checked = {this.state.typeHousingSpace === available_space.option_2.value}
              />
              {
                this.state.errors["typeHousingSpace"]
                ? <ErrorMessage message={this.state.errors["typeHousingSpace"]} />
                : ''
              }
            </div>
            { this.state.modeEdit ? <ButtonEdit /> : <Buttons /> }
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer
};

export default connect(mapStateToProps ,aloharFormActions)(Type);