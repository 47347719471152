// Librerías
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../store/user/actions';
import { Link } from 'react-router-dom';
import { ScreenClassProvider } from 'react-grid-system';
// Componentes
import Navbar from '../../components/Navbar/Navbar';
// Recursos
import '../../assets/styles/Error404/_error.sass';
import error from '../../assets/static/error.svg';
import {BASE_URL} from '../../Helpers';
import axios from "axios";
import queryString from 'query-string'

const EmailConfirm = () => {
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [messengerError, setMessengerError] = useState('');
  const [name, setName] = useState('');
  const dispatch = useDispatch()
  const request = (email, emailConfirmedToken) =>{
    setSendRequest(true)
    axios.post(`${BASE_URL}/auth/confirm_email`, {
      email: email,
      emailConfirmedToken: emailConfirmedToken
    })
    .then(function (response) {
      if(response.status === 201){
        setConfirmed(true)
        setLoading(false)
        const user = response.data.user
        const accessToken = response.data.token
        localStorage.removeItem('housingPublicProcessData')
        
        localStorage.setItem("userData", JSON.stringify({
            accessToken:accessToken,
            login:true
        }));

        dispatch(loginUser(accessToken))
        setName(user.firstName+" "+user.lastName)
      }
    })
    .catch(function (error) {
      console.log(error.response);
      setLoading(false)
      if(error.response.status === 400){
        setConfirmed(false)
        setLoading(false)
        setMessengerError('Token inválido')
      }
    });

  }

  useEffect(()=>{
    const query = queryString.parse(window.location.search);
    const {email, emailConfirmedToken} = query
    if(!sendRequest)
      request(email, emailConfirmedToken)
    
  })
  

  return(
    <ScreenClassProvider>
      <Navbar />
      <div className="Error404">
        <div className="Error404__container">
          <div className="Error404__container__text">
            <h1 className="Error404__container__text__title">
              
              {!loading && confirmed ? '¡Confirmado!' : ''}
              {!loading && !confirmed ? messengerError : ''}
              {loading? 'Confirmando...' : ''}
            </h1>
            
            {!loading && confirmed ? 
              <>
                <p className="Error404__container__text__description">¡Hola {name} !</p>
                <p className="Error404__container__text__description">
                    ¡Qué bueno es compartir momentos, viajes y experiencias! 
                    Es por eso que desde el <stron>equipo Alohar</stron> trabajamos para que en 
                    cada uno de esos momentos no tengas que preocuparte por la seguridad y la calidad de tu hospedaje al <stron>mejor precio y en pesos</stron>.
                </p>
                <p className="Error404__container__text__description">
                  <stron>Bienvenido a Alohar, más Argentinos viajando por Argentina.</stron>
                </p>
                <br />
                <Link className="Error404__container__text__btn" to="/alohar">¡Quiero publicar!</Link>
              </>
             : ''}
              
            
          </div>
          <div className="Error404__container__img">
            <img src={error} alt="Error 404"/>
          </div>
        </div>
      </div>
    </ScreenClassProvider>
  );
}

export default EmailConfirm;