import React from "react";

const ArrowPagination = ({ size, color, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size : "24"}
      height={size ? size : "24"}
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path
        stroke={color && color}
        d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
      ></path>
      <path
        stroke={color && color}
        d="M0 0h24v24H0z"
        fill={"none"}
      ></path>
    </svg>
  );
};

export default ArrowPagination;
