// Librerías
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
// Componentes
import MainContainer from "../Utils/MainContainer";

const Cancel = (props) => {
  const { id } = useParams();

  const [reason, setReason] = useState("");
  const [penalty, setPenalty] = useState(0);
  const [messageError500, setMessageError500] = useState(false);

  const history = useHistory();
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "reason") setReason(value);
    if (name === "penalty") setPenalty(value);
  };

  const sendData = () => {
    const url = `${BASE_URL}/v2/reservations/${id}`;
    const cancellationData = {
      data: {
        reason: reason.toString(), // Reemplaza con el motivo de cancelación real
        penalty: penalty, // Reemplaza con la penalización real (opcional)
        user: "admin",
      },
    };
    AxiosAuth.delete(url, cancellationData)
      .then((response) => {
        history.goBack();
      })
      .catch((error) => {
        setMessageError500(true);
        // console.log(error.data);
        // console.log(error.responseText);
      });
  };

  const handleSubmit = () => {
    sendData();
  };

  return (
    <MainContainer title={`Cancelar Reserva ${id}`}>
      <>
        <div className="MainContainer__form">
          <div className="MainContainer__form__group">
            <label htmlFor="reason" className="MainContainer__form__label">
              Razón de la cancelación
            </label>
            <input
              type="text"
              name="reason"
              id="reason"
              className="MainContainer__form__input"
              placeholder="Razón de la cancelación"
              onChange={handleChange}
              onBlur={handleChange}
              value={reason}
              required
            />
          </div>
          <div className="MainContainer__form__group">
            <label htmlFor="penalty" className="MainContainer__form__label">
              Penalización (En pesos $$$$)
            </label>
            <input
              type="number"
              name="penalty"
              id="penalty"
              className="MainContainer__form__input"
              placeholder="Razón de la cancelación"
              onChange={handleChange}
              onBlur={handleChange}
              value={penalty}
              required
            />
          </div>
          <div className="UserEditComponent__btns">
            <button
              className="MainContainer__form__btn__outline"
              onClick={() => history.goBack()}
            >
              <p className="MainContainer__form__btn__outline__text">Volver</p>
            </button>
            <button className="MainContainer__form__btn" onClick={handleSubmit}>
              Guardar
            </button>
          </div>
          {messageError500 &&
            "Error al cancelar reserva si es inmediata hay que esperar a las 24hs para poder cancelar"}
        </div>
      </>
    </MainContainer>
  );
};

export default Cancel;
