import axios from "axios";
import { BASE_URL } from "../Helpers";

import LocalStorageService from "./localStorageService";
//https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
//https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token

const refresh_url = BASE_URL + "/auth/refresh";
const localStorageService = LocalStorageService.getService();

const AxiosAuth = axios.create({
  baseURL: BASE_URL,
});

// Request interceptor for API calls
AxiosAuth.interceptors.request.use(
  async (config) => {
    const token = localStorageService.getAccessToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
AxiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      originalRequest.headers["Authorization"] = `Bearer ${access_token}`;
      return new Promise((resolve, reject) => {
        axios
          .request(originalRequest)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = localStorageService.getRefreshToken();
  const token = await axios
    .post(refresh_url, { refresh_token: refreshToken })
    .then((res) => {
      if (res.status === 201) {
        // 1) put token to LocalStorage
        const new_token = res.data.data.payload.token;
        const user = res.data.data.user;
        localStorageService.setToken(new_token);
        localStorage.setItem(
          "userData",
          JSON.stringify({
            id: user.id,
            avatar: user.avatar,
            firstName: user.firstName,
            lastName: user.lastName,
            login: true,
          })
        );
        return new_token;
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        //window.location.replace("/")
      }
      return Promise.reject(err);
    });
  return token;
};

export default AxiosAuth;
