import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setChild, setAdult } from '../../store/search/actions';

function mapDispatchToProps(dispatch){
    return bindActionCreators({ setAdult: setAdult, setChild:setChild },dispatch)
}

class SelectPerson extends React.Component{

    constructor(props){

        super(props)
        
        this.state = {
            personas: Number.isInteger(parseInt(this.props.adults)) && Number.isInteger(parseInt(this.props.childs)) ? this.props.adults + this.props.childs : 1,
            adultos: this.props.adults ? this.props.adults : 1,
            menores: this.props.childs ? this.props.childs : 0,
            active:false,
        };
    }

    componentDidMount(){
        this.setState({
            personas: parseInt(this.props.adults) + parseInt(this.props.childs),
            adultos: this.props.adults,
            menores: this.props.childs,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.adults !== this.state.adultos || this.props.childs !== this.state.menores) {

                this.setState({
                    adultos: this.props.adults,
                    personas: parseInt(this.props.adults) + parseInt(this.props.childs),
                    menores: this.props.childs,
                })
            
        }
      }

    showOptions(){
        this.setState({
            active: true
        })
    }

    calculatePersons(type,num){

        const {menores,adultos,personas} = this.state
        if(type === "menores"){
            if(num > 0 || (num < 0 && menores > 0)  ){
                this.setState({
                    menores: parseInt(menores) + num,
                    personas: parseInt(personas) + num
                },()=>{
                    this.props.setChild( parseInt(menores) + num )
                })
                
            }
        }

        if(type === "adultos"){
            if(num > 0 || (num < 0 && adultos > 0)  ){
                if(parseInt(adultos) + num > 0){
                    this.setState({ 
                        adultos: parseInt(adultos) + num,
                        personas: parseInt(personas) + num
                    },()=>{
                        this.props.setAdult( parseInt(adultos) + num )
                    })
                }
            }
        }
    }

    hideFilter(){
        this.setState({
            active: false
        })
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClick, false)
        
    }

    handleCloseClick = () =>{
        this.hideFilter();
    }

    handleClick = (e) =>{
        // Me salvo la vida
        // https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-react-component-5604830beb7f

        if(this.node){
            if(this.node.contains(e.target)){
                return
            }
        }
        this.hideFilter();
    }


    render(){

        const {active, personas, adultos, menores} = this.state

        return (
            <div  onClick={()=>this.showOptions()}   className="input_wrapper" id="select_personas" ref={node => this.node = node}>
                <label id="persons_label" className="input_label">Personas</label>
                <div className="person_select">{personas}</div>
                <div className={ active ? 'lista_personas visble' : 'lista_personas'} >
                    <div className="personas_wrapper">
                        <div className="opciones_personas">
                            <div className="person_type">Adultos</div>
                            <div className="options">
                                <button className="round_boton" onClick={() => this.calculatePersons('adultos',-1) }>-</button>
                                <div className="contador">{adultos}</div>
                                <button className="round_boton" onClick={() => this.calculatePersons('adultos',1) }>+</button>
                            </div>
                        </div>
                        <hr/>
                        <div className="opciones_personas">
                            <div className="person_type">Menores</div>
                            <div className="options">
                                <button className="round_boton" onClick={() => this.calculatePersons('menores',-1) }>-</button>
                                <div className="contador">{menores}</div>
                                <button className="round_boton" onClick={() => this.calculatePersons('menores',1) }>+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (reducers) => {
    return reducers.searchReducer
  };

export default connect(mapStateToProps,mapDispatchToProps)(SelectPerson);
