import React from 'react';
import '../../assets/styles/Utils/__separator.sass';

const Separator = () => {
  return (
    <div className="MainSeparator">
    </div>
  )
}

export default Separator;