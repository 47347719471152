// Librerías
import React, { useState, useEffect } from 'react'
import AxiosAuth from '../../../services/requestApi';
import { BASE_URL } from '../../../Helpers';
// Recursos
import '../../../assets/styles/Utils/__heart.sass';
import useCurrentLanguaje from '../../../services/currentlenguaje';
import globalJson from "../../../utilities/traduccGlobal.json";

const Heart = ( props ) => {
  const { handleFilterLang } = useCurrentLanguaje();

  const [liked, setLiked] = useState(false);

  const sendData = (liked) =>{
    const url = `${BASE_URL}/favorites/toggle/${props.housingid}`;
    const data = {
      liked: liked
    }
    AxiosAuth.post(url,data)
    .catch((error) => {
      console.log(error)
    });
  }
  
  const handleSave = () => {
    if (liked){
      setLiked(false)
      sendData(false);
    } else {
      setLiked(true) 
      sendData(true);
    }
  }

  useEffect(() => {
    setLiked(props.liked);
  },[ props ])

  return (
    <button className={`FavoriteSaveBtn${liked ? ' liked': ''}`} onClick={ handleSave }>
      { liked ? handleFilterLang(globalJson).guardado : handleFilterLang(globalJson).guardar }
    </button>
  )
}

export default Heart
