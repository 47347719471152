// Librerías
import React from 'react';
import { connect, useDispatch } from 'react-redux';
// Componentes
import { closeModal } from '../../store/modal/actions'
// Recursos
import x from '../../assets/static/Modals/x.svg';
import aloharAvatar from '../../assets/static/Modals/aloharTalking.svg';
import '../../assets/styles/Modals/_welcome_modal.sass';


const WelcomeModal = ({ handleReSendEmail, showSendEmail, dark, name }) => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch(closeModal())
    }

    const reSendEmail = () => {
        handleReSendEmail()
    }
    
    return(
        <div className={`WelcomeModal ${ ( dark ) ? 'dark' : ''}`}>
            <header className="WelcomeModal__header">
                <img onClick={() => dispatch(closeModal())} alt="x" src={x} className="WelcomeModal__header__cross"/>
            </header>
            <section className="WelcomeModal__section">
                <div className="WelcomeModal__section__iconContainer">
                    <img className="WelcomeModal__section__iconContainer__avatar" src={ aloharAvatar } alt="Alohar Avatar"/>
                </div>
                <h2 className={`WelcomeModal__section__title ${ ( dark ) ? 'dark' : ''}`}>
                    ¡Bienvenido a Alohar,<br /><b>{name}!</b>
                </h2>
                <p className={`WelcomeModal__section__text ${ ( dark ) ? 'dark' : ''}`}>
                    Tu cuenta fue creada exitosamente. Te envitamos<br />un mail con un codigo de confirmación.
                </p>
            </section>
            <section className="WelcomeModal__buttons">
                <button className="WelcomeModal__buttons__btn" onClick={handleClick}>Cerrar</button>
                { showSendEmail 
                    ?
                    <p onClick={reSendEmail} className={`WelcomeModal__buttons__text ${ ( dark ) ? 'dark' : ''}`}>
                        Reenviar email de activación
                    </p>
                    :
                    <p className={`WelcomeModal__buttons__text ${ ( dark ) ? 'dark' : ''}`}>
                        Correo enviado
                    </p>
                
                }
            </section>
        </div>
    );
}

export default connect(null,{closeModal})(WelcomeModal);
