// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
// Componentes
import Buttons from '../Utils/Buttons';
import ButtonEdit from '../Utils/ButtonEdit';
import ErrorMessage from '../../Alerts/ErrorAlert';
import ProgressBar from '../Utils/ProgressBar';
import Radio from '../Utils/Radio';
import ToggleButtons from '../../Utils/ToggleButtons';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_rules.sass';

const cancellation_policy= [
  {
    id: "cancellation_policy_1",
    value: "flex",
    name: "cancellation_policy",
    title: "Flexible",
    description: [
      "Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si comunican su decisión de cancelar con una anticipación no menor a 24 horas antes del Check in*. Caso contrario no habra derecho alguno a reembolso. Una vez operado el ingreso  por parte del huesped y hallándose en uso de los servicios, si este efectuare por su decision abandono de los mismos, carecera de derecho alguno a reembolso por las noches no utilizadas.",
    ],
    checked: true,
  },
  {
    id: "cancellation_policy_2",
    value: "moderate",
    name: "cancellation_policy",
    title: "Moderada",
    description: [
      "Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si cancelan al menos  7 días antes del Check in*."
    ],
  },
  {
    id: "cancellation_policy_3",
    value: "stric",
    name: "cancellation_policy",
    title: "Estricta",
    description: [
      "Los huéspedes recibirán un reembolso del 50% (menos la tarifa de servicio del huésped) si cancelan al menos  30 días antes del Check in*"
    ],
  },
  {
    id: "cancellation_policy_4",
    value: "heavy",
    name: "cancellation_policy",
    title: "Muy Estricta",
    description: ["Si el huésped cancela la reserva, no se le reembolsará ningún importe abonado."],
  },
]

const rules = [
  {
    title:"Se permite fumar",
    value:"smooke"
  },
  {
    title:"Se admiten mascotas",
    value:"pets"
  },
  {
    title:"Se permiten fiestas o eventos",
    value:"party"
  }
]

class Rules extends Component{
  constructor(props) {
    super(props);

    this.state = {
      rule: this.props.bodyParameters.rule || [],
      ruleExtra: this.props.bodyParameters.ruleExtra || '',
      cancelation: this.props.bodyParameters.cancelation || 'flex',
      errors : {},
      modeEdit: this.props.modeEdit || false,

    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.handleValidate()
    this.props.ruleStep(this.state) 
  }

  handleValidate = () =>{
    let errors = {};
    const validate = ( this.state.cancelation.length !== '')

    if(this.state.cancelation.length === 0)
      errors['cancelation'] = "Debe elegir un tipo de cancelación"

      this.props.validateStep(validate);
      this.setState({errors:errors})
  }

  handleChange = (event) => {

    let name = event.target.name;
    let value = event.target.value;
    let checked = event.target.checked;
    const options = this.state[name]

    let index
    if(name === "rule"){
      if(checked){
        options.push(value)
      }else{
        index = options.indexOf(value)
        options.splice(index, 1)
      }
      this.setState({ rule: options }, () => {
        this.props.ruleStep(this.state)
        this.handleValidate();
      } );
    }

    if(name === "cancelation")
      this.setState({ cancelation: value },()=>{
        this.props.ruleStep(this.state)
        this.handleValidate();
        } );

    if(name === "ruleExtra")
      this.setState({ ruleExtra: value },()=>{
        this.props.ruleStep(this.state)
        this.handleValidate();
      } );

  }

  render(){
    return(
      <React.Fragment>
        <div className='rulesResponsive'>
          <div className="Rules">
          {this.state.modeEdit ? '' : <ProgressBar title={"NORMAS DE LA CASA"} stepAt={'Paso 3: Toques finales'} />}
            <div className="Rules__container">
            <div className="Rules__container-right">
                <h2 className="Rules__container__title">
                  Política de Cancelación
                </h2>
                <p className="Rules__container__descriptionLeft">
                Elegí que política de cancelación queres que tu espacio tenga en el caso que el huésped cancele la reserva confirmada.
                </p>

                {cancellation_policy.map((service,index) => (
                    <Radio
                      id={service.id}
                      key={service.id}
                      value={service.value}
                      name="cancelation"
                      title={service.title}
                      descriptions={service.description}
                      customClickEvent={this.handleChange.bind(this)}
                      checked={service.value === this.state.cancelation}
                    />
                  ),this)}
                {/* <p className="Rules__container__description">
                  <b>*Check in:</b> Se contemplan horas y días completas antes de la hora local de llegada del anuncio (indicada en el correo electrónico de confirmación).
                </p> */}
                  {
                    this.state.errors["cancelation"]
                    ? <ErrorMessage message={this.state.errors["cancelation"]} />
                    : ''
                  }
              </div>
              <div className="Rules__container-left">
                <h2 className="Rules__container__title">
                  Normas de la casa
                </h2>
                <p className="Rules__container__descriptionRigth">
                  Los huéspedes tienen que aceptar tus normas de la casa antes de reservar.
                </p>

                {rules.map((rule) => (
                    <ToggleButtons 
                      id={rule.value}
                      key={rule.value}
                      value={rule.value}
                      name="rule"
                      title={rule.title}
                      customClickEvent={this.handleChange.bind(this)}
                      checked={this.state.rule.indexOf(rule.value) !== -1}
                    />
                  ),this)}

                <div className="Rules__container__inputContainer">
                  <label className="Rules__container__inputContainer__label" htmlFor="custom_rule">Norma de la casa</label>
                  <textarea
                    id="custom_rule"
                    name="ruleExtra"
                    rows="4"
                    className="Rules__container__inputContainer__textarea"
                    value = {this.state.textareaVal}
                    onChange={this.handleChange}
                    defaultValue={this.state.ruleExtra}
                    placeholder="Completá adicionales"
                  ></textarea>
                </div>
              </div>
            </div>
            { this.state.modeEdit ? <ButtonEdit /> : <Buttons /> }
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer
};

export default connect(mapStateToProps ,aloharFormActions)(Rules);