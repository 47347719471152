// Liberías
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector, shallowEqual } from "react-redux";
import { openRegisterModal } from "../../store/modal/actions";
// Componentes
import Navbar from "../Navbar/Navbar";
import Flag from "../LandingPage/Flag";
// Recursos
import "../../assets/styles/RentLanding/_headline.sass";
import logoBlanco from "../../assets/static/logotipo.png";
import { BASE_URL } from "../../Helpers";
import axios from "axios";
import queryString from "query-string";
import AxiosAuth from "../../services/requestApi";
import ButtonPrimary from "../ui/button-primary";

const Headline = () => {
  const user = useSelector((state) => state.userReducer, shallowEqual);
  window.scrollTo(0, 0);
  const query = queryString.parse(window.location.search);
  const { partner } = query;
  const [email, setEmail] = useState("");

  const setNavBar = (user) => {
    if (user.login === true) {
      return (
        <Navbar isLanding={true} hideAlohadorButtons={true} showMobile={true} />
      );
    } else {
      return (
        <Navbar
          isLanding={true}
          hideAlohadorButtons={true}
          showMobile={false}
        />
      );
    }
  };

  useEffect(() => {
    localStorage.removeItem("partner");
    const url = `/user/myProfile`;
    AxiosAuth.get(url)
      .then((response) => {
        const { email } = response.data;
        setEmail(email);
        if (email) {
          sendAndSaveMarketingLog(partner);
        }
      })
      .catch((error) => {
        console.log(error);
        sendAndSaveMarketingLog(partner);
      });
  }, [email]);

  const sendAndSaveMarketingLog = (partner) => {
    localStorage.setItem("partner", JSON.stringify(partner));
    const url = BASE_URL + "/housing/marketing/";
    const userEmail = email
      ? `${email},housing_new_testing`
      : "housing_new_testing";
    const bodyParameters = {
      action: `${userEmail}`,
      partner: partner,
      reservation: null,
      housing: null,
    };
    axios.post(url, bodyParameters).catch((error) => {
      console.log(error.response);
    });
  };
  const router = useHistory();

  return (
    <React.Fragment>
      <div className="Headline">
        {setNavBar(user)}

        <div className="Headline__container">
          <Link className="Headline__link" to="/">
            <img className="Headline__link__logo" src={logoBlanco} alt="logo" />
          </Link>
          <div className="Headline__container__cc">
            <h1 className="Headline__title">Quiero ser Alohador</h1>
            <ButtonPrimary
              title={"Alohá"}
              onClick={
                user.login
                  ? router.push("/alohar")
                  : () => router.push("/register")
              }
            />
          </div>
          <p className="Headline__text">
            Ana es alohadora
            <br />
            en MAR DEL PLATA
          </p>
        </div>
      </div>
      <Navbar />
      <Flag />
    </React.Fragment>
  );
};

export default connect(null, { openRegisterModal })(Headline);
