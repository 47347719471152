import React, { useState } from "react";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
import { useForm } from "../../../hooks/useForm";
import ModalGlobal from "../../Modals/modal-global";
import InputGlobal from "../../ui/input-global";
import ButtonPrimary from "../../ui/button-primary";
import CrossIcon from "../../../assets/components-icons/CrossIcon";

const RequiredUserData = ({ onSuccess, openModal, setOpenModal }) => {
  const initialForm = {
    numberPhone: 0,
    dni: 0,
  };
  const { form, handleChange } = useForm(initialForm, null);
  const [loading, setLoading] = useState(false);

  const putDatProfile = () => {
    setLoading(true);
    const body = {
      phoneNumber: form.numberPhone,
      dni: form.dni,
    };
    AxiosAuth.put(`${BASE_URL}/user/required-data-profile`, body)
      .then((response) => {
        setLoading(false);
        setOpenModal(false);
        onSuccess(true);
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <ModalGlobal close={openModal}>
      <div
        className="flex flex-col 
        relative
        py-11
        items-center
        justify-between
        gap-8
       bg-white  max-w-[650px] max-h-[400px] w-11/12 rounded-14 "
      >
        <span
          className="absolute cursor-pointer top-4 right-4"
          onClick={() => {
            setOpenModal(!openModal);
            setLoading(false);
          }}
        >
          <CrossIcon />
        </span>
        {/* justify-center items-center */}
        <h2 className="px-6 text-lg text-center font-robotoBold">
          Necesitamos los siguientes datos antes de continuar
        </h2>
        <div className="flex flex-col items-center justify-center w-full h-full pt-10 gap-7 ">
          <InputGlobal
            // width={"100%"}
            prefix={"Numero de telefono"}
            className={"w-5/6 sm:w-40 sm:col-span-1"}
            type={"number"}
            value={form.numberPhone}
            name={"numberPhone"}
            onChange={handleChange}
          />
          <InputGlobal
            // width={"100%"}
            prefix={"Cuil"}
            className={"w-5/6 sm:w-40 sm:col-span-1"}
            type={"number"}
            value={form.dni}
            name={"dni"}
            onChange={handleChange}
          />
        </div>
        <ButtonPrimary
          disabled={form.numberPhone < 1 || form.dni < 1}
          loading={loading}
          title={"Guardar"}
          onClick={() => putDatProfile()}
        />
      </div>
    </ModalGlobal>
  );
};

export default RequiredUserData;
