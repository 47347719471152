import SelectCity from "../../../components/MainSearch/SelectCity";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCity } from "../../../store/search/actions";
import { useEffect } from "react";
import { useState } from "react";

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCity: setCity }, dispatch);
}

const GetCityId = (props) => {
  const [cityId, setCityId] = useState();
  useEffect(() => {
    if (props.city || props.city === 0) {
      props.setCityId(props.city);
    }
    setCityId(props.city);
  }, [props.city]);
  return (
    <div>
      <SelectCity
        cityId={cityId}
        backOffice={props.backOffice}
        placeholder="Elejir ciudad"
      />
    </div>
  );
};
const mapStateToProps = (reducers) => {
  return reducers.searchReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(GetCityId);
