// Librerías
import React from 'react';
// Recursos
import '../../assets/styles/Alerts/__error_alert.sass';
import alert from '../../assets/static/alert.svg';

const ErrorAlert = ({ type, message }) => {
  return(
    <React.Fragment>
      <div className={(type)? `alert-${type}` : 'alert-error'}>
        {
          (type)
          ? ''
          : <img src={ alert } alt="Alert"/>
        }
        { message }
      </div>
    </React.Fragment>
  );
}

export default ErrorAlert;