// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../store/aloharForm/actions";

// Componentes
import Values from "./Steps/Values";
import UserStep from "./Steps/UserStep";
import Title from "./Steps/Title";
import Type from "./Steps/Type";
import Quantities from "./Steps/Quantities";
import Location from "./Steps/Location";
import Services from "./Steps/Services";
import Covid from "./Steps/Covid";
import Photos from "./Steps/Photos";
import Rules from "./Steps/Rules";
import Guests from "./Steps/Guests";
import Prices from "./Steps/Prices";
import Calendar from "./Steps/Calendar";
import Payment from "./Steps/Payment";
import Legal from "./Steps/Legal";
import Finished from "./Steps/Finished";
class AloharForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLang: this.props.currentLang || "es",
    };
  }
  componentDidUpdate(prevProps) {
    // Actualiza el estado solo si el props del lenguaje ha cambiado
    if (prevProps.lenguaje !== this.props.lenguaje) {
      this.setState({ currentLang: this.props.lenguaje });
    }
  }

  showStep = () => {
    let step = this.props.step;

    let bodyParameters = {};

    // if (this.props.modeEdit === undefined) {
    //   localStorage.removeItem("housingPublicProcessData");
    // }
    if (this.props.steps[step]) {
      bodyParameters = this.props.steps[step].bodyParameters;
    }

    // if (step === 16) {
    //   /* Cuando finaliza el proceso de publicación la información queda en Redux
    //     por lo cual si se hace el restart al finalizar, debido al state, automaticamente se reinicia
    //     el proceso de publicación. Para evitar ese comportamiento dejo aca el restart del redux
    //     y la redirección al paso 1.
    //     Que dios y la patria me juzguen.
    //   */
    //   step = 1;
    //   this.props.restart();
    // }
    switch (step) {
      case 1:
        return (
          <Values
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 2:
        return (
          <UserStep
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 3:
        return (
          <Type
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 4:
        return (
          <Quantities
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 5:
        return (
          <Location
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 6:
        return (
          <Services
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 7:
        return (
          <Covid
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 8:
        return (
          <Photos
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 9:
        return (
          <Rules
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 10:
        return (
          <Guests
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 11:
        return (
          <Title
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 12:
        return (
          <Prices
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 13:
        return (
          <Payment
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 14:
        return (
          <Legal
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 15:
        return (
          <Calendar
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      case 16:
        return (
          <Finished
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
      default:
        return (
          <Legal
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
          />
        );
    }
  };

  render() {
    return <React.Fragment>{this.showStep()}</React.Fragment>;
  }
}

const mapStateToProps = (reducers) => {
  return { ...reducers.aloharFormReducer, ...reducers.userReducer };
};

export default connect(mapStateToProps, aloharFormActions)(AloharForm);
