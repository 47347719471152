import { useEffect } from "react";
import { useLanguage } from "../store/lenguaje/useLenguaje";

const useCurrentLanguage = () => {
  const { currentLang } = useLanguage();
  useEffect(() => {}, [currentLang]);

  const handleFilterLang = (jsonLenguaje) => {
    return jsonLenguaje[currentLang];
  };

  return { handleFilterLang };
};

export default useCurrentLanguage;
