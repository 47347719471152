// Librerías
import React from 'react';
// Componentes
import ReservationList from '../../../components/BackOffice/Reservations/Reservations';

const Reservations = () => {
  return(
    <ReservationList />
  );
}

export default Reservations;