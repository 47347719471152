// Librerías
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import { cleanRedirectUser, redirectUser } from "../../store/user/actions";
import {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
} from "../../store/modal/actions";
// Componentes
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
// Recursos
import { loginUser } from "../../store/user/actions";
import "../../assets/styles/Users/_login.sass";
import loadingGif from "../../assets/static/loading.gif";

const SocialLoginContainer = () => {
  const loginState = useSelector((state) => state.userReducer, shallowEqual);
  const dispatch = useDispatch();
  const query = queryString.parse(window.location.search);
  const history = useHistory();

  useEffect(() => {
    /* Social login callback*/
    const { token, refresh_token, firstName, lastName, avatar, id } = query;

    if ((token && refresh_token && firstName && lastName && avatar, id)) {
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: token,
          refresh_token: refresh_token,
          avatar: avatar,
          firstName: firstName,
          lastName: lastName,
          login: true,
          id: id,
        })
      );

      dispatch(
        loginUser({ token, refresh_token, firstName, lastName, avatar, id })
      );

      if (loginState.redirect) {
        dispatch(cleanRedirectUser());
        history.push(loginState.redirect);
        // window.location.replace(`${loginState.redirect}`)
      } else {
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }
    }
  });
  return (
    <React.Fragment>
      <Navbar />

      <div className="Login">
        <center className="Login__gif">
          <img src={loadingGif} alt="loading" />
        </center>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default connect(null, {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
})(SocialLoginContainer);
