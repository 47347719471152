// Librerías
import React from 'react'
import ProfileHousingCard from '../../Utils/Cards/ProfileHousingCard';
import Separator from '../../Utils/Separator';
// Sass
import '../../../assets/styles/Profile/Utils/_profileuserhousing.sass'

const ProfileUserHousing = (props) => {
  const housing = props.housing;
  const housingAmount = housing.length

  return (
    <div className="ProfileUserHousing">
      <h3 className="ProfileUserHousing__title">
        { (housingAmount === 1) ? `Anuncio de ${props.firstName}` : `Anuncios de ${props.firstName}` }
      </h3>
      <div className="ProfileUserHousing__container">
        {
          housing && housingAmount > 0
          ? housing.map( h => <ProfileHousingCard housing={ h } key={h.id} /> )
          : ''
        }
      </div>
      <Separator />
    </div>
  )
}

export default ProfileUserHousing
