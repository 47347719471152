// Librerías
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AxiosAuth from '../services/requestApi';
import { BASE_URL } from '../Helpers';
import ReactGA from 'react-ga';

// Componentes
import Navbar from '../components/Navbar/Navbar';
import HousingList from '../components/MyHousing/HousingList';
import LocalStorageService from '../services/localStorageService';
import HousingListAPP from '../components/MyHousing/HousingListAPP';

ReactGA.initialize('G-R95SZ6875J');

// Función para obtener los parámetros de la URL
const getParamsFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    token: urlParams.get('token'),
    refreshToken: urlParams.get('refreshToken'),
    app: urlParams.get('app'),
  };
};

const MyHousingsPage = (props) => {
  const [housingList, setHousingList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token, refreshToken, app } = getParamsFromUrl();

  useEffect(() => {
    if (token) {
      LocalStorageService.setToken(token);
    }
    if (refreshToken) {
      LocalStorageService.setRefreshToken(refreshToken);
    }
    if (app !== 'true') {
      // Redireccionar o mostrar un mensaje de error
      window.location.href = '/error'; // Redireccionar a una página de error
      // alert('La URL no tiene el parámetro app=true'); // Mostrar mensaje de error
    }
  }, [token, refreshToken, app]);

  useEffect(() => {
    if (app === 'true') {
      const url = `${BASE_URL}/housing/my`;

      AxiosAuth.get(url)
        .then((response) => {
          setHousingList(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [token, app]);

  if (app !== 'true') {
    return null; // No renderizar el componente si app no es 'true'
  }

  return (
    <div className="bg-grey">
      {/* <Navbar /> */}
      <HousingListAPP housingList={housingList} loading={loading} />
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.userReducer;
};

export default connect(mapStateToProps, null)(MyHousingsPage);
