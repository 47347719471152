import React from "react";

const GoogleTagManagger = () => {
  return (
    <>
      <script
        src="https://www.googletagmanager.com/ns.html?id=GTM-WF57PN4"
        async={true}
        strategy="LazyOnload"
      ></script>

      <script id="GTM-WF57PN4" strategy="LazyOnload">
        {`
            window.dataLayer = window.dataLayer || []
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-GTM-WF57PN4)

          `}
      </script>
    </>
  );
};

export default GoogleTagManagger;
