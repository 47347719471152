import React from "react";
import "../../assets/styles/Utils/bannerTransfer.sass";
import useCurrentLanguage from "../../services/currentlenguaje";
import globalJson from "../../utilities/traduccGlobal.json"

const BannerTransfer = () => {
  const { handleFilterLang } = useCurrentLanguage();

  
  return (
    <div className="bannerTransfer">
      <h2 className="bannerTransfer__logo">$</h2>
      <h2 className="bannerTransfer__title">
      {handleFilterLang(globalJson).aprovechaTransferencia}
        
      </h2>
    </div>
  );
};

export default BannerTransfer;
