import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../../../Helpers";
import AxiosAuth from "../../../../services/requestApi";
import moment from "moment";
import LoadingGift from "../../../../assets/components-icons/LoadingGift";
import BoxSendMessage from "../box-send-message";
import "./send-message.sass";

const SendMessage = ({ user, refClickUser, userId, setUserHousing }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [housingId, setHousingId] = useState("");
  const [chatList, setChatList] = useState([]);
  const [toId, setToId] = useState("");
  const [page, setPage] = useState(1);
  const [disabledNewMessages, setDisabledNewMessages] = useState(false);
  const [disabledScroll, setDisabledScroll] = useState(true);
  const [scollToDown, setScollToDown] = useState({});
  const getMessagesWith = async () => {
    setDisabledNewMessages(false);
    setLoading(true);
    try {
      let url;
      if (userId) {
        url = `${BASE_URL}/user/messages/with/${user.id}/${userId}`;
      } else {
        url = `${BASE_URL}/v2/user/messages/with/${user.id}?page=${page}&pageSize=10`;
      }

      const response = await AxiosAuth.get(url);
      if (response.data.length < 10) {
        setDisabledNewMessages(true);
      }

      const newMessages =
        response.data.length > 0 ? response.data.reverse() : [];

        setMessages((prevMessages) => [...prevMessages, ...newMessages]);
        setChatList(newMessages);
        setHousingId(newMessages[0]?.hosting || "");
        setUserHousing(newMessages[0]?.hosting || "");
  
      setToId(user.id);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (user) {
      setMessages([]);
      setChatList([]);
      setPage(1);
    }
  }, [user]);

  useEffect(() => {
    if (user.id) {
      getMessagesWith();
    }
  }, [page, user.id]);

  useEffect(() => {
    if (Array.isArray(messages)) {
      setChatList(messages.sort((a, b) => a.id - b.id));
    }
  }, [messages]);

  const scrollRef = useRef(null);
  useEffect(() => {
    if (
      disabledScroll &&
      chatList.length > 0 &&
      scollToDown &&
      scrollRef.current
    ) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (chatList.length < 10) {
      setDisabledScroll(true);
    }
  }, [scollToDown, chatList, user]);

  const replaceCharacter = (string) => {
    let result = "";
    for (let i = 0; i < string.length; i++) {
      if ((i + 1) % 20 === 0) {
        result += "\n";
      } else {
        result += string[i];
      }
    }
    return result;
  };
  console.log("housingId");
  console.log(housingId);

  return (
    <div className="sendMessage">
      {loading && (
        <div className="sendMessage__loading">
          <LoadingGift size={100} />
          <h2>cargando mensajes...</h2>
        </div>
      )}

      <div className="sendMessage__content">
        {!disabledNewMessages && !loading && (
          <button
            onClick={() => {
              setPage((prevPage) => prevPage + 1);
              setDisabledScroll(false);
            }}
            disabled={loading}
          >
            Ver mensajes antiguos
          </button>
        )}
        {chatList.map((e) =>
          e.message.trim() !== "" ? (
            <div
              key={e.id}
              className={
                e.from.id === user.id
                  ? "sendMessage__content__container"
                  : "sendMessage__content__containerLeft"
              }
            >
              <div
                className={
                  e.from.id === user.id
                    ? "sendMessage__content__container__message"
                    : "sendMessage__content__container__messageLeft"
                }
              >
                <p
                  className={
                    e.from.id === user.id
                      ? "sendMessage__content__container__message__textLeft"
                      : "sendMessage__content__container__message__textRight"
                  }
                >
                  {e.message}
                </p>
                <span className="sendMessage__content__container__message__date">
                  {moment(e.issued).utc(true).format("h:mm, D MMM ")}
                </span>
              </div>
            </div>
          ) : null
        )}
        <div ref={scrollRef}></div>
      </div>
      <BoxSendMessage
        setPage={setPage}
        setScollToDown={setScollToDown}
        setChatList={setChatList}
        toId={toId}
        housingId={housingId}
        refClickUser={refClickUser}
      />
    </div>
  );
};

export default SendMessage;
