import React, { Suspense, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

import { Link } from "react-router-dom/cjs/react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import * as serachActions from "../../store/search/actions";
import "../LandingPage/landing-home.css";
import "swiper/swiper-bundle.css";

import marDelPlataTitle from "../../assets/static/Promos/mar-del-plata-title.webp";
import iguazuTitle from "../../assets/static/Promos/iguazu-title.webp";
import BariloTitle from "../../assets/static/Promos/barilo-title.webp";
import aloharOrangeTitle from "../../assets/static/landing-host/alohar-orange.png";
import arrow from "../../assets/static/Icons/arrowShort.png";
import titleHeaderMobile from "../../assets/static/landing-host/title-header.webp";
import Footer from "../Footer/Footer";
import { HertzModal } from "../Modals/hertzModal";
import MainInputSearchNew from "../MainSearch/MainInputSearchNew";
import Navbar from "../Navbar/Navbar";
import SectionCard from "../AloharForm/components/SectionCard";
import SelectCity from "../MainSearch/SelectCity";
import SwiperBanks from "./landing-components/SwiperBanks";
import WhatsAppButton from "../Buttons/WhatsAppButton";
import moment from "moment";
import ImageLazyLoad from "../ui/image-lazyLoad";
import { BASE_URL } from "../../Helpers";
import AxiosAuth from "../../services/requestApi";
import PromotionsDesktop from "./landing-components/PromotionsDesktop";
import WeDontWannaGetSued from "./WeDontWannaGetSued";
import HousingPromotions from "./housing-promotions";
import Offline from "./Offline";
import axios from "axios";
import BannerHertz from "./landing-components/BannerHertz";
import BannerApp from "./landing-components/BannerApp";

import tradLanding from "./tradLandingPage.json";
import useCurrentLanguage from "../../services/currentlenguaje";

const LandingHome = (props) => {
  SwiperCore.use([Autoplay]);
  const { handleFilterLang } = useCurrentLanguage();

  const [number, setNumber] = useState([]);
  const [offline, setOffline] = useState(false);

  const codeDiscount = async () => {
    const url = `${BASE_URL}/hertz`;
    try {
      const response = await AxiosAuth.get(url);
      setNumber([response.data]);
    } catch (error) {
      console.log(error);
    }
    setOpenModal(true);
  };
  const [openModal, setOpenModal] = useState(false);
  const first = useRef(null);

  const checkin = moment(Date.now()).utc(true).format("DD/MM/YY");
  const checkout = moment().add(5, "days").format("DD/MM/YY");
  useEffect(() => {
    props.setCity({ city: null, cityName: null });
    const url = `${BASE_URL}/location/promotions`;
    axios
      .get(url)
      .then((response) => {})
      .catch((e) => {
        setOffline(true);
      });
  }, []);
  return (
    <>
      {offline ? (
        <div class="header">
          <Offline />
        </div>
      ) : (
        <div
          id="0"
          className="flex flex-col justify-between min-h-screen "
          ref={first}
        >
          <Navbar
            isLanding={true}
            logo="orange"
            showMobile={true}
            icon={true}
          />

          <div className="flex  flex-col w-full mx-auto sm:w-11/12 sm:gap-[4vw] sm:pb-14 sm:mt-[-125px]  relative swiperHome">
            <section
              id="estatic-home"
              className={`flex  sm:rounded-b-[10vw] relative    flex-col  sm:justify-center items-center sm:bg-kayak bg-backgroundHomeMobile bg-cover bg-top h-screen  sm:min-h-full  sm:h-auto py-[7%] `}
            >
              {isMobile && (
                <ImageLazyLoad
                  src={titleHeaderMobile}
                  alt="Titulo"
                  className={
                    "block object-contain w-[75%] mx-auto sm:hidden  absolute left-1/2 -translate-x-1/2 bottom-[30vw]  z-0 "
                  }
                />
              )}

              {isMobile && props.searchReducer.city && (
                <div className="absolute sm:hidden flex top-0 left-0 z-40 w-full h-screen min-h-[700px]   bg-white pt-[100px] ">
                  <MainInputSearchNew />
                </div>
              )}
              {!isMobile && (
                <div className="hidden w-11/12   items-center justify-center sm:flex sm:mt-[15vw] z-40 max-w-[1200px]">
                  <MainInputSearchNew />
                </div>
              )}

              {isMobile && (
                <div className="z-10 flex flex-col  items-center justify-center w-11/12 h-auto px-1 py-8 mt-[30vh] bg-white sm:hidden rounded-70 relative">
                  <h2 className="w-[70%] mx-auto text-[3vw] sm:text-lg   font-semibold ">
                    {handleFilterLang(tradLanding).proximoDestino} <br />
                  </h2>
                  <div className="homeInputCity ">
                    <SelectCity
                      selectedCity={props.searchReducer.city}
                      placeholder={handleFilterLang(tradLanding).dondeVamos}
                    />
                  </div>
                </div>
              )}

              <h1 className="sm:mt-9 hidden sm:block ms:text-[3vw] text-[3.5vw] text-center text-white  font-castaBold ">
                {handleFilterLang(tradLanding).vosViaja}
              </h1>
              <h2 className=" sm:flex  sm:justify-center hidden  RobotoBold  sm:text-[1.8vw] text-2x1 text-start sm:text-center text-white w-9/12 mx-auto">
                {handleFilterLang(tradLanding).alohateEnPesos}
              </h2>
            </section>

            <HousingPromotions />
            <BannerHertz codeDiscount={codeDiscount} />

            {/* <SwiperPromotions codeDiscount={codeDiscount} /> */}

            <PromotionsDesktop />

            {isMobile && (
              <Suspense fallback={<></>}>
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  preloadImages={1}
                  lazy={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  className="relative block w-full h-screen sm:hidden "
                >
                  <SwiperSlide>
                    <div className="flex flex-col h-full swiperHome">
                      <div
                        className={`relative bg-top bg-cover bg-bariloche  flex justify-center items-center h-[65%] row-span-2`}
                      >
                        <ImageLazyLoad
                          alt={"Bariloche"}
                          src={BariloTitle}
                          className={`bg-center left-[20%]  top-1/2  absolute
                      -translate-y-1/2   bg-no-repeat w-[200px]  `}
                        />
                      </div>
                      <div className="flex items-center pl-[20%] bg-primaryHome h-[35%] text-white ">
                        <Link
                          rel="noopener noreferrer"
                          to={`bariloche/search?city=56&checkin=${checkin}&checkout=${checkout}&childs=0&adults=1&cityName=bariloche`}
                          className="flex flex-col gap-4  text-white text-[6vw] hover:text-white RobotoBold  leading-[130%] "
                        >
                          {handleFilterLang(tradLanding).alohamientos} <br />
                          {`desde AR$ ${Intl.NumberFormat("de-AR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          }).format(parseFloat(45000).toFixed(2))}`}
                          <br />
                          {handleFilterLang(tradLanding).porDia}
                          <img src={arrow} className="w-[7vw]" alt="Arrow" />
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col h-full ">
                      <div
                        className={`relative bg-top bg-cover bg-iguazu  flex justify-center items-center h-[65%] row-span-2`}
                      >
                        <ImageLazyLoad
                          alt={"Iguzu"}
                          src={iguazuTitle}
                          className={`bg-center left-[20%]  top-1/2  absolute
                      -translate-y-1/2   bg-no-repeat w-[200px]  `}
                        />
                      </div>
                      <div className="flex items-center pl-[20%] bg-primaryHome h-[35%] text-white ">
                        <Link
                          rel="noopener noreferrer"
                          to={`iguzu/search?city=69&checkin=${checkin}&checkout=${checkout}&childs=0&adults=1&cityName=iguzu`}
                          className="flex flex-col gap-4  text-white text-[6vw] hover:text-white font-RobotoBold  leading-[130%] "
                        >
                          {handleFilterLang(tradLanding).alohamientos} <br />
                          {`desde AR$ ${Intl.NumberFormat("de-AR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          }).format(parseFloat(20000).toFixed(2))}`}
                          <br />
                          {handleFilterLang(tradLanding).porDia}
                          <img src={arrow} className="w-[7vw]" alt="Arrow" />
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col h-full ">
                      <div
                        className={`relative bg-top bg-cover bg-marDelPlata  flex justify-center items-center h-[65%] row-span-2`}
                      >
                        <div className="relative w-full ">
                          <ImageLazyLoad
                            alt={"Mar del plata"}
                            src={marDelPlataTitle}
                            className={`bg-center left-[20%]  top-1/2  absolute
                      -translate-y-1/2   bg-no-repeat w-[200px]  `}
                          />
                        </div>
                      </div>
                      <div className="flex items-center pl-[20%] bg-primaryHome h-[35%] text-white ">
                        <Link
                          rel="noopener noreferrer"
                          to={`mar del plata/search?city=1&checkin=${checkin}&checkout=${checkout}&childs=0&adults=1&cityName=mar del plata`}
                          className="flex flex-col gap-4  text-white text-[6vw] hover:text-white font-RobotoBold  leading-[130%] "
                        >
                          {handleFilterLang(tradLanding).alohamientos} <br />
                          {`desde AR$ ${Intl.NumberFormat("de-AR", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          }).format(parseFloat(12000).toFixed(2))}`}
                          <br />
                          {handleFilterLang(tradLanding).porDia}
                          <img src={arrow} className="w-[7vw]" alt="Arrow" />
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="relative block w-full h-screen bg-cover sm:hidden bg-girl ">
                  <a
                    rel="noopener noreferrer"
                    href="/alohar"
                    className="absolute flex flex-col gap-4 text-[#fff!important] left-[20%] bottom-[20%] RobotoBold text-[8vw] leading-[130%] "
                  >
                    {handleFilterLang(tradLanding).serAlohador} <br />
                    {handleFilterLang(tradLanding).esMuySencillo}
                    <br />
                    {handleFilterLang(tradLanding).enterateComo}
                    <img src={arrow} className="w-8 " alt="Arrow" />
                  </a>
                </div>
              </Suspense>
            )}

            <SwiperBanks />
            <Suspense fallback={<></>}>
              <SectionCard
                link={"/alohar"}
                alt={"Alohar"}
                title={aloharOrangeTitle}
                backgroundColor={"bg-dark50"}
                backgroundImg={"bg-girl"}
                styleBackground={{
                  backgroundPositionY: "85%",
                  backgroundPositionX: "100%",
                  backgroundSize: "117%",
                }}
                secondTitle={
                  <>
                    {handleFilterLang(tradLanding).alohaGente} <br />
                    {handleFilterLang(tradLanding).enTuCasa} <br />{" "}
                    {handleFilterLang(tradLanding).yPesos}
                    <br /> {handleFilterLang(tradLanding).enTuCuenta}
                  </>
                }
              >
                {handleFilterLang(tradLanding).serAlohador}
                <br />
                {handleFilterLang(tradLanding).esMuySencillo} <br />
                {handleFilterLang(tradLanding).enterateComo}
              </SectionCard>
            </Suspense>
            {/* <BannerEuropAssitence /> */}
            <BannerApp />
          </div>
          <WeDontWannaGetSued />

          <Footer />
          <HertzModal
            setOpenModal={setOpenModal}
            openModal={openModal}
            number={number}
          />
          <WhatsAppButton />
        </div>
      )}
    </>
  );
};
const mapStateToProps = (reducers) => {
  return {
    searchReducer: reducers.searchReducer,
    userReducer: reducers.userReducer,
  };
};
export default connect(mapStateToProps, serachActions)(LandingHome);
