import React from "react";
import "../../assets/styles/Modals/reservation_modal.sass";
import close from "../../assets/static/Icons/close.svg";

export const ReservationModal = ({ openModal, setOpenModal }) => {
  const refresh = () => {
    setOpenModal(false);
    window.location.reload(true);
  };
  return (
    <div className={openModal ? "reservationModal" : "modalNone"}>
      <div className="reservationModal__header">
        <h2 className="reservationModal__header__title">
          Error en la confirmación de la reserva
        </h2>
        <img
          onClick={() => {
            refresh();
          }}
          className="reservationModal__header__close"
          src={close}
          alt="cerrar"
        />
      </div>
      <h3 className="reservationModal__body">
        No hemos podido confirmar la reserva, debido a que el pago del huésped
        aún no se ha acreditado. Por favor contactar a Atención al cliente para
        más información
      </h3>
      <a
        href="https://api.whatsapp.com/send?phone=5492233018749&text=¡Hola Alohar! Quisiera que me contacten."
        className="reservationModal__footer"
        rel="noopener noreferrer"
        target="_blank"
      >
        Contactar
      </a>
    </div>
  );
};
