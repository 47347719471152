// Librerías
import React from 'react';
// Componentes
import LocationsCreate from '../../../components/BackOffice/Locations/Create';

const Create = () => {
  return(
    <LocationsCreate />
  );
}

export default Create;