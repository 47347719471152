// Librerías
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AxiosAuth from '../services/requestApi'
import {BASE_URL} from '../Helpers'
import axiosInstance from '../services/requestApi'
import ReactGA from 'react-ga';
// Componentes
import Navbar from '../components/Navbar/Navbar';
import HousingList from '../components/MyHousing/HousingList';

ReactGA.initialize('G-R95SZ6875J');

const MyHousingsPage = (props) => {
    const [housingList, setHousingList] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const url = `${BASE_URL}/housing/my`
        
        AxiosAuth.get(url).then(function (response) {
            setHousingList(response.data)
            setLoading(false)
        })
        .catch(function (error) {
            console.log(error)
            setLoading(false)
        })
    },[])


    return(
        <div className="bg-grey">
            <Navbar />
            <HousingList housingList={ housingList } loading={loading} />
        </div>

    );
}


const mapStateToProps = (reducers) => {
    return reducers.userReducer
};

export default connect(mapStateToProps, null)(MyHousingsPage);

