const initialState = {
    isOpen : false,
    type:''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'CLOSE_MODAL':
            return {
                ...state,
                isOpen: false,
                type:''
            }

        case 'OPEN_LOGIN_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'LOGIN'
            }
      
        case 'OPEN_EDIT_MODAL':
          return {
              ...state,
              isOpen: true,
              type:'EDIT'
          }

        case 'OPEN_SHARE_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'SHARE'
            }
        
        case 'OPEN_MESSENGER_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'MESSENGER'
            }
        
            
        case 'OPEN_CONTACT_ALOHADOR_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'CONTACT_ALOHADOR'
            }    
        case 'OPEN_REGISTER_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'REGISTER'
            }

        case 'OPEN_RECOVER_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'RECOVER'
            }
        case 'OPEN_CONFIRM_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'CONFIRM'
            }
        case 'OPEN_NOTIFICATION_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'NOTIFICATION'
            }

        case 'OPEN_BANK_PROMOTION_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'BANK_PROMOTION'
            }
        
        case 'OPEN_CANCELATION_RESERVATION_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'CANCELATION_RESERVATION'
            }
        
        case 'OPEN_REPORT_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'REPORT'
            }
        case 'OPEN_PHONE_MODAL':
            return {
                ...state,
                isOpen: true,
                type:'PHONE'
            }
            
            
        default:
            return state
    }
}
