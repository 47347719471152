// Librerías
import React from 'react';
// Componentes
import Update from '../../../components/BackOffice/Locations/Update';

const Edit = () => {
  return(
    <Update />
  );
}

export default Edit;