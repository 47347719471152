// Librerías
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
// Componentes
import MainContainer from "../Utils/MainContainer";
import ModalAnt from "../Utils/Modal";
import ButtonPrimary from "../../ui/button-primary";
import InputGlobal from "../../ui/input-global";
import axios from "axios";
import moment from "moment";
import hotSale from "../../../assets/static/hot_sale/boton-home-hot-sale-.png";

const View = () => {
  const { id } = useParams();
  const [housing, setHousing] = useState([]);
  const [user, setUser] = useState([]);
  // const history = useHistory();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [progress, setProgress] = useState({});
  const [pricing, setPricing] = useState(false);
  const [datePricing, setDatePricing] = useState("");
  useEffect(() => {
    const url = `${BASE_URL}/backoffice/housing/${id}`;
    AxiosAuth.get(url)
      .then((response) => {
        const hh = response.data;
        setHousing(hh);
        setUser(hh.user);
        setProgress({
          calendar: { number: 15, progres: hh.progress.calendar },
          location: { number: 5, progres: hh.progress.location },
          media: { number: 8, progres: hh.progress.media },
          payment: { number: 13, progres: hh.progress.payment },
          price: { number: 12, progres: hh.progress.price },
          quantities: { number: 4, progres: hh.progress.quantities },
          reservation: { number: 10, progres: hh.progress.reservation },
          rules: { number: 9, progres: hh.progress.rules },
          sanitary: { number: 7, progres: hh.progress.sanitary },
          services: { number: 6, progres: hh.progress.services },
          title: { number: 11, progres: hh.progress.title },
          tos: { number: 14, progres: hh.progress.tos },
          type: { number: 3, progres: hh.progress.type },
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const goodPhotos = (id) => {
    const url = `${BASE_URL}/backoffice/housing/good-photos/${id}`;
    AxiosAuth.get(url)
      .then((response) => {
        setTitle("Puntos agregados");
        setMessage("Puntos agregados correctamente");
      })
      .catch((e) => {
        const error = e.response.data;
        setTitle(`${error.status} - Código de error: ${error.statusCode}`);
        setMessage(`Error: ${error.error}`);
      })
      .finally(() => {
        setModal(true);
      });
  };

  const badPhotos = (id) => {
    const url = `${BASE_URL}/backoffice/housing/bad-photos/${id}`;
    AxiosAuth.get(url)
      .then((response) => {
        setTitle("Puntos substraídos");
        setMessage("Puntos substraídos correctamente");
      })
      .catch((e) => {
        const error = e.response.data;
        setTitle(`${error.status} - Código de error: ${error.statusCode}`);
        setMessage(`Error: ${error.error}`);
      })
      .finally(() => {
        setModal(true);
      });
  };
  const updatePricing = () => {
    const url = `${BASE_URL}/backoffice/update-pricing`;
    const body = {
      housingId: id,
    };
    AxiosAuth.post(url, body)
      .then((response) => {
        setPricing(true);
      })
      .catch((error) => {
        setPricing(false);
      });
    setTimeout(() => {
      setPricing(false);
    }, 3000);
  };

  const updateLimitePrice = () => {
    const url = `${BASE_URL}/housing/pricing`;
    const body = {
      pricing: {
        housingId: id,
        date: datePricing,
      },
    };
    AxiosAuth.post(url, body)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const url = `${BASE_URL}/housing/get-pricing?housingId=${id}`;
    axios.get(url).then((response) => {
      setDatePricing(moment(response.data).format("YYYY-MM-DD"));
    });
  }, []);

  return (
    <MainContainer
      title={`Propiedad: #${id} | ${housing.title} - Status: ${housing.statusPoints} | ${user.firstName} ${user.lastName}`}
    >
      <section className="UserData">
        <div className="UserData__actions">
          <Link to={`transfer/${id}`} className="UserData__actions__btn">
            <p className="UserData__actions__btn__text">Transferir</p>
          </Link>
          <button
            className="UserData__actions__btn"
            onClick={() => goodPhotos(id)}
          >
            Fotos Buenas
          </button>
          <Link to={`status-points/${id}`} className="UserData__actions__btn">
            <p className="UserData__actions__btn__text">Status Points</p>
          </Link>
          <button
            className="UserData__actions__btn"
            onClick={() => badPhotos(id)}
          >
            Fotos Malas
          </button>
          <Link to={`previaje/${id}`} className="UserData__actions__btn">
            <p className="UserData__actions__btn__text">Previaje</p>
          </Link>
        </div>
        <div className="UserData__section">
          <h2 className="UserData__section__title">
            <p className="UserData__section__title__text" style={{ margin: 0 }}>
              Propiedad: {`#${id} - ${housing.title}`}
            </p>
          </h2>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">
              ID (Número único identificador):{" "}
            </p>
            <p className="UserData__dataGroup__content">{id}</p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">User ID: </p>
            <p className="UserData__dataGroup__content">
              {user.id ? user.id : "Sin asignar"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Título: </p>
            <p className="UserData__dataGroup__content">
              {housing.title ? housing.title : "Sin asignar"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Estado: </p>
            <p className="UserData__dataGroup__content">
              <b>{housing.public ? "Publicado" : "Sin publicar / Pausado"}</b>
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Precio Base: </p>
            <p className="UserData__dataGroup__content">
              {housing.basePrice ? `$${housing.basePrice}` : "Sin asignar"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Dirección: </p>
            <p className="UserData__dataGroup__content">
              {housing.adress ? housing.adress : "Sin asignar"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Ubicación: </p>
            <p className="UserData__dataGroup__content">
              {housing.location?.location
                ? housing.location.location
                : "Sin asignar"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Tipo de Propiedad: </p>
            <p className="UserData__dataGroup__content">
              {housing.typeHousingProperty
                ? housing.typeHousingProperty
                : "Sin asignar"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Tipo de Cancelación: </p>
            <p className="UserData__dataGroup__content">
              {housing.cancelation ? housing.cancelation : "Sin asignar"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Eliminado? </p>
            <p className="UserData__dataGroup__content">
              {housing.virtualDelete ? "Si" : "No"}
            </p>
          </div>
        </div>
        <div className="UserData__section">
          <h2 className="UserData__section__title">
            <p className="UserData__section__title__text" style={{ margin: 0 }}>
              Propietario: {`${user.firstName} ${user.lastName}`}:
            </p>
          </h2>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Proveedor: </p>
            <p className="UserData__dataGroup__content">{user.provider}</p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">
              ID (Número único identificador):{" "}
            </p>
            <p className="UserData__dataGroup__content">{user.id}</p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Email: </p>
            <p className="UserData__dataGroup__content">
              {user.email ? user.email : "Cuenta sin email"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Email confirmado: </p>
            <p className="UserData__dataGroup__content">
              {user.emailConfirmed ? "Si" : "No"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">Teléfono / Celular: </p>
            <p className="UserData__dataGroup__content">
              {user.phoneNumber ? user.phoneNumber : "No ingresado aún"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">
              Teléfono / Celular confirmado:{" "}
            </p>
            <p className="UserData__dataGroup__content">
              {user.phoneNumberConfirmed ? "Si" : "No"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">DNI: </p>
            <p className="UserData__dataGroup__content">
              {user.dni ? user.dni : "DNI no ingresado"}
            </p>
          </div>
          <div className="UserData__dataGroup">
            <p className="UserData__dataGroup__title">DNI Verificado: </p>
            <p className="UserData__dataGroup__content">
              {user.dniConfirmed ? "Si" : "No"}
            </p>
          </div>
        </div>
        <div className="UserData__section">
          <h2 className="UserData__section__title">
            <p className="UserData__section__title__text" style={{ margin: 0 }}>
              Progreso de la publicación:{" "}
              {housing.progressPercent ? housing.progressPercent * 100 : "0"}%
            </p>
          </h2>
          {Object.entries(progress).map(([key, value]) => (
            <div className="UserData__dataGroup" key={key}>
              <p className="UserData__dataGroup__title">
                Paso #{value.number} - {key}:
              </p>
              <p className="UserData__dataGroup__content">
                {value.progres ? "Terminado -" : "Inconcluso -"}
                &nbsp;&nbsp;
                <a href={`/edit/${value.number}/${housing.id}`} className="btn">
                  <p className="btn__text">Editar</p>
                </a>
              </p>
            </div>
          ))}
        </div>
        <div className="relative flex flex-col justify-between gap-5 md:flex-row lg:flex-row">
          {housing?.housingPromotions?.length > 0 && (
            <div className="flex items-center h-14 gap-11">
              este alojamiento cuenta con Promoción
              <img src={hotSale} alt="Promo" className="w-12 h-12 " />
            </div>
          )}
          <div className="flex flex-col gap-10 ">
            <InputGlobal
              prefix={"limite para reservar"}
              name={"pricing"}
              value={datePricing}
              type={"date"}
              onChange={(e) => {
                setDatePricing(e.target.value);
              }}
            />
            <ButtonPrimary
              onClick={() => updateLimitePrice()}
              title={"guardar"}
            />
          </div>
        </div>
      </section>

      <ModalAnt
        title={title}
        header={title}
        message={message}
        setVisible={setModal}
        visible={modal}
      />
    </MainContainer>
  );
};

export default View;
