// Librerías
import React from 'react';
// Recursos
import '../../../assets/styles/RentLanding/Content/_easyCharge.sass';

const EasyCharge = () => {
  return(
    <React.Fragment>
      <div className="EasyCharge">
        <div className="EasyCharge__headline">
          <p className="EasyCharge__headline__title">
            ¿Es tu primera vez como alohador?
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EasyCharge;