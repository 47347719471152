// Librerías
import React from "react";
// Recursos
import "../../../assets/styles/AloharForm/Utils/_radio_black_mobile.sass";

const RadioMobile = ({
  disabled,
  id,
  name,
  value,
  title,
  descriptions,
  checked,
  customClickEvent,
  blackText = false,
}) => {
  const textColor = blackText ? "black" : "";
  const setDescriptions = (descriptions) => {
    if (descriptions && descriptions.length > 0) {
      for (var i = 0; i < descriptions.length; i++) {
        return (
          <p className="RadioBlack__container__description">
            {descriptions[i]}
          </p>
        );
      }
    }
  };
  return (
    <React.Fragment>
      <div className={`RadioBlack ${textColor}`}>
        <input
          disabled={disabled}
          id={id}
          value={value}
          onChange={customClickEvent}
          className="RadioBlack__radio"
          type="checkbox"
          checked={checked}
          name={name}
        />
        <label htmlFor={id} className="RadioBlack__container__label">
          <span className={disabled ? "RadioBlack__container__circleDisabled" : "RadioBlack__container__circle"}></span>
          <div className="RadioBlack__container__label__text">
            <p className={disabled ?"RadioBlack__container__titleDisabled": "RadioBlack__container__title"}>{title}</p>
            {setDescriptions(descriptions)}
          </div>
        </label>
      </div>
    </React.Fragment>
  );
};

export default RadioMobile;
