export const caclValuations = (numbers) => {
  if (numbers[0] > 0) {
    const valuationCalc = numbers.reduce(
      (acumulador, numero) => acumulador + numero,
      0
    );

    let suma = 0;

    for (let i = 0; i < valuationCalc.length; i++) {
      suma += Number(valuationCalc[i]);
    }
    return parseInt(suma / numbers.length);
  } else {
    return 0;
  }
};
