import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import esLocale from "moment/locale/es";
import "react-dates/lib/css/_datepicker.css";
import "./calendar-pick.css";
import { useEffect } from "react";
const CalendarPick = ({
  start,
  end,
  setCheckin,
  setCheckout,
  orientation,
  disabledDays,
  startDatePlaceholderText,
  endDatePlaceholderText,
  disabled,
}) => {
  moment.updateLocale("es", esLocale);
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(start ? start : null);
  const [endDate, setEndDate] = useState(end ? end : null);

  const hundleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate !== null) {
      setCheckin(startDate.format("Y-MM-D"));
    } else {
      setCheckin(null);
    }

    if (endDate !== null) {
      setCheckout(endDate.format("Y-MM-D"));
    } else {
      setCheckout(null);
    }
  };

  const isOutsideRange = () => false;
  const disabledDates = (day) => {
    if (disabledDays) {
      const ifBlock = disabledDays.filter((d) => {
        const start = d.checkin;
        const end = d.checkout;
        return day.isBetween(start, end, "days", "[]");
      });
      return ifBlock.length !== 0;
    }
    return false;
  };

  return (
    <div className="relative calendarPick " id="select_date">
      <DateRangePicker
        disabled={disabled}
        orientation={orientation ? orientation : "vertical"}
        startDate={startDate}
        noBorder
        hideKeyboardShortcutsPanel
        startDateId="start_date_id"
        startDatePlaceholderText={
          startDatePlaceholderText ? startDatePlaceholderText : "Desde"
        }
        endDatePlaceholderText={
          endDatePlaceholderText ? endDatePlaceholderText : "Hasta"
        }
        endDate={endDate}
        showClearDates={true}
        endDateId="end_date_id"
        onDatesChange={({ startDate, endDate }) =>
          hundleDateChange(startDate, endDate)
        }
        isDayBlocked={disabledDates}
        isOutsideRange={isOutsideRange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      />
    </div>
  );
};

export default CalendarPick;
