// Librerías
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import { cleanRedirectUser, redirectUser } from "../../store/user/actions";
import { BASE_URL } from "../../Helpers";
import axios from "axios";
import {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
} from "../../store/modal/actions";
// Componentes
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
// Modales
import ModalWrapper from "../../components/Modals/ModalWrapper";
import LoginModal from "../../components/Modals/LoginModal";
import RegisterModal from "../../components/Modals/RegisterModal";
import RecoverModal from "../../components/Modals/RecoverModal";
// Recursos
import { loginUser } from "../../store/user/actions";
import AuthService from "../../services/auth";
import facebook_login from "../../assets/static/Modals/facebook_login.png";
import google_login from "../../assets/static/Modals/google_login.png";
import "../../assets/styles/Users/_login.sass";
import loadingGif from "../../assets/static/loading.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import eye from "../../assets/static/Icons/eye.svg";
import useModal from "antd/lib/modal/useModal";

const LoginContainer = () => {
  const loginState = useSelector((state) => state.userReducer, shallowEqual);
  const [errorLogin, setErrorLogin] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState("");
  const [errorInput, setErrorInput] = useState("");
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showSendedEmail, setShowSendedEmail] = useState(false);
  const [socialLogin, setSocialLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const query = queryString.parse(window.location.search);
  const history = useHistory();
  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const [openModal, setOpenModal] = useState(false);
  const [closeModal] = useModal(false);

  function selectModal(modalType) {
    switch (modalType) {
      case "LOGIN":
        return (
          <ModalWrapper key="login_modal">
            <LoginModal />
          </ModalWrapper>
        );
      case "REGISTER":
        return (
          <ModalWrapper key="register_modal">
            <RegisterModal />
          </ModalWrapper>
        );
      case "RECOVER":
        return (
          <ModalWrapper key="recover_modal">
            <RecoverModal />
          </ModalWrapper>
        );
      default:
        return "";
    }
  }

  useEffect(() => {
    /* Social login callback*/
    const { token, refresh_token, firstName, lastName, avatar, id } = query;

    if ((token && refresh_token && firstName && lastName && avatar, id)) {
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: token,
          refresh_token: refresh_token,
          avatar: avatar,
          firstName: firstName,
          lastName: lastName,
          login: true,
          id: id,
        })
      );

      dispatch(
        loginUser({ token, refresh_token, firstName, lastName, avatar, id })
      );

      if (loginState.redirect) {
        dispatch(cleanRedirectUser());
        history.push(loginState.redirect);
        // window.location.replace(`${loginState.redirect}`)
      } else {
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }
    }

    // if(query['accessToken']){
    //   const accessToken = query['accessToken']
    //   dispatch(loginUser(accessToken))
    //   localStorage.setItem("userData", JSON.stringify({
    //     accessToken:accessToken,
    //     login:true
    //   }))
    //   setTimeout(() => {

    //     history.push("/");
    //   }, 1000);

    // }else{
    //   setSocialLogin(false)
    // }
  });

  const handleGoogleSingUp = () => {
    window.location = `${BASE_URL}/auth/google/`;
  };

  const handleFacebookSingUp = () => {
    window.location = `${BASE_URL}/auth/facebook/`;
  };

  const ifValidate = (input) => {
    if (input === "email" || input === undefined) {
      if (email.length < 5) {
        return { v: false, e: "El email debe ser válido", i: "email" };
      }
      let re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.length > 5) {
        if (!re.test(email)) {
          return { v: false, e: "El email es inválido", i: "email" };
        }
      }
    }
    if (input === "password" || input === undefined) {
      let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      if (!re.test(password)) {
        return {
          v: false,
          e: "La contraseña debe tener al menos 8 cáracteres, una mayuscula y un número",
          i: "password",
        };
      }
    }
    return { v: true, e: "", i: "" };
  };

  const handleValidate = (input) => {
    const _ifValidate = ifValidate(input);
    setValidate(_ifValidate.v);
    if (!_ifValidate.v) {
      setErrorInput(_ifValidate.i);
      setError(_ifValidate.e);
    }
  };

  const handleReSendEmail = () => {
    axios
      .post(`${BASE_URL}/auth/send_confirm_email`, {
        email: email,
      })
      .then(function (response) {
        setShowSendedEmail(true);
        setShowSendEmail(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = (evt) => {
    setShowSendEmail(false);
    evt.preventDefault();

    const _ifValidate = ifValidate();
    setValidate(_ifValidate.v);
    if (_ifValidate.v) {
      AuthService.login(email, password)
        .then(function (response) {
          const { firstName, lastName, avatar, email,id } =
            response.data.data.user;
          const { refresh_token, token } = response.data.data.payload;

          dispatch(
            loginUser({
              token,
              refresh_token,
              firstName,
              lastName,
              avatar,
              email,
              id,
            })
          );

          if (loginState.redirect) {
            dispatch(cleanRedirectUser());
            history.push(loginState.redirect);
            // window.location.replace(`${loginState.redirect}`)
          } else {
            setTimeout(() => {
              history.push("/");
            }, 100);
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            setError("Usuario y contraseña incorrectos.");
          }
          if (error.response.status === 403) {
            setError("Cuenta sin confirmar. Revise su bandeja de SPAM.");
            setShowSendEmail(true);
          }
          if (error.response.status !== 403 && error.response.status !== 401) {
            setError(error.response);
          }
        });
    } else {
      setErrorInput(_ifValidate.i);
      setError(_ifValidate.e);
    }
  };
  return (
    <React.Fragment>
      <Navbar />

      <div className="Login">
        {/* socialLogin ? 
          <center className="Login__gif">
            <img src={loadingGif} alt="loading"/>
          </center>
        : */}
        <form className="Login__form" onSubmit={handleSubmit}>
          <div className="Login__form__header">
            <h2 className="Login__form__header__title">Iniciar sesión</h2>
          </div>
          <div className="Login__form__body">
            {error ? <ErrorAlert message={error} /> : ""}
            {showSendEmail ? (
              <div className="fake_link" onClick={handleReSendEmail}>
                Reenviar mail de confirmación
              </div>
            ) : (
              ""
            )}
            <div className="Login__form__body__social_container">
              <img
                alt="google login"
                onClick={() => handleGoogleSingUp()}
                src={google_login}
                className="social_login"
              />
              <img
                alt="facebook login"
                onClick={() => handleFacebookSingUp()}
                src={facebook_login}
                className="social_login"
              />
            </div>
            <div className="Login__form__body__separator">
              <hr />
              <div className="Login__form__body__separator__text">
                o ingresa con tu email
              </div>
              <hr />
            </div>
            <div className="Login__form__body__inputcontainer">
              <label
                htmlFor="email"
                className="Login__form__body__inputcontainer__label"
              >
                EMAIL
              </label>
              <input
                id="email"
                type="email"
                placeholder="Escribe tu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => handleValidate("email")}
                className={
                  errorInput === "email"
                    ? "Login__form__body__inputcontainer__input error"
                    : "Login__form__body__inputcontainer__input"
                }
              />
            </div>
            <div className="Login__form__body__inputcontainer">
              <label
                htmlFor="password"
                className="Login__form__body__inputcontainer__label"
              >
                CONTRASEÑA
              </label>
              <div className="Login__form__body__inputcontainer__passContainer">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Escribe tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() => handleValidate("password")}
                  className={
                    errorInput === "password"
                      ? "Login__form__body__inputcontainer__input error"
                      : "Login__form__body__inputcontainer__input"
                  }
                />
                <i
                  style={showPassword ? { color: "#FF4E00" } : {}}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <img src={eye} />
                </i>
              </div>
            </div>
            <div className="Login__form__body__forgotpassword">
              <button
                className="Login__form__body__forgotpassword__btn"
                onClick={() => dispatch(openRecoverModal({ closeModal }))}
              >
                ¿Olvidaste tu contraseña?
              </button>
            </div>
            <div className="Login__form__body__forgotpassword">
              <button
                className="Login__form__body__forgotpassword__btn"
                onClick={() => (window.location.href = "/register")}
              >
                Registrarme
              </button>
            </div>
            <button type="submit" className="Login__form__body__btn">
              Ingresar
            </button>
          </div>
        </form>

        {modalState.isOpen ? selectModal(modalState.type) : ""}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default connect(null, {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
})(LoginContainer);
