// Librerías
import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { connect, useDispatch } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
import AxiosAuth from "../../../services/requestApi";
// Datepicker
import esLocale from "moment/locale/es";
import moment from "moment";
// Componentes

import ProgressBar from "../Utils/ProgressBar";
import { DateRangePicker } from "react-dates";
import ConfirmModal from "../../Modals/ConfirmModal";
import ModalWrapper from "../../Modals/ModalWrapper";
// Recursos
import { BASE_URL } from "../../../Helpers";
import { withRouter } from "react-router-dom";
import CalendarSincronize from "../../calendar-sincronize";
import "react-dates/lib/css/_datepicker.css";
import "../../../assets/styles/AloharForm/Steps/_calendar.sass";
import "../../../assets/styles/MainSearch/daterange_black.sass";
import ErrorAlert from "../../Alerts/ErrorAlert";
import axios from "axios";
import ButtonPrimary from "../../ui/button-primary";

const available = {
  option_1: {
    id: "available_1",
    value: "private",
    name: "avilability",
    title: "Bloqueado",
  },
  option_2: {
    id: "available_2",
    value: "share",
    name: "avilability",
    title: "Valor Noche",
  },
  option_3: {
    id: "hold",
    value: "hold",
    name: "hold",
    title: "Registrar reserva",
  },
};

class Calendar extends Component {
  /*
    Este componente tiene un problema. Los rangos de fecha no se condicen 
    con el valor de la fecha.
    Por lo cual hay que hacer un fix entre el valor que se carga 
    en el componenente
    y el valor que se guarda para ser enviado al servidor.

    Los rangos de fecha marcados en el compoennte difieren en 1 dia
  */

  constructor(props) {
    super(props);

    moment.updateLocale("es", esLocale);
    const basePrice = this.props.bodyParameters.basePrice;

    const fixCalendarState = this.props.bodyParameters.calendar.map(
      (calendar) => {
        const fixEndDate = new Date(calendar.end);
        fixEndDate.setDate(fixEndDate.getDate());
        let dd = fixEndDate.getDate();
        let mm = fixEndDate.getMonth() + 1;
        const yyyy = fixEndDate.getFullYear();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        return { ...calendar, end: `${yyyy}-${mm}-${dd}` };
      }
    );

    this.state = {
      calendar: fixCalendarState,
      sidebar: "Calendar__sidebar",
      errors: {},
      start_date: null,
      end_date: null,
      new_event: null,
      validateSideBar: false,
      validateSideBarError: "",
      basePrice: basePrice,
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate,
      initialCalendarValues: this.props.bodyParameters.calendar,
      // Date Pikcer Range
      startDate: null,
      endDate: null,
      orientation: "horizontal",
      initDataFromURL: false,
      amount: 0,
      typeEvent: false,
      openModal: false,
      modalType: "",
      eventToDelete: null,
      importCalendarModal: false,
      linkImportCalendar: "",
      linkExportCalendar: "",
      linkDescriptionCalendar: "",
      housingId: "",
      calendarImports: [],
      userRole: "",
      error: "",
      fechasBloqueadas: this.obtenerFechasBloqueadas(),
    };

    this.calendarRef = React.createRef();
    this.from = React.createRef();
    this.to = React.createRef();
    this.blocked = React.createRef();
    this.availableDates = React.createRef();
    this.registerReservation = React.createRef();
    this.amount = React.createRef();
    this.handleBasePriceShow = this.handleBasePriceShow.bind(this);
    this.props.calendarStep({ calendar: this.props.bodyParameters.calendar });
    this.handleExit = this.handleExit.bind(this);
  }



  onChangeImportCalendar = (event) => {};
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.calendar !== this.state.calendar &&
      !isNaN(this.props.count_validate)
    ) {
      this.props.calendarStep({ calendar: this.state.calendar });
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
    if (startDate && endDate) {
      this.setState({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      });
    }
  };

  openSidebar = () => {
    this.setState({ sidebar: "Calendar__sidebar activated" });
  };
  DateRangeChecker({ param, startDate, endDate }) {
    const fechaInicioRango = this.state.calendar[0].start;
    const fechaFinRango = this.state.calendar[0].end;
    const fechaInicioParam = param[0].start;
    const fechaFinParam = param[0].end;
    if (
      (fechaInicioParam.getTime() === fechaInicioRango.getTime() &&
        fechaFinParam.getTime() === fechaFinRango.getTime()) ||
      (fechaInicioRango <= fechaInicioParam && fechaFinParam <= fechaFinRango)
    ) {
      return true;
    }

    return false;
  }
  cancelEvent = () => {
    const new_event = this.state.new_event;
    if (new_event) {
      new_event.remove();
      this.setState({ new_event: null });
    }
    this.handleBasePriceShow();
    this.closeSidebar();
  };

  closeSidebar = () => {
    this.setState({ sidebar: "Calendar__sidebar" });
  };

  handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    let from = this.from;
    let to = this.to;

    const new_event = calendarApi.addEvent({
      title: "Pendiente",
      amount: selectInfo.amount,
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      allDay: selectInfo.allDay,
    });

    const fixStartDate = new Date(selectInfo.startStr);
    fixStartDate.setDate(fixStartDate.getDate() + 2);
    let dd = fixStartDate.getDate();
    let mm = fixStartDate.getMonth() + 1;
    let yyyy = fixStartDate.getFullYear();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const startStr = `${yyyy}-${mm}-${dd}`;

    const fixEndDate = new Date(selectInfo.endStr);
    fixEndDate.setDate(fixEndDate.getDate());
    dd = fixEndDate.getDate();
    mm = fixEndDate.getMonth() + 1;
    yyyy = fixEndDate.getFullYear();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const endStr = `${yyyy}-${mm}-${dd}`;

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    this.setState({
      start_date: startStr,
      end_date: endStr,
      new_event: new_event,
    });

    // Restamos un día porque el plugin funciona de esta manera,
    // termina como tiene que ser la selección

    this.openSidebar();
    from.current.value = moment(selectInfo.startStr)
      .utc(true)
      .subtract(1, "day")
      .format("DD/MM/YYYY");
    to.current.value = moment(selectInfo.endStr).utc(true).format("DD/MM/YYYY");
  };
  async getDates() {
    AxiosAuth.get(`${BASE_URL}/housing/public/13/${this.state.housingId}`).then(
      (data) => {
        this.props.calendarStep({ calendar: data.data.calendar });
        this.obtenerFechasBloqueadas(data.data.calendar);
        return data.status === 201;
      }
    );
  }
  async sendData(url, bodyParameters) {
    const send = await AxiosAuth.post(url, bodyParameters)
      .then((data) => {
        this.getDates();

        return data.status === 201;
      })
      .catch((e) => {
        if (e.response) {
          this.setState({
            statusCode: e.response.data.statusCode,
            message: e.response.data.message,
          });
        }
        return false;
      });
    window.location.reload();
    return send;
  }

  async handleSave() {
    const bodyParameters = this.props.steps[15].bodyParameters;

    bodyParameters.calendar = this.state.calendar;
    bodyParameters.calendar = bodyParameters.calendar.filter(
      (c) => c.typeHousingCalendar !== "hold"
    );
    bodyParameters.calendar = bodyParameters.calendar.filter(
      (c) => c.typeHousingCalendar !== "calendarImport"
    );
    bodyParameters.calendar = bodyParameters.calendar.filter(
      (c) => c.typeHousingCalendar !== "season"
    );

    const url = this.props.steps[15].endopoit;
    bodyParameters.id = JSON.parse(this.state.housingId);

    const send = await this.sendData(url, bodyParameters);
  }

  handleValidateSideBar = () => {
    let validate = false;
    let isMobile = false;

    if (this.state.startDate && this.state.endDate) {
      /* Version mobile el evento se crea desde el boton guardar sin usar el FullCalendar*/
      isMobile = true;
    }

    if (this.blocked.current.checked) validate = true;
    if (
      this.state.userRole.role === "admin" &&
      this.registerReservation.current.checked &&
      parseInt(this.amount.current.value) > 0
    ) {
      validate = true;
    } else {
      this.setState({
        validateSideBarError: "Debe indicar que quiere registrar la reserva",
      });
    }
    if (
      this.availableDates.current.checked &&
      parseInt(this.amount.current.value) > 0
    ) {
      validate = true;
    } else {
      this.setState({
        validateSideBarError: "Debe indicar un precio para el valor noche",
      });
    }

    if (!this.availableDates.current.checked && !this.blocked.current.checked) {
      this.setState({
        validateSideBarError: "Debe seleccionar un tipo de disponibilidad",
      });
    }

    this.setState({ validateSideBar: validate });
    if (validate) {
      this.saveDate(isMobile);
      this.setState({ validateSideBarError: "" });
    }
  };

  openSidebar = () => {
    this.setState({ sidebar: "Calendar__sidebar activated" });
  };

  checkDateOverlap(existingObjArray, newObj) {
    const datesBlock2 = existingObjArray.map((existingObj) => {
      const start = moment(existingObj.start);
      const end = moment(existingObj.end);
      const datesInRange = [];

      while (start <= end) {
        // Formatear la fecha a "YYYY-MM-DD" usando moment
        const formattedDate = start.format("YYYY-MM-DD");
        datesInRange.push(formattedDate);

        start.add(1, "day");
      }

      return datesInRange;
    });

    const newStartDate = moment(newObj.start);
    const newEndDate = moment(newObj.end);
    const datesInRangeNewObj = [];

    while (newStartDate <= newEndDate) {
      const formattedDate = newStartDate.format("YYYY-MM-DD");
      datesInRangeNewObj.push(formattedDate);

      newStartDate.add(1, "day");
    }

    const blockDate = datesBlock2.some((datesInRange) => {
      return (
        datesInRange.includes(newObj.start) ||
        datesInRange.includes(newObj.end) ||
        datesInRangeNewObj.some((date) => datesInRange.includes(date))
      );
    });

    return blockDate;
  }

  saveDate = (isMobile) => {
    const from = isMobile
      ? this.state.start_date
      : moment(new Date(this.state.start_date)).utc(true).format("YYYY-MM-DD");
    const to = isMobile
      ? moment(this.state.end_date)
          .utc(true)
          .add(1, "days")
          .format("YYYY-MM-DD")
      : moment(new Date(this.state.end_date))
          .utc(true)
          .add(1, "days")
          .format("YYYY-MM-DD");
    const to_fixed = moment(this.state.end_date).utc(true).format("YYYY-MM-DD");

    const blocked = this.blocked.current.checked;
    const availableDates = this.availableDates.current.checked;
    const registerReservation =
      this.state.userRole.role === "admin" &&
      this.registerReservation.current.checked;
    let amount = this.amount.current.value;
    const title = blocked ? "Bloqueado" : `Valor Noche ($${amount})`;
    const backgroundColor = registerReservation
      ? "rgba(0, 0, 255, .3)"
      : blocked
      ? "red"
      : "green";

    const borderColor = registerReservation
      ? "rgba(0, 0, 255, .3)"
      : blocked
      ? "red"
      : "green";

    if (blocked) amount = "";

    const event = {
      title,
      start: from,
      end: to,
      isBlocked: blocked,
      isAvailable: availableDates,
      isHold: registerReservation,
      amount: amount,
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      typeHousingCalendar:
        registerReservation || "season"
          ? "Hold"
          : blocked
          ? "block"
          : `available`,
    };

    let verifyDates;

    if (this.state.calendar && this.state.calendar.length > 0) {
      const calendarBlockAvailable = this.state.calendar.filter((e) => {
        if (event.typeHousingCalendar === "block") {
          return (
            e.typeHousingCalendar !== "season " ||
            e.typeHousingCalendar !== "Hold " ||
            e.typeHousingCalendar !== "available "
          );
        } else if (event.typeHousingCalendar === "available") {
          return (
            e.typeHousingCalendar !== "season " ||
            e.typeHousingCalendar !== "Hold " ||
            e.typeHousingCalendar !== "block "
          );
        }
      });
      verifyDates = this.checkDateOverlap(calendarBlockAvailable, event);
    }
    if (verifyDates) {
      this.setState({ error: true });
    } else {
      let new_event;
      if (isMobile) {
        let calendarApi = this.calendarRef.current.getApi();
        new_event = calendarApi.addEvent(event);
        this.setState({
          startDate: null,
          endDate: null,
          new_event,
        });
        /* Fix calendar date end to send server*/
        event.end = to_fixed;
      } else {
        new_event = this.state.new_event;
      }

      new_event.setProp("title", title);
      if (registerReservation) {
        new_event.setProp("backgroundColor", "rgba(0, 0, 255, .3)");
        new_event.setProp("borderColor", "rgba(0, 0, 255, .3)");
      } else {
        if (blocked) {
          new_event.setProp("backgroundColor", "red");
          new_event.setProp("borderColor", "red");
        } else {
          new_event.setProp("backgroundColor", "green");
          new_event.setProp("borderColor", "green");
        }
      }

      if (registerReservation) {
        this.saveRegisterReservation();
      } else {
        this.setState(
          {
            calendar: [...this.state.calendar, event],
          },
          () => {
            /* Limpiamos los datos para evitar duplicar con multiples clicks*/
            this.blocked.current.checked = false;
            this.availableDates.current.checked = false;
            this.setState({ validateSideBar: false }, () => {
              this.handleSave();
              /*Envio los datos al servidor */
            });
          }
        );

        this.handleBasePriceShow();
        this.setState({ error: false });
      }
    }
  };
  saveRegisterReservation() {
    const { id } = this.props.match.params;
    const body = {
      calendar: {
        housingId: this.props.match.params.id,
        calendar: [
          {
            startDate: moment(this.state.start_date)
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            endDate: this.state.end_date,
          },
        ],
        typeHousingCalendar: "hold",
      },
    };
    const url = `${BASE_URL}/housing/put-calendar`;
    AxiosAuth.put(url, body)
      .then((response) => {
        // window.location.reload();
      })
      .catch((error) => {});
  }
  handleExit = () => {
    this.props.history.goBack();
  };

  handleBasePriceShow = () => {
    const dias = document.getElementsByClassName("fc-daygrid-day-events");
    Array.prototype.map.call(dias, (dia) => {
      const casillero = dia.parentNode;
      if (dia.attributeStyleMap && dia.attributeStyleMap.size > 0) {
        casillero.getElementsByTagName("SPAN")[0].classList.add("displayNone");
      } else {
        casillero
          .getElementsByTagName("SPAN")[0]
          .classList.remove("displayNone");
      }
    });
  };

  deleteEvent = () => {
    this.setState({ openModal: false, modalType: "" });
    const eventToDelete = this.state.eventToDelete;
    const start = moment(eventToDelete.event._instance.range.start)
      .utc(true)
      .format("YYYY-MM-DD");
    const end = moment(eventToDelete.event._instance.range.end)
      .utc(true)
      .format("YYYY-MM-DD");
    const newState = this.state.calendar.filter(
      (c) => c.start !== start && c.end !== end
    );
    this.setState({ calendar: newState }, () => {
      this.props.calendarStep({ calendar: this.state.calendar });
      this.handleSave();
    });
    eventToDelete.event.remove();
    this.setState({ eventToDelete: null });
  };

  handleEventClick = (clickInfo) => {
    if (this.state.userRole.role === "admin") {
      this.setState({
        openModal: true,
        modalType: "ADMIN",
        eventToDelete: clickInfo,
      });
    } else {
      if (
        !clickInfo.event._def.extendedProps.typeHousingCalendar.includes(
          "season"
        ) &&
        !clickInfo.event._def.extendedProps.typeHousingCalendar.includes("hold")
      ) {
        this.setState({
          openModal: true,
          modalType: "CONFIRM",
          eventToDelete: clickInfo,
        });
      }
    }
    // else {
    //   if (
    //     !clickInfo.event._def.extendedProps.typeHousingCalendar.includes(
    //       "season"
    //     ) &&
    //     !clickInfo.event._def.extendedProps.typeHousingCalendar.includes("hold")
    //   ) {
    //     this.setState({
    //       openModal: true,
    //       modalType: "CONFIRM",
    //       eventToDelete: clickInfo,
    //     });
    //   }
    // }
  };

  handleCancelDeleteEvent = () => {
    this.setState({ openModal: false, modalType: "" });
  };

  deleteConfirmReservation = () => {
    const eventToDelete = this.state.eventToDelete;
    const start = moment(eventToDelete.event._instance.range.start)
      .utc(true)
      .format("YYYY-MM-DD");

    const end = moment(eventToDelete.event._instance.range.end)
      .utc(true)
      .format("YYYY-MM-DD");

    const addDay = moment(start).add(1, "day").format("YYYY-MM-DD");

    const url = `${BASE_URL}/housing/delete-dates-calendar`;
    const { id } = this.props.match.params;
    const body = {
      calendar: {
        housingId: id,
        calendar: [
          {
            startDate: addDay,
            endDate: end,
          },
        ],
      },
    };
    AxiosAuth.delete(url, { data: body })
      .then((response) => {
        this.setState({ openModal: false, modalType: "" });
        this.props.calendarStep({ calendar: this.state.calendar, id: id });
        window.location.reload();
      })
      .catch((error) => {});
  };
  selectModal = (modalType) => {
    switch (modalType) {
      case "CONFIRM":
        return (
          <ModalWrapper key="success_modal">
            <ConfirmModal
              confirmAction={this.deleteEvent}
              title={"Confirme borrado"}
              text={
                "Estás por borrar un elemento del calendario. ¿Estás seguro?"
              }
              confirmButtonText={"Confirmar"}
              cancelAction={this.handleCancelDeleteEvent}
            />
          </ModalWrapper>
        );

      case "ADMIN":
        return (
          <ModalWrapper key="success_modal">
            <ConfirmModal
              confirmAction={this.deleteConfirmReservation}
              title={"Confirme borrado"}
              text={"Estás por borrar una reserva registrada ¿Estás seguro?"}
              confirmButtonText={"Confirmar"}
              cancelAction={this.handleCancelDeleteEvent}
            />
          </ModalWrapper>
        );

      default:
        return "";
    }
  };

  handleInputChange = (name, value) => {
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ housingId: id });
    }
    if (this.state.modeEdit) {
      this.props.restart();
    }
    const userRole = JSON.parse(window.localStorage.getItem("role"));
    this.setState({ userRole: userRole });
  }
  isDayBlocked = (day) => {
    return this.state.fechasBloqueadas.includes(day.format("YYYY-MM-DD"));
  };

  obtenerFechasBloqueadas = (calendar) => {
    const fechasObjeto = this.props.bodyParameters.calendar;

    // Obtener todas las fechas intermedias
    const fechasBloqueadas = fechasObjeto
      .filter((dateExcludes) => dateExcludes.typeHousingCalendar !== "season")
      .reduce((acc, fecha) => {
        const startDate = moment(fecha.start);
        const endDate = moment(fecha.end);
        // Agregar la fecha de inicio y fin
        acc.add(startDate.format("YYYY-MM-DD"));
        acc.add(endDate.format("YYYY-MM-DD"));
        // Agregar las fechas intermedias
        const daysDiff = endDate.diff(startDate, "days");
        for (let i = 1; i < daysDiff; i++) {
          acc.add(startDate.clone().add(i, "days").format("YYYY-MM-DD"));
        }

        return acc;
      }, new Set());

    // Convertir el conjunto a un array
    return Array.from(fechasBloqueadas);
  };

  render() {
    return (
      <React.Fragment>
        <div className="calendarResponsive">
          <div className="calendarResponsive__progresBar">
            {this.state.modeEdit ? (
              ""
            ) : (
              <ProgressBar
                title={"CALENDARIO"}
                stepAt={"Paso 3: Toques finales"}
              />
            )}
          </div>
          <div className="calendarResponsive__container">
            <div className="calendarResponsive__container__Calendar">
              <div className="calendarResponsive__container__Calendar__titles">
                <h1 className="calendarResponsive__container__Calendar__titles__title">
                  Calendario
                </h1>
                <p className="calendarResponsive__container__Calendar__titles__description">
                  El calendario te permite tener ordenado las reservas y llegada
                  de los huéspedes, ajustar y modificar precios según el día o
                  la estación para obtener el mayor rédito a tu espacio.
                  Seleccionando un periodo también podes bloquear los días que
                  prefieras no alquilar tu espacio.
                </p>
              </div>
              {/* aca */}
              <div className="calendarResponsive__container__Calendar__sidebar activated">
                <div className="calendarResponsive__container__Calendar__sidebar__bar">
                  <div className="calendarResponsive__container__Calendar__sidebar__bar__container">
                    <div className="calendarResponsive__container__Calendar__sidebar__bar__container__dates">
                      <h2 className="pt-8 calendarResponsive__container__Calendar__sidebar__bar__container__title">
                        Seleccionar fechas
                      </h2>
                      <div className="flex flex-col py-8 calendarResponsive__container__Calendar__sidebar__bar__container__price__ccontainer">
                        {/* <div className="calendarResponsive__container__Calendar__sidebar__bar__container__price__ccontainer__mobile DateRangeBlack"> */}
                        <DateRangePicker
                          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                          startDateId={this.state.startDateId} // PropTypes.string.isRequired,
                          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                          endDateId={this.state.endDateId} // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) =>
                            this.onDatesChange({ startDate, endDate })
                          } // PropTypes.func.isRequired,
                          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={(focusedInput) =>
                            this.setState({ focusedInput })
                          } // PropTypes.func.isRequired,
                          startDatePlaceholderText={"Desde"}
                          endDatePlaceholderText={"Hasta"}
                          orientation={"horizontal"}
                          numberOfMonths={1}
                          noBorder
                          hideKeyboardShortcutsPanel
                          small
                          showClearDates={
                            this.state.role === "admin " ? false : true
                          }
                          isDayBlocked={this.isDayBlocked} // Agrega esta línea
                          displayFormat={"DD MMM YYYY"}
                          className="DateRangeBlack"
                        />
                        {/* </div> */}

                        <div className="absolute opacity-0 -left-full -top-full calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer">
                          <label className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer__label">
                            Desde
                          </label>
                          <input
                            className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer__input"
                            name="date_from"
                            id="date_from"
                            type="text"
                            style={{ borderRight: "none" }}
                            disabled
                            ref={this.from}
                            placeholder="-"
                          />
                        </div>
                        <div className="absolute opacity-0 -left-full -top-full calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer">
                          <label className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer__label">
                            Hasta
                          </label>
                          <input
                            className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer__input"
                            name="date_to"
                            id="date_to"
                            type="text"
                            style={{ borderLeft: "none" }}
                            disabled
                            placeholder="-"
                            ref={this.to}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle">
                      <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__radiobuttons ">
                        <h2 className="calendarResponsive__container__Calendar__sidebar__bar__container__title">
                          Disponibilidad
                        </h2>

                        <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer">
                          <label
                            htmlFor={available.option_1.id}
                            className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label"
                          >
                            <input
                              id={available.option_1.id}
                              ref={this.blocked}
                              className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__inputToggle"
                              type="radio"
                              name={available.option_1.name}
                              value={available.option_1.value}
                            />
                            <span className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__slider"></span>
                            <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__text">
                              <p className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__text__title">
                                {available.option_1.title}
                              </p>
                            </div>
                          </label>
                        </div>

                        <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer">
                          <label
                            htmlFor={available.option_2.id}
                            className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label"
                          >
                            <input
                              id={available.option_2.id}
                              ref={this.availableDates}
                              className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__inputToggle"
                              type="radio"
                              name={available.option_2.name}
                              value={available.option_2.value}
                            />
                            {/* <ToggleButtons
                            id={ available.option_2.id }
                            ref={ this.availableDates }
                            name={ available.option_2.name } 
                            value={available.option_2.value}
                          /> */}
                            <span className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__slider"></span>
                            <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__text">
                              <p className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__text__title">
                                {available.option_2.title}
                              </p>
                            </div>
                          </label>
                        </div>
                        {this.state.userRole.role === "admin" && (
                          <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer">
                            <label
                              htmlFor={available.option_3.id}
                              className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label"
                            >
                              <input
                                id={available.option_3.id}
                                ref={this.registerReservation}
                                className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__inputToggle"
                                type="radio"
                                name={available.option_3.name}
                                value={available.option_3.value}
                              />
                              <span className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__slider"></span>
                              <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__text">
                                <p className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__toogleContainer__label__text__title">
                                  {available.option_3.title}
                                </p>
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="calendarResponsive__container__Calendar__sidebar__bar__container__containerInputToggle__price">
                        <h2 className="calendarResponsive__container__Calendar__sidebar__bar__container__title">
                          Precio
                        </h2>
                        <div className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer">
                          <label
                            className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer__label"
                            htmlFor="amount_per_night"
                          >
                            Precio por Noche
                          </label>
                          <span className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer__prefix">
                            $
                          </span>
                          <input
                            className="calendarResponsive__container__Calendar__sidebar__bar__container__price__inputContainer__input"
                            name="title"
                            id="amount_per_night"
                            type="number"
                            ref={this.amount}
                            defaultValue={this.state.basePrice}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.error && (
                      <div style={{ marginTop: "20px" }}>
                        <ErrorAlert
                          message={
                            "las fechas ingresadas ya existen en el calendario"
                          }
                        />
                      </div>
                    )}
                    <p className="calendarResponsive__container__Calendar__sidebar__bar__container__info">
                      Nota: Recordá que por cada cambio o ajuste que hagas sobre
                      el calendario, debes presionar el botón de guardar en cada
                      cambio que desees realizar
                    </p>

                    <div className="calendarResponsive__containerCalendar__buttons">
                      <button
                        className="calendarResponsive__container__Calendar__btn-secondary"
                        onClick={this.cancelEvent}
                      >
                        Cancelar
                      </button>
                      {this.state.validateSideBarError}
                      <button
                        className="calendarResponsive__container__Calendar__btn-primary"
                        onClick={this.handleValidateSideBar}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ButtonPrimary
                className={"my-8"}
                title={"Precios automaticos"}
                width={"100%"}
                onClick={() => {
                  window.location.href = `app/pricing/${this.state.housingId}`;
                }}
              />
              {this.state.modeEdit && (
                <CalendarSincronize housingId={this.props.match.params.id} />
              )}
            </div>
            <div className="calendarResponsive__containerCalendar">
              <div className="calendarResponsive__containerCalendar__fullcalendar">
                <div style={{ width:'130%', height:600, marginBottom:-160}}>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  locale="MX"
                  headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "",
                  }}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  selectOverlap={true}
                  dayMaxEvents={true}
                  initialEvents={this.state.initialCalendarValues}
                  select={this.handleDateSelect}
                  eventClick={this.handleEventClick}
                  displayEventTime={false}
                  viewDidMount={() => {
                    setTimeout(() => {
                      this.handleBasePriceShow();
                    }, 100);
                  }}
                  validRange={{
                    start: moment().utc(true).format("YYYY-MM-DD"),
                  }}
                  eventsSet={this.handleBasePriceShow}
                  dayCellDidMount={(dayRenderInfo) => {
                    return dayRenderInfo.el.childNodes[0].insertAdjacentHTML(
                      "beforeend",
                      `<span class="basePriceCalendar">
                  $${this.state.basePrice} 
                  </span>`
                    );
                  }}
                  ref={this.calendarRef}
                />
                </div>

                <div className="calendarResponsive__containerCalendar__fullcalendar__buttons">
                  <button
                    className="calendarResponsive__containerCalendar__fullcalendar__buttons__btn"
                    onClick={this.handleExit}
                  >
                    Volver
                  </button>
                </div>
              </div>
              {/* <div className={ this.state.sidebar } > */}
            </div>
          </div>
        </div>
        {this.state.openModal ? this.selectModal(this.state.modalType) : ""}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};
// export default withRouter(EditAloharPage);
export default withRouter(
  connect(mapStateToProps, aloharFormActions)(Calendar)
);
