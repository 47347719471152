// Librerías
import React from 'react';
// Recursos
import '../../../assets/styles/RentLanding/Content/_easyChargeDescription.sass';

const EasyChargeDescription = () => {
  return(
    <React.Fragment>
      <div className="EasyChargeDescription">
        <div className="EasyChargeDescription__description">
          <div className="EasyChargeDescription__description__container">
            <div className="EasyChargeDescription__description__card">
              <h3 className="EasyChargeDescription__description__card-title">
                Vos ponés<br/>el precio
              </h3>
              <p className="EasyChargeDescription__description__card-text">
                Ya sos parte de nuestra comunidad de alohadores. Una vez que cargaste tu propiedad sólo resta definir cuánto querés cobrar por noche y las condiciones de alquiler.
              </p>
            </div>
            <div className="EasyChargeDescription__description__card">
              <h3 className="EasyChargeDescription__description__card-title">
                Comisiones<br/>bajas y en pesos
              </h3>
              <p className="EasyChargeDescription__description__card-text">
                ¡Sin impuestos ni cargos extra sorpresa! Operamos en pesos argentinos. Recibirás el dinero que figura en la página de administración de tu propiedad. Solo se te descontará el 6,5% de  comisión de uso de la plataforma.
              </p>
            </div>
            <div className="EasyChargeDescription__description__card">
              <h3 className="EasyChargeDescription__description__card-title">
                Cobrá<br/>rápido
              </h3>
              <p className="EasyChargeDescription__description__card-text">
                Llega tu huésped, ya casi que se hicieron amigos, y pasadas las 24 horas que disponen para denunciar cualquier inconveniente, te emitimos el pago a tu cuenta. Así de fácil y seguro. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EasyChargeDescription;