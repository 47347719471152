// Librerías
import React, { useEffect } from "react";
import AxiosAuth from "../../../services/requestApi";
// Componentes
import Buttons from "../Utils/Buttons";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_values.sass";

const Values = () => {
  const [name, setName] = React.useState(null);

  async function getUserName() {
    await AxiosAuth.get("/user/name").then((response) => {
      setName(response.data);
    });
  }

  useEffect(() => {
    getUserName();
  });

  return (
    <div className="Values">
      <div className="Values__left">
        <div className="Values__floating">
          <h1 className=" Values__title">
            ¡Bienvenido a Alohar
            {name ? (
              <React.Fragment>
                ,<br />
                <strong>{name}</strong>!
              </React.Fragment>
            ) : (
              "!"
            )}
          </h1>
          <p className=" Values__description">
            Nuestra misión es democratizar el turismo nacional facilitando la
            conexión entre propietarios y huéspedes en un medio digital seguro.
          </p>
          <p className=" Values__description">
            Esta comunidad se basa en una relación de <b>confianza</b>.
          </p>
          <p className=" Values__description">
            Acepto relacionarme con los miembros de la comunidad con respeto,
            autenticidad, seguridad y sin perjuicio de nadie.
          </p>
          <div className="Values__button">
            <Buttons />
          </div>
        </div>
      </div>
      <div className="Values__right">
        <p className="Values__tagline">
          Elsa es alohadora
          <br />
          en Mar del Plata.
        </p>
      </div>
    </div>
  );
};

export default Values;
