// Librerías
import React, { useState, useEffect } from "react";
import { ScreenClassProvider } from "react-grid-system";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import ReactGA from "react-ga";
// Components
import Navbar from "../components/Navbar/Navbar";
import PropertyComponent from "../components/PropertyPage/PropertyComponent";
import Footer from "../components/Footer/Footer";
import { Redirect } from "react-router-dom";
// Recursos
import "../assets/styles/PropertyPage/_property_page.sass";
import { BASE_URL } from "../Helpers";

ReactGA.initialize("G-H6XXNZ6Z0T");

const PropertyPage = () => {
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState({});
  const [housing, setHousing] = useState(false);
  const [redirctTo, setRedirctTo] = useState(false);
  const { id } = useParams();
  const query = queryString.parse(window.location.search);
  const { partner } = query;

  const sendAndSaveMarketingLog = (partner) => {
    localStorage.setItem("partner", JSON.stringify(partner));
    const url = BASE_URL + "/housing/marketing/";
    const bodyParameters = {
      action: "view",
      partner: partner,
      reservation: null,
      housing: id,
    };
    axios.post(url, bodyParameters).catch(function (error) {
      console.log(error.response);
    });
  };

  const getImage = () => {
    const url_media = BASE_URL + "/housing/images/" + id;
    axios
      .get(url_media)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.length > 0) {
            response.data.map((img) => {
              img.media = BASE_URL + img.media.replace("public", "");
            });
            setVideo(response.data[0]);
            response.data.splice(0, 1);
            setImages(response.data);
          }
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    localStorage.removeItem("partner");
    getImage();
    const apiKey = process.env.REACT_APP_ALOHAR_KEY;
    const url = `${BASE_URL}/housing/${id}?apiKey=${apiKey}`;
    axios
      .get(url)
      .then(function (response) {
        if (response.status === 200) {
          response.data.housingService = response.data.housingService.map(
            (service) => service.housingService
          );
          response.data.housingRoom = response.data.housingRoom.map(
            (room) => room.room
          );
          response.data.housingRule = response.data.housingRule.map(
            (rule) => rule.rule
          );
          setHousing(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 404) {
          setRedirctTo(true);
        }
      });
    if (partner) {
      sendAndSaveMarketingLog(partner);
    }
  }, []);
  return (
    <ScreenClassProvider>
      <Navbar addSearch />
      {redirctTo ? <Redirect to="/404" /> : ""}
      {/* <PropertyComponent images={images} video={video} housing={housing}/>  */}
      {housing ? (
        <PropertyComponent images={images} video={video} housing={housing} />
      ) : (
        ""
      )}
      <Footer />
    </ScreenClassProvider>
  );
};

export default connect()(PropertyPage);
