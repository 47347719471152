import React from "react";
import LandingContainer from "../../landing-container";
import ButtonPrimary from "../../../components/ui/button-primary";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../Helpers";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import GoogleTagManagger from "../../../services/GoogleTagManagger";
import "./success-register.sass";

const SuccessReservation = () => {
  const router = useHistory();
  useEffect(() => {
    const email = localStorage.getItem("registerEmail");
    setEmail(email);
    scriptLead();
  }, []);

  const [showSendedEmail, setShowSendedEmail] = useState(false);
  const [email, setEmail] = useState("");
  const handleReSendEmail = () => {
    axios
      .post(`${BASE_URL}/auth/send_confirm_email`, {
        email: email,
      })
      .then(function (response) {
        setShowSendedEmail("success");
      })
      .catch(function (error) {
        console.log(error);
        setShowSendedEmail("error");
      });
  };

  const scriptLead = () => {
    return <GoogleTagManagger />;
  };

  return (
    <LandingContainer>
      <div className="successRegister">
        <div className="successRegister__container">
          <h2 className="successRegister__container__title">
            Registro completo
          </h2>
          {showSendedEmail === "error" && (
            <ErrorAlert
              type={"success"}
              message={
                "Email reenviado exitosamente, chequee su bandeja de entrada. De no encontrarlo, verifique su carpeta de Spam."
              }
            />
          )}

          {showSendedEmail === "success" && (
            <ErrorAlert
              type={"success"}
              message={"Email reenviado exitosamente."}
            />
          )}
          {showSendedEmail === "success" && (
            <p>
              Hemos reenviado el correo para confirmar tu cuenta, por favor,
              verificá tu bandeja de entrada y de spam, y confirma la cuenta
              para poder ingresar a alohar.
              <br />
              <br />
              Muchas gracias.
            </p>
          )}

          {showSendedEmail === false && (
            <p>
              Hemos enviado un correo para confirmar tu cuenta, por favor revisá
              tu casilla de email y confirmá la cuenta para poder ingresar en
              Alohar.
              <br />
              <br />
              Muchas gracias.
            </p>
          )}
          {showSendedEmail === "error" && (
            <p>
              Hemos enviado un correo para confirmar tu cuenta, por favor revisá
              tu casilla de email y confirmá la cuenta para poder ingresar en
              Alohar.
              <br />
              <br />
              Muchas gracias.
            </p>
          )}

          <br />
          <ButtonPrimary
            disabled={showSendedEmail !== false && false}
            width={"300px"}
            title={
              showSendedEmail
                ? "Correo enviado"
                : "Reenviar email de Activación"
            }
            onClick={() => handleReSendEmail()}
          />
          <br />
          <ButtonPrimary
            title={"Iniciar sesion"}
            onClick={() => router.push("/")}
          />
        </div>
      </div>
    </LandingContainer>
  );
};

export default SuccessReservation;
