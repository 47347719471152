// Librerías
import React from 'react';
import { Link } from 'react-router-dom';
// Recursos
import emptyStateImg from '../../assets/static/emptystate.svg';
import '../../assets/styles/Utils/__emptystate.sass';

const EmptyState = ({ message }) => {
    return(
        <div className="EmptyState">
            <div className="EmptyState__container">
                <div className="EmptyState__container__imgcontainer">
                    <img className="EmptyState__container__imgcontainer__img" src={emptyStateImg} alt=""/>
                </div>
                <div className="EmptyState__container__title">
                    {message}
                </div>
            </div>
        </div>
    );
}

export default EmptyState;