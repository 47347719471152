
export const nextStep = () => (dispatch) => {
  dispatch({
      type: "NEXT_STEP"
  })
}

export const restart = () => (dispatch) => {
  dispatch({
      type: "RESTART"
  })
}

export const countValidate = () => (dispatch) => {
  dispatch({
      type: "COUNT_VALIDATE"
  })
}

export const previousStep = () => (dispatch) =>{
  dispatch({
      type: "PREVIOUS_STEP"
  })
}

export const setStep = (data) => (dispatch) =>{
  
  dispatch({
      type: "SET_STEP",
      step: parseInt(data.step)
  })
}

export const validateStep = (validate) => (dispatch) =>{
  dispatch({
      type: "VALIDATE_STEP",
      validate: validate
  })
}

export const housingIdSetup = (data) => (dispatch) =>{
  dispatch({
      type: "HOUSING_ID_UPDATE",
      housingId: data.id,
  })
}

export const userStep = (data) => (dispatch) =>{
  dispatch({
      type: "USER_STEP_UPDATE",
      prefixPhoneNumber: data.prefixPhoneNumber,
      phoneNumber: data.phoneNumber
  })
}

export const quantitiesStep = (data) => (dispatch) =>{
  dispatch({
      type: "QUANTITIES_STEP_UPDATE",
      guests: data.guests,
      simple_beds: data.simple_beds,
      double_beds: data.double_beds,
      rooms: data.rooms,
      bathrooms: data.bathrooms,
  })
}

export const locationStep = (data) => (dispatch) =>{
  dispatch({
      type: "LOCATION_STEP_UPDATE",
      adress: data.adress,
      adressExtra: data.adressExtra,
      lat: data.lat,
      lng: data.lng,
      center: data.center,
      zoom: data.zoom,
      locationId: data.locationId,
      floor: data.floor,
      department: data.department,
      TAD: data.TAD,
      province: data.province,
      neighborhood: data.neighborhood,
      countryId: data.countryId,
  })
}

export const servicesStep = (data) => (dispatch) =>{
  dispatch({
      type: "SERVICES_STEP_UPDATE",
      typeHousingRoom: data.typeHousingRoom,
      typeHousingService: data.typeHousingService,
      extraHousingRoom: data.extraHousingRoom,
      extraHousingService: data.extraHousingService
  })
}

// STEP 8

export const covidStep = (data) => (dispatch) =>{
  dispatch({
      type: "CODVID_STEP_UPDATE",
      typeHousingSanitary: data.typeHousingSanitary,
      typeHousingSecurity: data.typeHousingSecurity
  })
}

// STEP 9

export const photosStep = (data) => (dispatch) =>{
  /* Somtimes data.files is object and no array, WTF?*/
  
  if(!Array.isArray(data.files)){
    let files_array = Object.keys(data.files).map(key => data.files[key]);
    data.files = files_array
  }
  
  dispatch({
      type: "PHOTOS_STEP_UPDATE",
      files: data.files,
      displayDropzone: data.displayDropzone,
      displayGallery: data.displayGallery,
  })
}

export const titleStep = (data) => (dispatch) =>{
  dispatch({
      type: "TITLE_STEP_UPDATE",
      title: data.title,
      description: data.description
  })
}

export const typeStep = (data) => (dispatch) =>{
  dispatch({
      type: "TYPE_STEP_UPDATE",
      typeHousingProperty: data.typeHousingProperty,
      typeHousingSpace: data.typeHousingSpace,
  })
}

export const guestStep = (data) => (dispatch) =>{
  dispatch({
      type: "GUEST_STEP_UPDATE",
      minNight: data.minNight,
      maxNight: data.maxNight,
      timeAdvanceBook: data.timeAdvanceBook,
      startCheckInTime: data.startCheckInTime,
      endCheckInTime: data.endCheckInTime,
      checkOutTime: data.checkOutTime,
      typeHousingReservation: data.typeHousingReservation

  })
}

export const priceStep = (data) => (dispatch) =>{
  dispatch({
      type: "PRICE_STEP_UPDATE",
      coin: data.coin,
      basePrice: data.basePrice,
      cleaningPrice: data.cleaningPrice,
      weeklyDiscount: data.weeklyDiscount,
      monthlyDiscount: data.monthlyDiscount,
  })
}

export const calendarStep = (data) => (dispatch) =>{
  dispatch({
      type: "CALENDAR_STEP_UPDATE",
      calendar: data.calendar,
  })
}

export const paymentStep = (data) => (dispatch) =>{
  // housingId
  dispatch({
      type: "PAYMENT_STEP_UPDATE",
      cbu: data.cbu,
      alias: data.alias,
      bank: data.bank,
      account: data.account,
      headline: data.headline,
      cuit: data.cuit,
      typeBankAccount: data.typeBankAccount,
      housingId: data.housingId

  })
}

export const photoStep = (data) => (dispatch) =>{
  dispatch({
      type: "PHOTO_STEP_UPDATE",
      files: data.files
  })
}

export const ruleStep = (data) => (dispatch) =>{
  dispatch({
      type: "RULE_STEP_UPDATE",
      rule: data.rule,
      ruleExtra: data.ruleExtra,
      cancelation: data.cancelation
  })
}

export const legalStep = (data) => (dispatch) =>{
  dispatch({
      type: "LEGAL_STEP_UPDATE",
      tos: data.tos
  })
}


export const stepSuccess = (step,status) => (dispatch) =>{
  dispatch({
      type: "STEP_SUCCESS_SEND",
      step: step,
      status: status
  })
}