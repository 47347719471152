// Librerías
import React, {useState} from 'react';
// Componentes
import ErrorMessage from '../Alerts/ErrorAlert';
// Recursos
import x from '../../assets/static/Modals/x.png';
import '../../assets/styles/Modals/_confirm_modal.sass';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const PhoneModal = ({handleSavePhone, handleCloseModal}) => {
    const [phone, setPhone] = useState(null)
    const [showError, setShowError] = useState(false)

    const handleChange = (event) => {
        const val = event.target.value;
        setPhone(val.replace(/\D/g,''))
    }

    const _handleSavephone = () =>{
        if(phone && phone.length > 8){
            handleSavePhone(phone.replace(/\D/g,''))
            setShowError(false)
        }else{
            setShowError(true)
        }

    }
    
    return(
        <div className="ConfirmModal">
            <header className="ConfirmModal__header">Necesitamos tú número de teléfono <img onClick={handleCloseModal} alt="x" src={x} className="x"/></header>
            <section className="ConfirmModal__section">
                <div className="ConfirmModal__section__iconContainer">
                    <i className="ConfirmModal__section__icon" ><FontAwesomeIcon icon={faPhoneAlt} /></i>
                </div>
                <br/>

                <p>Es importante que nos deje tu contacto para que el alohador pueda comunicarse con vos. Podrás editar el número desde tú perfil de usuario.</p>

                <div className="ConfirmModal__inputContainer">
                  <label className="label">
                    <span className="text-label" htmlFor="cellphone">Celular</span>
                  </label>
                  <input
                    name="phone" 
                    id="phone" 
                    type="number"
                    onChange={handleChange}
                    onBlur={handleChange}
                    autoComplete={false}
                    placeholder="Ej. 2932 41111111"
                  />

                  {
                    showError
                    ? <ErrorMessage message={"Debe introducir un número de téfono válido. Solamente ingrese números incluyendo el código de área y de país si sos extranjero."} />
                    : ''
                  }
                </div>

               
                
                
                <section className="ConfirmModal__buttons">
                    <button className="button_orange" onClick={_handleSavephone}>Guardar</button>
                </section>
            </section>
        </div>
    );
}

export default PhoneModal;
