import axios from "axios";
import { BASE_URL } from "../Helpers";
import AxiosAuth from "../services/requestApi";
import LocalStorageService from "../services/localStorageService";
//https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da

const API_AUTH = `${BASE_URL}/auth/`;
const localStorageService = LocalStorageService.getService();

class AuthService {
  login(email, password) {
    return axios
      .post(API_AUTH + "signin", {
        email,
        password,
        from: "app",
      })
      .then((response) => {
        response.data["login"] = true;
        const { token, refresh_token } = response.data.data.payload;
        let { avatar, firstName, lastName, id } = response.data.data.user;

        if (!avatar.includes("http")) avatar = BASE_URL + avatar;
        localStorage.removeItem("housingPublicProcessData");
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem(
          "userData",
          JSON.stringify({
            // token:token,
            // refresh_token:refresh_token,
            id: id,
            avatar: avatar,
            firstName: firstName,
            lastName: lastName,
            login: true,
          })
        );
        return response;
      })
      .finally(() => {
        try {
          this.getRole();
        } catch (error) {
          console.log(error);
        }
      });
  }

  async getRole() {
    AxiosAuth.get("/auth/role").then((response) => {
      if (response.data.length > 0) {
        localStorage.setItem("role", JSON.stringify(response.data[0]));
      } else {
        const role = {
          role: "housing",
        };
        localStorage.setItem("role", JSON.stringify(role));
      }
    });
  }

  recover(email) {
    return axios
      .post(API_AUTH + "recover", {
        headers: {
          "content-type": "application/json",
        },
        email: email,
      })
      .then((response) => {
        return response;
      });
  }

  // Borrar token de app del servidor
  async logout() {
    const token = localStorage.getItem("refresh_token");
    const url = API_AUTH + "logout";
    localStorage.clear();
    return await AxiosAuth.post(url, { refresh_token: token });
  }

  register(name, lastName, email, password, birthday) {
    return axios.post(API_AUTH + "signup", {
      firstName: name,
      lastName: lastName,
      email: email,
      password: password,
      birthDay: birthday,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
