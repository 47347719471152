import React, { useState } from "react";
import { BASE_URL } from "../../Helpers";
import AxiosAuth from "../../services/requestApi";
import loadingGif from "../../assets/static/loading.gif";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ReservationModal } from "../Modals/ReservationModal";
import { AcceptReservationModal } from "../Modals/AcceptReservationModal";
import ButtonPrimary from "../ui/button-primary";
import ButtonWhite from "../ui/button-white";
import ModalGlobal from "../ui/modal-global";
import "../../assets/styles/ConfirmReservations/_reservation.sass";
import FinishedModal from "../Modals/FinishedModal";
import ModalSuccessStyle from "../ui/modalSuccessStyle";
import jsonGlobal from "../../utilities/traduccGlobal.json";
import useCurrentLanguage from "../../services/currentlenguaje";

moment.locale("es");

const Reservation = ({
  reservation,
  handleOpenContactAlohadorModal,
  handleCancelationModal,
  states,
  setModalClose,
  getAllreservation,
  keyp
}) => {
  const {handleFilterLang} = useCurrentLanguage();

  const [error, setError] = useState({
    modal: false,
    adminNoConfirm: false,
    adminConfirm: false,
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [modalAcceptReservation, setModalAcceptReservation] = useState(false);
  const {
    user,
    housing,
    guests,
    checkin,
    checkout,
    nights,
    status,
    limitToConfirm,
    id,
    locator,
    paymentType,
  } = reservation;
  const router = useHistory();
  const userAvatarDefault = `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${user.firstName}`;

  const avatar =
    user.avatar && user.avatar.includes("http")
      ? user.avatar
      : user.avatar === null
      ? userAvatarDefault
      : `${BASE_URL}/user/avatar/${user.avatar}`;

  const _checkin = moment(checkin).utc(true);
  const _checkout = moment(checkout).utc(true);
  const _limitToConfirm = moment(limitToConfirm).utc(true);

  const estados = {
    pending: handleFilterLang(jsonGlobal).pendiente  ,
    cancelled:  handleFilterLang(jsonGlobal).cancelada,
    confirmed: handleFilterLang(jsonGlobal).confirmada || "2",
  };

  const handleConfirm = () => {
    setLoading(true);
    const url = `${BASE_URL}/v2/reservations/${id}`;
    AxiosAuth.put(url)
      .then((response) => {
        setLoading(false);
        setModalAcceptReservation(true);
        setError({ ...error, adminConfirm: true });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data?.message === "ADMIN_NO_CONFIRM") {
          setError({ ...error, modal: true, adminNoConfirm: true });
        }
      });
    getAllreservation();
  };
  const acceptReservation = () => {
    setError({ ...error, modal: false });
    getAllreservation();
  };
  return (
    <div className="Reservation">
      <AcceptReservationModal
        guest={user.firstName}
        isOpen={error.adminConfirm}
        isClose={() => {
          setError({ ...error, adminConfirm: false });
        }}
        checkin={_checkin.format("ddd D, MMM")}
        checkout={_checkout.format("ddd D, MMM")}
        status={estados[status]}
        locator={locator}
        dateLimit={_limitToConfirm.format("ddd D MMM, H:mm")}
        nights={nights}
        guests={guests}
      />

      <ModalSuccessStyle
        closeModal={error.modal}
        setCloseModal={() => setError({ ...error, modal: false })}
      >
        <div className="Reservation__modal">
          <h2 className="Reservation__modal__title">
            {handleFilterLang(jsonGlobal).felicidadesReservaConfirmada}
            
          </h2>
          {error.adminNoConfirm && (
            <p className="Reservation__modal__text">
              {handleFilterLang(jsonGlobal).verificarPago}
              
            </p>
          )}

          <ButtonPrimary title={handleFilterLang(jsonGlobal).aceptar} onClick={acceptReservation} />
        </div>
      </ModalSuccessStyle>
      <ReservationModal openModal={openModal} setOpenModal={setOpenModal} />
      <div className="Reservation__imgcontainer">
        <img
          className="Reservation__imgcontainer__img"
          alt="Viaje"
          src={avatar}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = userAvatarDefault;
          }}
        />
      </div>
      <div className="Reservation__datacontainer">
        <div className="Reservation__datacontainer__dates">
        {handleFilterLang(jsonGlobal).del}
          
           {_checkin.format("ddd D, MMM")}{handleFilterLang(jsonGlobal).al}

          {_checkout.format("ddd D, MMM")}
        </div>
        <div className="Reservation__datacontainer__dates">
          {nights} {handleFilterLang(jsonGlobal).noches}
 | {guests} {handleFilterLang(jsonGlobal).personas}
        </div>
        <div className="Reservation__datacontainer__title">
          {user.firstName}, {user.lastName}
        </div>
        <div className="Reservation__datacontainer__address">
        {handleFilterLang(jsonGlobal).estado}: {estados[status]}
        </div>
        <div className="Reservation__datacontainer__address">
        {handleFilterLang(jsonGlobal).localizador}: {locator}
        </div>
        <div className="Reservation__datacontainer__address">
        {handleFilterLang(jsonGlobal).fechaLimiteConfirmar}:
          {_limitToConfirm.format("ddd D MMM, H:mm")}
        </div>
        <div className="Reservation__datacontainer__buttonList">
          <div className="Reservation__datacontainer__buttonList__buttons">
            <ButtonPrimary
              fontSize={"13px"}
              width={120}
              title={handleFilterLang(jsonGlobal).contactarAlHuesped}
              onClick={() => {
                handleOpenContactAlohadorModal(
                  user.firstName,
                  housing.id,
                  user.id
                );
              }}
            />
            <ButtonPrimary
              width={120}
              title={handleFilterLang(jsonGlobal).verDetalle}
              onClick={() => {
                history.push(`/alohador/reservations/${id}`);
              }}
            />
          </div>

          <div className="Reservation__datacontainer__buttonList__buttons">
            {states === "pending" && (
              <>
                <ButtonPrimary
                  width={120}
                  title={handleFilterLang(jsonGlobal).aceptar}
                  onClick={() => handleConfirm()}
                  disabled={loading ? true : false}
                />
                <ButtonWhite
                  width={120}
                  title={handleFilterLang(jsonGlobal).rechazar}
                  onClick={
                    states === "next"
                      ? () => setModalClose(true)
                      : () => handleCancelationModal(id, reservation)
                  }
                />
              </>
            )}
            {states === "next" && (
              <ButtonWhite
                width={120}
                title={handleFilterLang(jsonGlobal).rechazar}
                onClick={
                  states === "next"
                    ? () => setModalClose(true)
                    : () => handleCancelationModal(id, reservation)
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservation;
