// Librerías
import React from 'react';
// Recursos
import '../../../assets/styles/RentLanding/Content/_easyHousingNumbers.sass';

const EasyHousingNumbers = () => {
  return(
    <div className="EasyHousingNumbers">
      <div className="EasyHousingNumbers__description">
        <div className="EasyHousingNumbers__description__container">
          <div className="EasyHousingNumbers__description__card">
            <h3 className="EasyHousingNumbers__description__card-title">
              <b>1 - Registrá</b><br/>tu Alohamiento
            </h3>
            <p className="EasyHousingNumbers__description__card-desc">
              Creás tu perfil de forma gratuita, subís las mejores fotos que tengas de tu propiedad, apretás el botoncito que dice “publicar” y... listo. Eso sí, ponele onda: cuanto más te esmeres, más chances tendrás de alohar huéspedes, viajeros, en definitiva, huéspedes.
            </p>
          </div>
          <div className="EasyHousingNumbers__description__card">
            <h3 className="EasyHousingNumbers__description__card-title">
              <b>2 - Decidí</b><br/>cómo querés alohar
            </h3>
            <p className="EasyHousingNumbers__description__card-desc">
              Por día, por semana, por mes. El sistema es tan flexible como las personas. Pará, pará, ¿vos no sos flexible? Bueno, deberías. Así es como funciona nuestra plataforma: se adapta a tus necesidades para que puedas aprovecharla al máximo.
            </p>
          </div>
          <div className="EasyHousingNumbers__description__card">
            <h3 className="EasyHousingNumbers__description__card-title">
              <b>3 - Recibí</b><br/>tu primer huésped
            </h3>
            <p className="EasyHousingNumbers__description__card-desc">
              ¡Qué nervios! Todo listo, la casa limpia, perfumada, todo en su lugar. Ahí viene con sus valijas, cansado, tu primer huésped. No hay nada que temer, olvidate. Seguro va a salir todo bien y será la primera de muchas maravillosas experiencias que vas a compartir con nuestra comunidad.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EasyHousingNumbers;