// Librerías
import React from 'react';
// Components
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import ValuationComponent from '../components/Valuation/ValuationComponent';


const ValuationContainer = () => {
  return(
    <React.Fragment>
      <Navbar />
      <ValuationComponent />
      <Footer />
    </React.Fragment>
  )
}

export default ValuationContainer;