// Librerías
import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
// Recursos
import '../../../assets/styles/PropertyPage/Utils/_gallery.sass';

// Opciones del Lightbox Plugin
const options = {
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
  },
  caption: {
    captionFontFamily: 'Fira Sans, sans-serif',
    captionFontSize: '22px',
    captionColor: '#8D99AE',
    captionFontWeight: 500,
    showCaption: true
  },
  settings: {
    overlayColor: 'rgba(43, 45, 66, 0.95)',
    transitionTimingFunction: 'ease-in-out',
    slideTransitionSpeed: 0.6,
    slideTransitionTimingFunction: [0.25, 0.75, 0.5, 1],
    slideAnimationType: 'slide',
    enablePanzoom: false,
    autoplaySpeed: 5000,
    hideControlsAfter: false
  },
  progressBar: {
    height: '3px',
    fillColor: '#8D99AE',
    backgroundColor: 'rgba(43, 45, 66, 0.95)'
  },
  thumbnails: {
    showThumbnails: true
  }
}

const PropertyGallery = ({ images, video }) => {
  const listImages = images.map((item,index) => {
    return (
      <div className="PropertyGallery__imgContainer__images__imgc" key={item.id}>
        <img src={item.media} alt={item.description} style={{display: (index > 3) ? "none": ''}}/>
      </div>
    );
  }
  );
  return(
    <React.Fragment>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <div className="PropertyGallery">
            <div className="PropertyGallery__imgContainer">
              <div className="PropertyGallery__imgContainer__video">
                <img src={video.media} alt={video.description}/>
              </div>
              <div className="PropertyGallery__imgContainer__images">
                { listImages }
              </div>
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </React.Fragment>
  );
}

export default PropertyGallery;