// Librerías
import React from 'react'

const ExpanderUser = ({ data }) => {
    return(
    <>
        <div>
            <br /><div>
                <b>Nombre y Apellido: </b> 
                <p>{data.name} </p>
            </div><br />
            <div>
                <b>Número de teléfono / celular:</b> 
                <p>{(data.phoneNumber) ? data.phoneNumber : "No ingreso el número de teléfono"}</p>
            </div><br />
            <div>
                <b>DNI:</b> 
                <p>{(data.dni) ? data.dni : "No ingreso el documento"}</p>
            </div><br />
            <div>
                <b>Provider: </b>
                <p>{data.provider}</p>
            </div><br />
            <div>
                <b>Último login: </b>
                <p>{data.lastLogin}</p>
            </div><br />
        </div>
    </>
    )
};

export default ExpanderUser;
