// Librerías
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
// Componentes
import ValuationContainer from '../../Utils/ValuationContainer';
import Separator from '../../Utils/Separator';
// Sass
import '../../../assets/styles/Profile/Utils/_profilevaluationcontainer.sass'

const ProfileValuationContainer = (props) => {
  const valuationsAsHost = props.valuationsAsHost;
  const valuationsAsGuest = props.valuationsAsGuest;
  const valuationsAmountAsHost = valuationsAsHost.length;
  const average = props.average;
  const valuationsAmountAsGuest = valuationsAsGuest.length;
  const [valuationsAmount] = useState(valuationsAmountAsGuest + valuationsAmountAsHost);
  const [valuationType, setValuationType] = useState('fromGuests');
  const [valuationsShown, setValuationsShown] = useState([]);
  const [showOneCat, setShowOneCat] = useState(false);

  const ifOnlySetOne = (asHost, asGuest) => {
    if(asGuest === 0 || asHost === 0){
      if(asGuest === 0) setValuationsShown(valuationsAsHost);
      if(asHost === 0) setValuationsShown(valuationsAsGuest);
      return true;
    }
    return false;
  }

  const setValuations = () => {
    if(valuationType === 'fromGuests') setValuationsShown(valuationsAsHost);
    if(valuationType === 'fromHosts') setValuationsShown(valuationsAsGuest);
  }

  useEffect(() => {
    setValuations();
    setShowOneCat(ifOnlySetOne(valuationsAmountAsHost, valuationsAmountAsGuest));
  }, []);



  return (
    <div className="ProfileValuationContainer">
      <h5 className="ProfileValuationContainer__title">
        <FontAwesomeIcon className="ProfileValuationContainer__title__icon" icon={ faStar } />
        <p className="ProfileValuationContainer__title__text">
          { (valuationsAmount === 1) ? `${valuationsAmount} evalución` : `${valuationsAmount} evaluaciones` } { (average !== (null || undefined)) ?? ` con una valoración promedio de ${average} de 5 estrellas`}
        </p>
      </h5>
      {
        !showOneCat
        ?
        <div className="ProfileValuationContainer__tabs">
          <button className={`ProfileValuationContainer__tabs__tab${ valuationType === 'fromGuests' ? ' select' : '' }`} onClick={() => setValuationType('fromGuests')}>De los huéspedes ({valuationsAmountAsHost})</button>
          <button className={`ProfileValuationContainer__tabs__tab${ valuationType === 'fromHosts' ? ' select' : ''}`} onClick={() => setValuationType('fromHosts')}>De los alohadores ({valuationsAmountAsGuest})</button>
        </div>
        : ''
      }
      <section className="ProfileValuationContainer__review">
        {
          valuationsShown.map((val) => <ValuationContainer valuation={ val } key={val.reservation.id} />)
        }
      </section>
      <Separator />
    </div>
  )
}

export default ProfileValuationContainer
