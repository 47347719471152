// Librerías
import React from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector, shallowEqual } from "react-redux";
import { openRegisterModal } from "../../../store/modal/actions";
// Recursos
import "../../../assets/styles/RentLanding/Content/_guarantees.sass";
import ButtonPrimary from "../../ui/button-primary";

const Guarantees = () => {
  const router = useHistory();
  const user = useSelector((state) => state.userReducer, shallowEqual);

  return (
    <React.Fragment>
      <div className="Guarantees">
        <div className="Guarantees__headline">
          <div className="Guarantees__headline__container">
            <p className="Guarantees__headline__container__text">
              Garantía para alohadores
            </p>
            <p className="Guarantees__headline__container__text Guarantees__headline__container__text__orange">
              Nosotros te cubrimos
            </p>
            <ButtonPrimary
              title={"Alohá"}
              onClick={
                user.login
                  ? router.push("/alohar")
                  : () => router.push("/register")
              }
            />
          </div>
        </div>
        <div className="Guarantees__description">
          <div className="Guarantees__description__container">
            <p className="Guarantees__description__container__title">
              Sabemos que la tranquilidad es súper importante. Por eso
              trabajamos para que nuestros alohadores estén 100 por ciento
              seguros:
            </p>
            <ul className="Guarantees__description__container__list">
              <li>Definís el calendario de tu espacio</li>
              <li>Ponés las reglas de tu hogar</li>
              <li>Identidad del huésped verificada</li>
              <li>Servicio de soporte Alohar</li>
              <li>Sistema de pago antifraudes</li>
              <li>Seguro de responsabilidad civil</li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { openRegisterModal })(Guarantees);
