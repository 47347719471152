// Librerías
import React from 'react'
import {isMobile} from 'react-device-detect';
// Recursos
import '../assets/styles/Working/_working.sass';
import Navbar from '../components/Navbar/Navbar';


function Working() {
    const selectNavbar = () =>{
        if( isMobile ){
            return ''
        }else{
            return <Navbar isLanding={true} hideButtons={true} />
        }
    }
    return (
    <div className="Working">
        { selectNavbar() }
        <div className="Working__textContainer">
            <h2 className="Working__textContainer__title">
                Estamos trabajando para mejorar tu experiencia
            </h2>
            <p className="Working__textContainer__description">
                En breve podrás volver a buscar tu próximo destino<br />
                - Equipo Alohar
            </p>
        </div>
    </div>
    );
}

export default Working
