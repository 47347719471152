import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BASE_URL } from "../../../Helpers";
import axios from "axios";
import hotSale from "../../../../src/assets/static/hot_sale/alohar-hot-sale.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import HotSaleButton from "../../Buttons/HotSaleButton";
import { truncar } from "../../../utilities/truncar";
import styles from "./housingPromotions.module.css";

import levelPlatinium from "../../../../src/assets/static/housing-promotions/bandas-plata-borde.png";
import levelGold from "../../../../src/assets/static/housing-promotions/bandas-dorado-borde.png";
const HousingPromotions = () => {
  const [items, setItems] = useState([]);

  const [hotSaleActive, setHotSaleActive] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getHousingPromotions = (currentPage) => {
    const url = `${BASE_URL}/housing-promotions/v2?page=${currentPage}&pageSize=10`;
    axios.get(url).then((response) => {
      setItems(response.data);
      const filterHotSale = response.data.filter(
        (item) => item.status === 0 || item.status === 2
      );
      if (filterHotSale.length > 0) {
        setHotSaleActive(true);
      }
    });
  };
  useEffect(() => {
    getHousingPromotions(1);
  }, []);

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    const slidesPerView = swiper.params.slidesPerView;
    const totalSlides = swiper.slides.length;

    const slidesRemaining = totalSlides - activeIndex;

    // if (items.length === activeIndex + 4) {
    //   getHousingPromotions(currentPage + 1);
    // }
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {hotSaleActive && <HotSaleButton />}

      {items.length > 0 && (
        <section
          // bg-dark100
          // style={{height:"calc(100vh - 75px)"}}
          className={`flex   relative flex-col  sm:justify-center items-center  pt-28 pb-6 sm:py-0    pl-[5%] sm:pl-0   sm:rounded-[10vw]  `}
        >
          <div className="relative flex flex-col w-full">
            <h2 className="text-black sm:pl-[5%] sm:text-[64px] text-[45px] font-castaBold">
              Sugeridos
            </h2>

            <div className={` w-full `}>
              <Swiper
                spaceBetween={0}
                slidesPerView={
                  windowWidth > 1050
                    ? 4
                    : windowWidth > 800
                    ? 3
                    : windowWidth > 700
                    ? 2
                    : 1
                }
                onSlideChange={handleSlideChange}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                lazy={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                // pb-[100px]
                className=" sm:px-[5%] pb-12"
              >
                <button
                  className={`${styles.prev} flex items-center   justify-center text-white swiper-button-prev`}
                  style={{
                    background: "#FF4E00",
                    borderRadius: "100%",
                    padding: "12px",
                    width: "34px",
                    height: "34px",
                  }}
                >{`<`}</button>
                <button
                  style={{
                    background: "#FF4E00",
                    borderRadius: "100%",
                    padding: "12px",
                    width: "34px",
                    height: "34px",
                  }}
                  className={`${styles.next} flex items-center justify-center text-white swiper-button-next`}
                >{`>`}</button>
                {items.map((item, index) => (
                  <SwiperSlide
                    // style={{ maxWidth: "350px" }}
                    className="sm:rounded-[10vw]"
                    key={index}
                  >
                    <Link
                      to={`${item.housing.location.location}/property/${item.housing.id}`}
                      className="relative rounded-[20px] bg-white w-11/12 gap-[10px] overflow-hidden flex flex-col"
                    >
                      {/* h-[50vh] sm:h-[12vw] */}
                      <div className="relative w-full    rounded-[20px]  h-[70vw] sm:h-[12vw] ">
                        {[1, 2].includes(item.status) &&
                          item?.priorityLevel?.name === "platinum" && (
                            <img
                              src={levelPlatinium}
                              alt="Hot sale"
                              className="absolute w-[45%] top-0 left-0 z-50"
                            />
                          )}
                        {[1, 2].includes(item.status) &&
                          item?.priorityLevel?.name === "gold" && (
                            <img
                              src={levelGold}
                              alt="Hot sale"
                              className="absolute w-[45%] top-0 left-0 z-50"
                            />
                          )}
                        {[0, 2].includes(item.status) && (
                          <img
                            src={hotSale}
                            alt="Hot sale"
                            className="absolute z-50 w-10 top-1 right-2"
                          />
                        )}
                        <img
                          className="object-cover w-full h-full  rounded-[20px]"
                          src={`${BASE_URL}/${item.housing.housingMedia[0]?.media.replace(
                            "public/",
                            ""
                          )}`}
                          alt="Alojamiento"
                        />
                        {[0, 2].includes(item.status) && (
                          <span className="absolute font-robotoRegular bottom-[-7.5%] right-[5%] px-2 py-2 text-sm leading-none text-white bg-primary rounded-[8px] ">
                            {item.discount}% OFF
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col ">
                        <div className="pl-[5%]  flex flex-col justify-between h-full gap-[10px] ">
                          <h3 className="text-grayAlohar w-max font-robotoRegular ">
                            {`${item.housing.location.location} ${
                              item.housing.location.id === 428
                                ? item.housing.neighborhood?.name
                                : ""
                            }`}
                          </h3>
                          <h2
                            className="text-[24px] leading-none truncate"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.housing.title}
                          </h2>
                          <h3 className="h-[50px]  leading-none font-robotoRegular">
                            {`${item.housing.description.substring(0, 110)} ${
                              item.housing.description.length >= 110
                                ? "..."
                                : ""
                            }`}
                          </h3>
                          <div>
                            {[0, 2].includes(item.status) && (
                              <del className="text-[24px] font-robotoRegular leading-none">
                                ${item.housing.basePrice}
                              </del>
                            )}
                            <p className="flex items-center gap-1 leading-none text-grayAlohar font-robotoRegular">
                              <span className="text-[32px]  text-primary font-robotoBold">
                                $
                                {[0, 2].includes(item.status) 
                                  ? truncar(
                                      item.housing.basePrice -
                                        (item.housing.basePrice *
                                          item.discount) /
                                          100,
                                      2
                                    )
                                  : `${Intl.NumberFormat("de-AR").format(
                                      item.housing.basePrice
                                    )}`}
                              </span>
                              / Noche
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default HousingPromotions;
