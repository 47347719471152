import React from "react";
import ArrowLeft from "../../../assets/components-icons/ArrowLeft";
import "./secondary-button.sass";
const SecondaryButton = ({ title, disabled, onClick, color,background }) => {
  return (
    <button
      className="secondaryButton"
      disabled={disabled }
      onClick={onClick}
      style={{ color: color ? color : "#FF4E00", background: background ? background : "#fff" }}
    >
      <ArrowLeft color={color ? color : "#FF4E00"} />
      {title}
    </button>
  );
};

export default SecondaryButton;
