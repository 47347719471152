// Librerías
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ScreenClassProvider, Container, Row, Col } from "react-grid-system";
// Componentes
import Navbar from "../components/Navbar/Navbar";
import ReservationList from "../components/ConfirmReservations/ReservationList";
import EmptyState from "../components/EmptyState/EmptyState";
import Footer from "../components/Footer/Footer";
import AxiosAuth from "../services/requestApi";
import loadingGif from "../assets/static/loadingBlack.gif";

const ConfirmReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [housing, setHousing] = useState("");
  const [loading, setLoading] = useState(true);
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const { id } = useParams();

  const url = "reservations/all/" + id;
  const getAllreservation = () => {
    AxiosAuth.get(url)
    .then((response) => {
        if (response.data.length > 0) {
          setReservations(response.data);
          setHousing(response.data[0].housing.title);
          setCheckin([
            response.data[0].startCheckInTime.slice(0, -3),
            response.data[0].endCheckinTime.slice(0, -3),
          ]);
          setCheckout(response.data[0].checkoutTime.slice(0, -3));
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllreservation();
  }, []);

  return (
    <div className="ConfirmReservations">
      <Navbar />
      <div className="ConfirmReservations__container">
        {loading ? (
          <img style={{ margin: "auto" }} src={loadingGif} />
        ) : reservations.length === 0 ? (
          <EmptyState message="Por el momento no hay reservas" />
        ) : (
          <div className="principal_col">
            <div className="ConfirmReservations__title">
              Reservas de: {housing}
            </div>
            <div>
              Horario de checkin: {checkin[0]} a {checkin[1]}
            </div>
            <div>Horario de checkout: {checkout}</div>
            <ReservationList
              reservations={reservations}
              getAllreservation={getAllreservation}
              setReservations={setReservations}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ConfirmReservations;
