// Librerías
import React from 'react'
// Recursos
import '../../assets/styles/LandingPage/_terms.sass';

const BaproLegal = () => {
  return (
    <div className="Terms">
      <h2 className="Terms__title">
        Promoción Banco Provincia
      </h2>
      <p className="Terms__text">
        (*) <b>CFTNA</b>: 0,00%
      </p>
      <p className="Terms__text">
        (Costo Financiero Total Nominal Anual)
      </p>
      <p className="Terms__text">
        (*) Costo Financiero Total Nominal Anual (<b>CFTNA</b>) 0,00%. Interés: Tasa Nominal Anual (<b>TNA</b>) 0,00%. Tasa Efectiva Anual (<b>TEA</b>) 0,00%. Promoción válida todos los días desde el 17 de mayo de 2021 hasta el 30 de junio de 2021 ambas fechas inclusive, en el ámbito de la República Argentina. Beneficio del 10% de descuento y hasta seis (6) cuotas sin interés, para la compra online de alojamiento pagando con las tarjetas de crédito VISA y/o Mastercard emitidas por el Banco Provincia en las empresas adheridas (consultar participantes de la promoción en <a target="_blank" rel="noopener noreferrer" href="https://www.bancoprovincia.com.ar/beneficios">https://www.bancoprovincia.com.ar/beneficios</a> ). Sin tope de reintegro. El mismo se verá reflejado como bonificación hasta en el resumen siguiente a aquel en el que ingrese el consumo. Se excluyen de la promoción las tarjetas Visa Regalo, Visa Recargable, Visa Corporate, Visa Business y Visa Purchasing. No acumulable con otras promociones y/o descuentos vigentes ni los planes “Ahora 3 ,6, 12 y 18”. La promoción no aplica en compras realizadas con tarjeta a través de MPOS y/o Botón de Pago S.A. y compras con billeteras virtuales, plataformas de pago y/o portales de pago on line. Ejemplo representativo: en un pago de $ 18.000.- en seis cuotas sin interés se abonarán seis cuotas mensuales de $3.000.- y <b>recibirá un reintegro</b> de $ 1.800.- El descuento promocionado aplicará sobre el precio al contado, en concordancia con la resolución 51 y 240–e/2017. Ministerio de Producción de la Nación, Secretaría de Comercio. Banco de la Provincia de Buenos Aires. CUIT 33-99924210-9. Calle 7 N° 726, La Plata, Provincia de Buenos Aires. CARTERA DE CONSUMO.
      </p>
    </div>
  )
}

export default BaproLegal;