import React from "react";
import ButtonPrimary from "../../ui/button-primary";
import "./modalSuccessTransfer.sass";
const ModalSuccessTransfer = ({ firstName, totalPrice, onClickSuccess,onClickDecline}) => {
  return (
    <div className="modalSuccessTransfer">
      <h2 className="modalSuccessTransfer__title">
        ¡Felicidades {firstName} !
      </h2>
      <p className="modalSuccessTransfer__subtitle">
        Tu pedido de reserva por transferencia está en proceso. Para poder
        <br /> completarlo debes:
      </p>

      <ul className="modalSuccessTransfer__list">
        <li className="modalSuccessTransfer__list__li">
          <strong>Realizar la transferencia a </strong>
          <br />
          CBU: 0720212620000000342340
          <br />
          ALIAS: alohar2020
          <br />
          CUIT: 30-71686126-7
          <br />
          de ${totalPrice}
          <br />
        </li>
      </ul>
      <ul className="modalSuccessTransfer__list">
        <li className="modalSuccessTransfer__list__li">
          <strong>Enviar un Whatsapp</strong> con el <br /> comprobante de
          transferencia
        </li>
      </ul>
      <ul className="modalSuccessTransfer__list">
        <li className="modalSuccessTransfer__list__li">
          Una vez confirmado el pago y la disponibilidad,
          <strong> nos contactaremos</strong> para confirmar la reserva y dejar
          todo registrado
        </li>
      </ul>
      <div className="modalSuccessTransfer__buttons">
        <ButtonPrimary title={"Aceptar"} onClick={onClickSuccess} />
        <ButtonPrimary secondaryColor={true} title={"Rechazar"} onClick={onClickDecline} />
      </div>
    </div>
  );
};

export default ModalSuccessTransfer;
