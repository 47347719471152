export   const mostCommonQuestionsEs = [
        {
            id:1,
            questionsType: "covid",
            question: "Acerca del brote de coronavirus (COVID-19)",
            answer: "El objetivo de nuestros alohadores, es mantener la higiene y las normas establecidas de público conocimiento, manteniendo el distanciamiento social. Desde Alohar promulgamos el seguimiento del protocolo definido para el alquiler de espacios de alojamiento.<br /><br /><a href='http://www.turismomardelplata.gob.ar/pdf_covid/Alojamiento%20Extra%20Hotelero.pdf' target='_blank'>Ver Protocolo</a>",
        },
        {
            id:2,
            questionsType: "register",
            question: "¿Cómo me registro en Alohar?",
            answer: "En la esquina superior derecha de la página inicial de Alohar, deberás hacer click en el icono y seleccionar la opción de <a href='/register'>Regístrate</a>. Podrás acceder a través de tu correo electrónico o cuenta de Facebook. Siempre deberás ingresar a través del mismo medio.",
        },
        {
            id:3,
            questionsType: "login",
            question: "¿Cómo completo el proceso de verificación para acceder a mi cuenta?",
            answer: "Al registrarte recibirás un e-mail para confirmar tu identidad. Una vez confirmada podrás iniciar sesión.",
        },
        {
            id:4,
            questionsType: "login",
            question: "¿Cómo inicio sesión?",
            answer: "En la esquina superior derecha de la página inicial de Alohar, deberás hacer click en el icono y seleccionar la opción de <a href='/login'>Iniciar Sesión</a>. E ingresar con la opción seleccionada al registrarse. Con cuenta de Gmail, Facebook o correo electrónico y la contraseña.",
        },
        {
            id:5,
            questionsType: "password",
            question: "¿Qué debo hacer para cambiar mi contraseña?",
            answer: "Si olvidaste tu contraseña, simplemente debes ir a la opción “Iniciar sesión” en el menú que se encuentra en la página principal en el margen superior derecho. Una vez que se abre la ventana, para ingresar, debés hacer click en la opción “olvidé mi contraseña”. <br /><br />En caso de que quieras cambiar tu contraseña, podrás hacerlo desde el menú principal una vez que hayas iniciado sesión. En la opción <a href='/profile'>Mi Perfil</a>, encontrarás el link de “Cambio de contraseña”.",
        },
        {
            id:6,
            questionsType: "profile-configuration",
            question: "¿Cómo modifico mis ajustes de contacto?",
            answer: "Una vez iniciada la sesión, accedes al menú principal en el margen superior derecho. Seleccionas la opción <a href='/profile'>Mi Perfil</a>, luego “editar perfil” y allí podrás modificar todos tus datos personales y de contacto.",
        },
        {
            id:7,
            questionsType: "housing-configuration",
            question: "¿Cómo configuro mi alohamiento?",
            answer: "En el menú principal, ingreso en <a href='/profile/housing'>Mis Alohamientos</a>.En mi aviso, selecciono la opción “Editar”. Y se abrirá una ventana en donde puede cambiar todas las configuraciones de su alohamiento: Desde este panel, podrás editar cada opción. ",
        },
        {
            id:8,
            questionsType: "bookings",
            question: "¿Cómo puedo realizar modificaciones en la reserva?",
            answer: `Las reservas confirmadas no podrán ser modificadas por la plataforma, comunícate con el
            grupo de atención al cliente de alohar.`,
        },
        {
            id:9,
            questionsType: "bookings",
            question: "¿Cómo sé que mi reserva es segura?",
            answer: "Alohar garantiza por pasarelas de pago que la reserva sea segura, utilizando estrictos protocolos de seguridad.",
        },
        {
            id:10,
            questionsType: "bookings",
            question: "¿Dónde puedo encontrar las indicaciones sobre la entrada al alohamiento y los demás datos sobre la llegada?",
            answer: "Nuestro Alohadores, suelen compartir la información de llegada por mensajes dentro de la plataforma.<br />Deberás estar en contacto con el alohador por medio de nuestra plataforma para acordar el horario de ingreso a la propiedad.",
        },
        {
            id:11,
            questionsType: "bookings",
            question: "No logro encontrar el establecimiento que he reservado online. ¿Qué debo hacer?",
            answer: "Deberás comunicarte con el propietario por medio de la plataforma, en caso de que esto no prospere, te contactas con hola@alohar.me",
        },
        {
            id:12,
            questionsType: "bookings",
            question: "¿Cómo puedo hacer un seguimiento de los pagos de las reservas?",
            answer: "Dentro de <a href='/profile/housing'>Mis Alohamientos</a>, “reservas”.",
        },
        {
            id:14,
            questionsType: "bookings-comms",
            question: "¿Cómo me pongo en contacto con el propietario?",
            answer: "Hasta el ingreso a la propiedad el contacto con el propietario va a ser por medio de la plataforma.",
        },
        {
            id:15,
            questionsType: "bookings-comms",
            question: "¿Cómo puedo ponerme en contacto con mis huéspedes?",
            answer: "Te deberás contactar por medio del chat interno dentro de la plataforma.",
        },
        {
            id:16,
            questionsType: "bookings-comms",
            question: "¿Qué es mi índice de respuesta y por qué es importante?",
            answer: "El índice de respuesta le da mejor posicionamiento al propietario, y a medida que vaya generando mayores respuestas y mayor puntuación, se convierte en súper alohador.",
        },
        {
            id:17,
            questionsType: "bookings-comms",
            question: "¿Por qué es importante responder en 24 horas?",
            answer: "Porque mientras más rápido respondas mejor visualización de tu propiedad vas a tener.",
        },
        {
            id:18,
            questionsType: "bookings-comms",
            question: "¿Cómo gestiono las solicitudes de reserva y de información?",
            answer: "Dentro de la plataforma, recibirás un mail con la solicitud de un huésped para conocer tu propiedad, luego podrás interactuar con él por medio del chat dentro de la plataforma alohar.me",
        },
        {
            id:19,
            questionsType: "bookings-comms",
            question: "¿Por qué los huéspedes no pueden pagarme/ponerse en contacto conmigo directamente?",
            answer: "El objetivo de alohar es generar una comunidad de alohadores, en donde ambas partes puedan contratar un servicio fácil, seguro y en pesos.",
        },
        {
            id:20,
            questionsType: "bookings-comms",
            question: "¿Cómo respondo a los huéspedes?",
            answer: "En el chat dentro de la plataforma, previamente recibirás un mail con la inquietud de huésped.",
        },
        {
            id:21,
            questionsType: "bookings-comms",
            question: "¿Cómo escribo una opinión sobre un alquiler?",
            answer: "Una vez cumplida la estadía, recibirás un correo en donde puntuarás el alohador y la propiedad en donde estuviste alohada.",
        },
        {
            id:22,
            questionsType: "cancelled",
            question: "Han cancelado mi reserva. ¿Dónde voy a alojarme ahora?",
            answer: "Si el alohador cancela la reserva por algún motivo, tendrás que buscar un nuevo alohamiento en la plataforma.",
        },
        {
            id:23,
            questionsType: "cancelled",
            question: "¿Cómo puedo cancelar mi reserva? (para huéspedes)",
            answer: "Te deras contactar con el alohador y explicarle los motivos por los cuales se cancela la reserva, estarás sujeto a las distintos tipos de cancelacion eligio el propietario para publicar su anuncio.<br /><br /><b>Flexible</b><br />Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si comunican su decisión de cancelar con una anticipación no menor a 24 horas antes del Check in*. Caso contrario no habra derecho alguno a reembolso. Una vez operado el ingreso  por parte del huesped y hallándose en uso de los servicios, si este efectuare por su decision abandono de los mismos, carecera de derecho alguno a reembolso por las noches no utilizadas.<br /><b>Moderada</b><br />Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si cancelan al menos 7 días antes del Check in*.<br /><b>Estricta</b><br />Los huéspedes recibirán un reembolso del 50% (menos la tarifa de servicio del huésped) si cancelan al menos 30 días antes del Check in*<br /><b>Muy Estricta</b><br />Si el huésped cancela la reserva, no se le reembolsará ningún importe abonado.",
        },
        {
            id:24,
            questionsType: "cancelled",
            question: "¿Qué debería hacer si se produce una situación de emergencia que pueda afectar a mi reserva?",
            answer: "Deberías comunicarte con el alohador y plantearle la cuestión.",
        },
        {
            id:25,
            questionsType: "cancelled",
            question: "¿Cuándo recibiré la devolución?",
            answer: "La devolución dependerá de cada modalidad de pago y entidad bancaria quedando sujeta a los plazos de los mismos para su devolución. El huésped contará con el soporte del área de atención de alohar para un seguimiento de ser necesario.",
        },
        {
            id:26,
            questionsType: "cancelled",
            question: "¿Cómo puedo reclamar un reembolso?",
            answer: "En nuestro correo hola@alohar.me",
        },
        {
            id:27,
            questionsType: "taxes",
            question: "¿Tengo que pagar algún tipo de impuesto o IVA?",
            answer: "Alohar cumple el rol de intermediario entre partes y factura únicamente las comisiones por el servicio prestado, la facturación del saldo restante corresponde exclusivamente al Alohador.",
        },
        {
            id:28,
            questionsType: "taxes",
            question: "¿Qué comisiones debo pagar? (para propietarios)",
            answer: "6,5%* si sos alohador y 11,5%* si sos huésped. *Valores sin iva incluido",
        },
        {
            id:29,
            questionsType: "taxes",
            question: "¿En qué monedas puedo recibir los pagos de los huéspedes?",
            answer: "En pesos argentinos.",
        },
        {
            id:30,
            questionsType: "taxes",
            question: "¿Por qué no he recibido el pago de un huésped?",
            answer: "Los pagos a alohadores serán emitidos a los 5 días hábiles posteriores al check in del huésped. Para aquellas reservas generadas con al menos 20 días de antelación al ingreso, la fecha del envío de dinero puede ser mayor debido a la demora en el proceso de cobro de las tarjetas y bancos pudiendo llegar a ser superior a los 10 días hábiles posterior al ingreso del huésped. En cualquier caso, Alohar no se responsabiliza por demoras de gestión de entidades bancarias o de cobro.",
        },
        {
            id:31,
            questionsType: "taxes",
            question: "¿Debería recibir los pagos en mi cuenta bancaria?",
            answer: "Si, en la cuenta que ingresaron en “datos bancarios” al cargar la propiedad.",
        },
        {
            id:32,
            questionsType: "accountabilities",
            question: "¿Cómo puedo denunciar un problema ocurrido durante mi estancia?",
            answer: "Debes enviar un mail a hola@alohar.me",
        },
        {
            id:33,
            questionsType: "accountabilities",
            question: "¿Cómo desactivo mi perfil?",
            answer: "Para dar de baja, debes enviar un email a hola@alohar.me y solicitar la baja, el equipo de aloha te ayudará en tu pedido.",
        }, 
        {
            id:34,
            questionsType: "cancelled",
            question: "¿Cómo puedo pagar mi reserva?",
            answer: "El proceso de reserva es únicamente por la plataforma con tarjeta de crédito en 6,12 o 18 cuotas, también se puede abonar con tarjeta de débito y además se puede realizar una transferencia directa, esta última tendrá un beneficio de un 2% de descuento para el huésped. </bn> Se paga el total de la estadía una vez que la reserva está confirmada por el alohador.",
        },
        
    ];


    export const mostCommonQuestionsEn = [
        {
            id: 1,
            questionsType: "covid",
            question: "About the coronavirus outbreak (COVID-19)",
            answer: "The aim of our hosts is to maintain hygiene and the established public knowledge norms, maintaining social distancing. At Alohar, we promote following the protocol defined for the rental of accommodation spaces.<br /><br /><a href='http://www.turismomardelplata.gob.ar/pdf_covid/Alojamiento%20Extra%20Hotelero.pdf' target='_blank'>See Protocol</a>"
        },
        {
            id: 2,
            questionsType: "register",
            question: "How do I register on Alohar?",
            answer: "In the upper right corner of the Alohar homepage, you should click on the icon and select the <a href='/register'>Register</a> option. You can access through your email or Facebook account. You must always log in through the same medium."
        },
        {
            id: 3,
            questionsType: "login",
            question: "How do I complete the verification process to access my account?",
            answer: "When you register, you will receive an email to confirm your identity. Once confirmed, you can log in."
        },
        {
            id: 4,
            questionsType: "login",
            question: "How do I log in?",
            answer: "In the upper right corner of the Alohar homepage, you should click on the icon and select the <a href='/login'>Log In</a> option. And enter with the option selected when registering. With Gmail, Facebook, or email and password."
        },
        {
            id: 5,
            questionsType: "password",
            question: "What should I do to change my password?",
            answer: "If you forgot your password, simply go to the 'Log In' option in the menu located on the main page in the upper right margin. Once the window opens, to log in, you must click on the 'forgot my password' option. <br /><br />If you want to change your password, you can do so from the main menu once you have logged in. In the <a href='/profile'>My Profile</a> option, you will find the 'Change Password' link."
        },
        {
            id: 6,
            questionsType: "profile-configuration",
            question: "How do I modify my contact settings?",
            answer: "Once logged in, access the main menu in the upper right margin. Select the <a href='/profile'>My Profile</a> option, then 'edit profile', and there you can modify all your personal and contact information."
        },
        {
            id: 7,
            questionsType: "housing-configuration",
            question: "How do I configure my accommodation?",
            answer: "In the main menu, I enter <a href='/profile/housing'>My Accommodations</a>. In my notice, I select the 'Edit' option. And a window will open where you can change all the settings of your accommodation: From this panel, you can edit each option."
        },
        {
            id: 8,
            questionsType: "bookings",
            question: "How can I make modifications to the reservation?",
            answer: `Confirmed reservations cannot be modified by the platform, contact the
            Alohar customer service group.`
        },
        {
            id: 9,
            questionsType: "bookings",
            question: "How do I know my reservation is secure?",
            answer: "Alohar guarantees through payment gateways that the reservation is secure, using strict security protocols."
        },
        {
            id: 10,
            questionsType: "bookings",
            question: "Where can I find instructions for entering the accommodation and other arrival details?",
            answer: "Our Hosts usually share arrival information through messages within the platform.<br />You must be in contact with the host through our platform to agree on the check-in time at the property."
        },
        {
            id: 11,
            questionsType: "bookings",
            question: "I can't find the establishment I booked online. What should I do?",
            answer: "You must communicate with the owner through the platform, if this does not work out, contact hola@alohar.me"
        },
        {
            id: 12,
            questionsType: "bookings",
            question: "How can I track reservation payments?",
            answer: "Within <a href='/profile/housing'>My Accommodations</a>, 'reservations'."
        },
        {
            id: 14,
            questionsType: "bookings-comms",
            question: "How do I get in touch with the owner?",
            answer: "Until entry to the property, contact with the owner will be through the platform."
        },
        {
            id: 15,
            questionsType: "bookings-comms",
            question: "How can I contact my guests?",
            answer: "You must contact them through the internal chat within the platform."
        },
        {
            id: 16,
            questionsType: "bookings-comms",
            question: "What is my response rate and why is it important?",
            answer: "The response rate gives better positioning to the owner, and as it generates more responses and higher scores, it becomes a super host."
        },
        {
            id: 17,
            questionsType: "bookings-comms",
            question: "Why is it important to respond within 24 hours?",
            answer: "Because the faster you respond, the better visibility your property will have."
        },
        {
            id: 18,
            questionsType: "bookings-comms",
            question: "How do I manage reservation and information requests?",
            answer: "Within the platform, you will receive an email with a guest's request to know your property, then you can interact with him through the chat within the alohar.me platform."
        },
        {
            id: 19,
            questionsType: "bookings-comms",
            question: "Why can't guests pay me/contact me directly?",
            answer: "The aim of alohar is to generate a community of hosts, where both parties can hire an easy, safe, and peso-based service."
        },
        {
            id: 20,
            questionsType: "bookings-comms",
            question: "How do I respond to guests?",
            answer: "In the chat within the platform, you will previously receive an email with the guest's concern."
        },
        {
            id: 21,
            questionsType: "bookings-comms",
            question: "How do I write a review about a rental?",
            answer: "Once the stay is completed, you will receive an email where you will rate the host and the property where you stayed."
        },
        {
            id: 22,
            questionsType: "cancelled",
            question: "My reservation has been canceled. Where will I stay now?",
            answer: "If the host cancels the reservation for any reason, you will have to look for a new accommodation on the platform."
        },
        {
            id: 23,
            questionsType: "cancelled",
            question: "How can I cancel my reservation? (for guests)",
            answer: "You must contact the host and explain the reasons for canceling the reservation, you will be subject to the different cancellation types chosen by the owner to publish their ad.<br /><br /><b>Flexible</b><br />Guests will receive a full refund of the reservation (minus the guest service fee) if they communicate their decision to cancel with at least 24 hours' notice before Check-in*. Otherwise, there will be no right to a refund. Once the guest has entered and is using the services, if he/she decides to abandon them, he/she will have no right to a refund for the unused nights.<br /><b>Moderate</b><br />Guests will receive a full refund of the reservation (minus the guest service fee) if they cancel at least 7 days before Check-in*.<br /><b>Strict</b><br />Guests will receive a 50% refund (minus the guest service fee) if they cancel at least 30 days before Check-in*.<br /><b>Very Strict</b><br />If the guest cancels the reservation, no amount paid will be refunded."
        },
        {
            id: 24,
            questionsType: "cancelled",
            question: "What should I do if an emergency situation occurs that may affect my reservation?",
            answer: "You should contact the host and raise the issue."
        },
        {
            id: 25,
            questionsType: "cancelled",
            question: "When will I receive the refund?",
            answer: "The refund will depend on each payment method and banking entity, subject to their respective deadlines for refund. The guest will have the support of the alohar attention area for follow-up if necessary."
        },
        {
            id: 26,
            questionsType: "cancelled",
            question: "How can I claim a refund?",
            answer: "In our email hola@alohar.me"
        },
        {
            id: 27,
            questionsType: "taxes",
            question: "Do I have to pay any taxes or VAT?",
            answer: "Alohar acts as an intermediary between parties and invoices only the commissions for the service provided, the billing of the remaining balance corresponds exclusively to the Host."
        },
        {
            id: 28,
            questionsType: "taxes",
            question: "What commissions do I have to pay? (for owners)",
            answer: "6.5%* if you are a host and 11.5%* if you are a guest. *Values ​​without VAT included"
        },
        {
            id: 29,
            questionsType: "taxes",
            question: "In what currencies can I receive guest payments?",
            answer: "In Argentine pesos."
        },
        {
            id: 30,
            questionsType: "taxes",
            question: "Why haven't I received payment from a guest?",
            answer: "Payments to hosts will be issued 5 business days after the guest's check-in. For those reservations made at least 20 days in advance of entry, the money transfer date may be longer due to the delay in the card and bank collection process, and may be more than 10 business days after the guest's entry. In any case, Alohar is not responsible for delays in bank or collection entity management."
        },
        {
            id: 31,
            questionsType: "taxes",
            question: "Should I receive payments in my bank account?",
            answer: "Yes, in the account entered in 'bank details' when loading the property."
        },
        {
            id: 32,
            questionsType: "accountabilities",
            question: "How can I report a problem that occurred during my stay?",
            answer: "You must send an email to hola@alohar.me"
        },
        {
            id: 33,
            questionsType: "accountabilities",
            question: "How do I deactivate my profile?",
            answer: "To unsubscribe, you must send an email to hola@alohar.me and request unsubscribing, the aloha team will assist you in your request."
        },
        {
            id: 34,
            questionsType: "cancelled",
            question: "How can I pay for my reservation?",
            answer: "The reservation process is only through the platform with a credit card in 6,12, or 18 installments, it can also be paid with a debit card and a direct transfer can also be made, the latter will have a benefit of a 2% discount for the guest. </bn> The total stay is paid once the reservation is confirmed by the host."
        }
    ];
    
    export const mostCommonQuestionsPo = [
        {
            id: 1,
            questionsType: "covid",
            question: "Sobre o surto de coronavírus (COVID-19)",
            answer: "O objetivo de nossos anfitriões é manter a higiene e as normas estabelecidas de conhecimento público, mantendo o distanciamento social. No Alohar, promovemos o cumprimento do protocolo definido para o aluguel de espaços de acomodação.<br /><br /><a href='http://www.turismomardelplata.gob.ar/pdf_covid/Alojamiento%20Extra%20Hotelero.pdf' target='_blank'>Ver Protocolo</a>"
        },
        {
            id: 2,
            questionsType: "register",
            question: "Como faço para me registrar no Alohar?",
            answer: "No canto superior direito da página inicial do Alohar, você deve clicar no ícone e selecionar a opção <a href='/register'>Registrar</a>. Você pode acessar através do seu email ou conta do Facebook. Você deve sempre fazer login pelo mesmo meio."
        },
        {
            id: 3,
            questionsType: "login",
            question: "Como completo o processo de verificação para acessar minha conta?",
            answer: "Ao se registrar, você receberá um email para confirmar sua identidade. Uma vez confirmado, você pode fazer login."
        },
        {
            id: 4,
            questionsType: "login",
            question: "Como faço para fazer login?",
            answer: "No canto superior direito da página inicial do Alohar, você deve clicar no ícone e selecionar a opção <a href='/login'>Fazer Login</a>. E entre com a opção selecionada no momento do registro. Com Gmail, Facebook ou email e senha."
        },
        {
            id: 5,
            questionsType: "password",
            question: "O que devo fazer para alterar minha senha?",
            answer: "Se você esqueceu sua senha, basta ir para a opção 'Fazer Login' no menu localizado na página principal no margem superior direita. Uma vez que a janela abrir, para fazer login, você deve clicar na opção 'esqueci minha senha'. <br /><br />Se você quiser alterar sua senha, pode fazer isso a partir do menu principal depois de fazer login. Na opção <a href='/profile'>Meu Perfil</a>, você encontrará o link 'Alterar Senha'."
        },
        {
            id: 6,
            questionsType: "profile-configuration",
            question: "Como modifico minhas configurações de contato?",
            answer: "Depois de fazer login, acesse o menu principal no margem superior direita. Selecione a opção <a href='/profile'>Meu Perfil</a>, depois 'editar perfil', e lá você pode modificar todas as suas informações pessoais e de contato."
        },
        {
            id: 7,
            questionsType: "housing-configuration",
            question: "Como configuro minha acomodação?",
            answer: "No menu principal, entro em <a href='/profile/housing'>Minhas Acomodações</a>. No meu aviso, seleciono a opção 'Editar'. E uma janela será aberta onde você pode alterar todas as configurações da sua acomodação: A partir deste painel, você pode editar cada opção."
        },
        {
            id: 8,
            questionsType: "bookings",
            question: "Como posso fazer modificações na reserva?",
            answer: `As reservas confirmadas não podem ser modificadas pela plataforma, entre em contato com o
            grupo de atendimento ao cliente da alohar.`
        },
        {
            id: 9,
            questionsType: "bookings",
            question: "Como sei que minha reserva é segura?",
            answer: "O Alohar garante por meio de gateways de pagamento que a reserva é segura, usando protocolos de segurança rigorosos."
        },
        {
            id: 10,
            questionsType: "bookings",
            question: "Onde posso encontrar instruções para entrar na acomodação e outros detalhes de chegada?",
            answer: "Nossos Anfitriões geralmente compartilham informações de chegada por mensagens dentro da plataforma.<br />Você deve estar em contato com o anfitrião através de nossa plataforma para concordar com o horário de check-in na propriedade."
        },
        {
            id: 11,
            questionsType: "bookings",
            question: "Não consigo encontrar o estabelecimento que reservei online. O que devo fazer?",
            answer: "Você deve se comunicar com o proprietário através da plataforma, se isso não der certo, entre em contato com hola@alohar.me"
        },
        {
            id: 12,
            questionsType: "bookings",
            question: "Como posso acompanhar os pagamentos da reserva?",
            answer: "Dentro de <a href='/profile/housing'>Minhas Acomodações</a>, 'reservas'."
        },
        {
            id: 14,
            questionsType: "bookings-comms",
            question: "Como posso entrar em contato com o proprietário?",
            answer: "Até a entrada na propriedade, o contato com o proprietário será através da plataforma."
        },
        {
            id: 15,
            questionsType: "bookings-comms",
            question: "Como posso entrar em contato com meus hóspedes?",
            answer: "Você deve entrar em contato com eles através do chat interno dentro da plataforma."
        },
        {
            id: 16,
            questionsType: "bookings-comms",
            question: "Qual é minha taxa de resposta e por que é importante?",
            answer: "A taxa de resposta dá melhor posicionamento ao proprietário e, à medida que gera mais respostas e pontuações mais altas, se torna um super anfitrião."
        },
        {
            id: 17,
            questionsType: "bookings-comms",
            question: "Por que é importante responder dentro de 24 horas?",
            answer: "Porque quanto mais rápido você responder, melhor visibilidade terá sua propriedade."
        },
        {
            id: 18,
            questionsType: "bookings-comms",
            question: "Como gerencio solicitações de reserva e informações?",
            answer: "Dentro da plataforma, você receberá um email com uma solicitação de um hóspede para conhecer sua propriedade, então você pode interagir com ele através do chat dentro da plataforma alohar.me."
        },
        {
            id: 19,
            questionsType: "bookings-comms",
            question: "Por que os hóspedes não podem me pagar/contatar diretamente?",
            answer: "O objetivo da alohar é gerar uma comunidade de anfitriões, onde ambas as partes possam contratar um serviço fácil, seguro e em pesos."
        },
        {
            id: 20,
            questionsType: "bookings-comms",
            question: "Como respondo aos hóspedes?",
            answer: "No chat dentro da plataforma, você receberá previamente um email com a preocupação do hóspede."
        },
        {
            id: 21,
            questionsType: "bookings-comms",
            question: "Como escrevo uma avaliação sobre um aluguel?",
            answer: "Depois que a estadia for concluída, você receberá um email onde avaliará o anfitrião e a propriedade onde você se hospedou."
        },
        {
            id: 22,
            questionsType: "cancelled",
            question: "Minha reserva foi cancelada. Onde vou ficar agora?",
            answer: "Se o anfitrião cancelar a reserva por qualquer motivo, você terá que procurar um novo alojamento na plataforma."
        },
        {
            id: 23,
            questionsType: "cancelled",
            question: "Como posso cancelar minha reserva? (para hóspedes)",
            answer: "Você deve entrar em contato com o anfitrião e explicar os motivos para o cancelamento da reserva, você estará sujeito aos diferentes tipos de cancelamento escolhidos pelo proprietário para publicar seu anúncio.<br /><br /><b>Flexível</b><br />Os hóspedes receberão um reembolso total da reserva (menos a taxa de serviço do hóspede) se comunicarem sua decisão de cancelar com pelo menos 24 horas de antecedência ao Check-in*. Caso contrário, não haverá direito a reembolso. Uma vez que o hóspede tenha entrado e esteja usando os serviços, se ele decidir abandoná-los, ele não terá direito a reembolso pelas noites não utilizadas.<br /><b>Modero</b><br />Os hóspedes receberão um reembolso total da reserva (menos a taxa de serviço do hóspede) se cancelarem pelo menos 7 dias antes do Check-in*.<br /><b>Estrito</b><br />Os hóspedes receberão um reembolso de 50% (menos a taxa de serviço do hóspede) se cancelarem pelo menos 30 dias antes do Check-in*.<br /><b>Muito Estrito</b><br />Se o hóspede cancelar a reserva, nenhum valor pago será reembolsado."
        },
        {
            id: 24,
            questionsType: "cancelled",
            question: "O que devo fazer se ocorrer uma situação de emergência que possa afetar minha reserva?",
            answer: "Você deve entrar em contato com o anfitrião e levantar a questão."
        },
        {
            id: 25,
            questionsType: "cancelled",
            question: "Quando receberei o reembolso?",
            answer: "O reembolso dependerá de cada método de pagamento e entidade bancária, sujeito aos respectivos prazos para reembolso. O hóspede terá o apoio da área de atenção da alohar para acompanhamento, se necessário."
        },
        {
            id: 26,
            questionsType: "cancelled",
            question: "Como posso solicitar um reembolso?",
            answer: "Em nosso email hola@alohar.me"
        },
        {
            id: 27,
            questionsType: "taxes",
            question: "Eu tenho que pagar algum imposto ou VAT?",
            answer: "A Alohar atua como intermediária entre as partes e fatura apenas as comissões pelo serviço prestado, a faturação do saldo restante cabe exclusivamente ao Anfitrião."
        },
        {
            id: 28,
            questionsType: "taxes",
            question: "Quais comissões devo pagar? (para proprietários)",
            answer: "6,5%* se você for anfitrião e 11,5%* se for hóspede. *Valores sem IVA incluído"
        },
        {
            id: 29,
            questionsType: "taxes",
            question: "Em quais moedas posso receber pagamentos de hóspedes?",
            answer: "Em pesos argentinos."
        },
        {
            id: 30,
            questionsType: "taxes",
            question: "Por que não recebi pagamento de um hóspede?",
            answer: "Os pagamentos aos anfitriões serão emitidos 5 dias úteis após o check-in do hóspede. Para aquelas reservas feitas com pelo menos 20 dias de antecedência da entrada, a data de transferência do dinheiro pode ser mais longa devido ao atraso no processo de cobrança do cartão e do banco, e pode ser mais de 10 dias úteis após a entrada do hóspede. Em qualquer caso, a Alohar não se responsabiliza por atrasos na gestão bancária ou de cobrança."
        },
        {
            id: 31,
            questionsType: "taxes",
            question: "Devo receber pagamentos na minha conta bancária?",
            answer: "Sim, na conta inserida em 'detalhes do banco' ao carregar a propriedade."
        },
        {
            id: 32,
            questionsType: "accountabilities",
            question: "Como posso relatar um problema que ocorreu durante minha estadia?",
            answer: "Você deve enviar um email para hola@alohar.me"
        },
        {
            id: 33,
            questionsType: "accountabilities",
            question: "Como desativo meu perfil?",
            answer: "Para cancelar a inscrição, você deve enviar um email para hola@alohar.me e solicitar o cancelamento, a equipe de aloha o ajudará em sua solicitação."
        },
        {
            id: 34,
            questionsType: "cancelled",
            question: "Como posso pagar minha reserva?",
            answer: "O processo de reserva é apenas através da plataforma com um cartão de crédito em 6,12 ou 18 parcelas, também pode ser pago com um cartão de débito e uma transferência direta também pode ser feita, esta última terá um benefício de um desconto de 2% para o hóspede. </bn> A estadia total é paga uma vez que a reserva é confirmada pelo anfitrião."
        }
    ];
    

    