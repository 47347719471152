import React from "react";
import LoadingGift from "../../../assets/components-icons/LoadingGift";
import "./button-primary.sass";

const ButtonPrimary = ({
  title,
  onClick,
  width,
  height,
  disabled,
  fontSize,
  secondaryColor,
  loading,
  type,
  className,
}) => {
  return (
    <button
      type={type ? type : ""}
      disabled={disabled}
     
      onClick={onClick}
      className={
        secondaryColor
          ? ` ${className} globalButtonSecondary  focus:outline-none focus:shadow-outline`
          : `${className} globalButtonPrimary  focus:outline-none focus:shadow-outline`
      }
      style={{
        width:  width ? width : "150px",
        height: height ? height : "55px",
        fontSize: fontSize && fontSize,
      }}
    >
      {loading ? <LoadingGift size={"90%"} /> : title}
    </button>
  );
};

export default ButtonPrimary;
