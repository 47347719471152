import React from "react";
import cross from "../../../assets/static/Icons/x_sombra.svg";

import SecondaryButton from "../../ui/secondary-button";
import "./global-modal.sass";
const GlobalModal = ({
  titleButton,
  onClick,
  onClose,
  children,
  backgroundImage,
  close,
  background
}) => {
  return (
    <div
      className={close ? "globalModal" : "displayNone"}
      style={{ background: background ? background : "rgba(000,000,000,0.7)" }}
    >
      <div
        className="globalModal__container"
        style={{
          backgroundImage: backgroundImage && `url( ${backgroundImage})`,
          background: backgroundImage ? backgroundImage : "#FF4E00",
          backgroundSize: backgroundImage && "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img
          onClick={onClose}
          className="globalModal__container__close"
          src={cross}
          alt={"icon"}
        />
        {children}
        <SecondaryButton
          onClick={onClick ? onClick : onClose}
          title={titleButton}
        />
      </div>
    </div>
  );
};

export default GlobalModal;
