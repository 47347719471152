// Librerías
import React from 'react';
import ReactGA from 'react-ga';
// Components
import CheckoutComponent from '../components/Checkout/CheckoutComponent';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import { useLanguage } from '../store/lenguaje/useLenguaje';

ReactGA.initialize('G-FB2CRDJ4XE');

const CheckoutContainer = () => {

  const { currentLang } = useLanguage();
  return(
    <React.Fragment>
      <Navbar />
      <CheckoutComponent lenguaje={currentLang} />
      <Footer />
    </React.Fragment>
  )
}

export default CheckoutContainer;