// Librerías
import React from "react";
import { Link } from "react-router-dom";
// Recursos
import "../../assets/styles/Footer/_footer.sass";
import facebook from "../../assets/static/Icons/facebook.svg";
import instagram from "../../assets/static/Icons/instagram.svg";
import terms from "../../assets/static/legal.pdf";
import privacy from "../../assets/static/privacidad.pdf";
import BLACK_LOGO_ALOHAR from "../../assets/static/Navbar/BLACK_LOGO_ALOHAR.svg";
import ArrowLeft from "../../assets/components-icons/ArrowLeft";
import { scroller } from "react-scroll";
import "../../assets/styles/Buttons/btn.sass";
import useCurrentLanguaje from "../../services/currentlenguaje";
import jsonFooter from "../../components/Footer/jsonFooter.json";

const Footer = () => {
  const scrollToHead = (id) => {
    scroller.scrollTo(id, {
      smooth: true,
      duration: 500,
      offset: 10,
      spy: true,
    });
  };

  const { handleFilterLang } = useCurrentLanguaje();

  return (
    <div className="Footer">
      <div className="Footer__container">
        <div className="col-start-1 Footer__logo sm:col-start-1 sm:">
          <Link to="/" rel="noopener noreferrer">
            <img src={BLACK_LOGO_ALOHAR} alt="Alohar TM" />
          </Link>
        </div>
        {scrollToHead && (
          <div className="flex items-center justify-center w-full h-full">
            <button
              aria-label="Scroll to head"
              onClick={() => scrollToHead(0)}
              className="col-start-2 Footer__logo sm:col-start-1 w-max
            rounded-[4px] border-solid border-2 border-cyan-600     sm:hidden "
            >
              <ArrowLeft color={"#38d1da"} className={" rotate-90"} />
            </button>
          </div>
        )}
        <div className="col-start-1 row-start-3 sm:col-start-2 sm:row-start-1 Footer__linkList">
          <Link
            rel="noopener noreferrer"
            to="/quiero-ser-alohador"
            className="underline"
          >
            {handleFilterLang(jsonFooter).quieroSerAlohador}
          </Link>
          <p className="underline Footer__smalltext">hola@alohar.me</p>
        </div>
        <div className="flex justify-between col-start-1 col-end-3 row-start-2 sm:row-start-1 Footer__linkList sm:col-start-3 ">
          <p className="grid grid-cols-2 gap-4">
            {handleFilterLang(jsonFooter).atencionAlohadores}
            
            <br className="hidden sm:block" />
            <a
              href="https://api.whatsapp.com/send?phone=5491128180202"
              target="_blank"
              rel="noopener noreferrer"
              className="Footer__smalltext"
            >
              +54 9 11 2818-0202
            </a>
          </p>
          <p className="grid grid-cols-2 gap-4">
            {handleFilterLang(jsonFooter).atencionHuespedes}

            <br className="hidden sm:block" />
            <a
              href="https://api.whatsapp.com/send?phone=5492233018749"
              target="_blank"
              rel="noopener noreferrer"
              className="Footer__smalltext"
            >
              +54 9 223 301 8749
            </a>
          </p>
        </div>

        <div className="flex items-center justify-center h-full col-start-2 row-start-3 gap-4 sm:items-start sm:col-start-3 sm:row-start-2 Footer__socialMedia">
          <a
            href="https://www.facebook.com/alohar.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="Footer__socialMedia__icon"
              src={facebook}
              alt="Facebook de Alohar"
            />
          </a>
          <a
            href="https://instagram.com/alohar.me/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="Footer__socialMedia__icon"
              src={instagram}
              alt="Instagram de Alohar"
            />
          </a>
        </div>
        <div className="col-start-1 row-start-4 sm:row-start-2 Footer__linkList sm:col-start-2 ">
          <a
            className="underline "
            href={terms}
            target="_blank"
            rel="noopener noreferrer"
          >
            {handleFilterLang(jsonFooter).termYCondiciones}
          </a>
         
          <a
            className="underline"
            href={privacy}
            target="_blank"
            rel="noopener noreferrer"
          >
            {handleFilterLang(jsonFooter).politicaPriv}
          </a>
          <Link rel="noopener noreferrer" className="underline" to={"/ayuda"}>
            FAQ
          </Link>
        </div>

        <p className="flex flex-col justify-end h-full col-start-1 col-end-3 row-start-6 sm:row-start-3 Footer__linkList sm:col-start-1 sm:col-end-3 ">
          2021-2023, Alohar.me | Buchu SA .
          <br /> {handleFilterLang(jsonFooter).copyright} EVT 18665 - Disp.
          0602/2023.
        </p>
        <p className="flex flex-col justify-end h-full col-start-1 col-end-3 row-start-7 sm:row-start-3 Footer__linkList sm:col-start-3 sm:col-end-5">
          {handleFilterLang(jsonFooter).providencia} N°
          PV-2023-18040486-APN-DRAV#MTYD
        </p>
      </div>
    </div>
  );
};

export default Footer;
