// Librerías
import React from 'react';
// Componentes
import Navbar from '../Navbar/Navbar';
// Recursos
import '../../../assets/styles/BackOffice/_mainContainer.sass';

const MainContainer = (props) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const name = `${userData.firstName} ${userData.lastName}`;
  const avatar = userData.avatar;
  const role = JSON.parse(localStorage.getItem('role')).role;

  return (
    <div className="MainContainer">
      <Navbar name={ name } avatar={ avatar } role={role} />
      <div className="MainContainer__right">
        <div className="MainContainer__right__titleContainer">
          <h2 className="MainContainer__right__titleContainer__title">
            { props.title }
          </h2>
        </div>
        <div className="MainContainer__right__container">
            { props.children }
        </div>
      </div>
    </div>
  );
}

export default MainContainer;