// Librerías
import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';
// Componentes
import Navbar from '../../components/Navbar/Navbar';
import ChangePassword from '../../components/Users/ChangePassword';


const ChangePasswordContainer = () => {
  return(
    <ScreenClassProvider>
      <Navbar />
      <ChangePassword />
    </ScreenClassProvider>
  );
}

export default ChangePasswordContainer;