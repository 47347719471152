// Librerías
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AxiosAuth from '../../../services/requestApi';
import { BASE_URL } from '../../../Helpers'
import { getProvinces } from '../../../services/api'
import { useHistory } from "react-router-dom";

// Componentes
import MainContainer from '../Utils/MainContainer';

const Create = ( props ) => {
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [location, setLocation] = useState('')
    const [lng, setLng] = useState(null);
    const [lat, setLat] = useState(null);
    
    ;
    const history = useHistory(); 

    const getProvinceList = async () => {
        try {
            const result = await getProvinces()
            setProvinces(result.data);
        } catch (error) {
            console.log(error)
        }

    };

    useEffect(() => {
        if(provinces.length === 0){
            getProvinceList();
        }
    },[]);

    const handleChange = (event)  =>{
        const name = event.target.name;
        const value = event.target.value;

        if(name === "location") setLocation(value)
        if(name === "lat") setLat(value)
        if(name === "lng") setLng(value)
    }

    const handleOnChangeProvince = (e) => {
        if (e.target.value !== ''){
            setSelectedProvince(e.target.value)
        }
    }

    const sendData = () =>{
      const url = `/location`;
      const data = {
        id: parseInt(selectedProvince),
        province: parseInt(selectedProvince),
        location: location,
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }

      AxiosAuth.post(url,data)
        .then((response) => {
          history.push("../locations");
        }).catch((error) => {
          console.log(error)
        });
    }

    const handleSubmit = () =>{
      sendData();
    }

    return (
        <MainContainer title="Agregar ubicación nueva">
            <>
                <div className="MainContainer__form">
                    {/* Provincia */}
                    <div className="MainContainer__form__group">
                        <label className="MainContainer__form__label" htmlFor="state">Provincia</label>
                        <select onChange={e => handleOnChangeProvince(e)} value={ selectedProvince } className="MainContainer__form__select" required>
                            <option selected>Seleccione una provincia</option>
                            {
                                provinces.sort((a,b)=>{ return (a.province < b.province) ? -1 : (a.province > b.province) ? 1 : 0; }).map((p) => {
                                    return <option value={p.id} key={"province_"+p.id}>{p.province}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="MainContainer__form__group">
                        <label htmlFor="location" className="MainContainer__form__label">Ubicación</label>
                        <input
                            type="text"
                            name="location"
                            id="location"
                            className="MainContainer__form__input"
                            placeholder="Localidad"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={location}
                            required
                        />
                    </div>
                    <div className="MainContainer__form__group">
                        <label htmlFor="lat" className="MainContainer__form__label">Latitud</label>
                        <input
                            type="number"
                            name="lat"
                            id="lat"
                            className="MainContainer__form__input"
                            placeholder="Latitud"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={lat}
                            required
                        />
                    </div>
                    <div className="MainContainer__form__group">
                        <label htmlFor="lng" className="MainContainer__form__label">Longitud</label>
                        <input
                            type="number"
                            name="lng"
                            id="lng"
                            className="MainContainer__form__input"
                            placeholder="Longitud"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={lng}
                            required
                        />
                    </div>

                    <div className="UserEditComponent__btns">
                        <button className="MainContainer__form__btn__outline" onClick={ () => history.push("../locations") }>
                            <p className="MainContainer__form__btn__outline__text">Volver</p>
                        </button>
                        <button className="MainContainer__form__btn" onClick={ handleSubmit }>Agregar</button>
                    </div>
                
                </div>
            </>
        </MainContainer>
    )
}

export default Create;
