// Librerías
import React from 'react'
// Componentes
import AppContainerNavbar from '../../components/Containers/AppContainerNavbar';
import Favorites from '../../components/Favorites/Favorites';

const UserFavoritesPage = () => {
  return (
    <AppContainerNavbar>
      <Favorites />
    </AppContainerNavbar>
  )
}

export default UserFavoritesPage;