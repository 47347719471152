// Librerías
import React from "react";
import ReactGA from "react-ga";
// Componentes
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import SearchPagePaginate from "../components/SearchPage/SearchPagePaginate";

ReactGA.initialize("G-SXE6FS3SVC");

const SearchPage = (props) => {
  return (
    <React.Fragment>
      <Navbar addSearch  />
      <SearchPagePaginate />
      <Footer />
    </React.Fragment>
  );
};

export default SearchPage;
