// Librerías
import React from "react";
import { Link } from "react-router-dom";
// Recursos
import logo from "../../../assets/static/logotipo.png";
import "../../../assets/styles/BackOffice/_bonavbar.sass";
import CanonicalUrl from "../../../canonical-url";

const Navbar = (props) => {
  return (
    <div className="BONavbar">
      <CanonicalUrl />

      {/* User Data + Logo */}
      <div className="BONavbar__upperside">
        <Link className="BONavbar__upperside__links" to="/">
          <img
            src={logo}
            className="BONavbar__upperside__links__logo"
            alt="Alohar Logo"
          />
        </Link>
      </div>
      {/* Menú */}
      <div className="BONavbar__downside">
        <div className="BONavbar__downside__menu">
          <Link className="BONavbar__downside__menu__links" to="/back-office/">
            Dashboard
          </Link>
          <Link
            className="BONavbar__downside__menu__links"
            to="/back-office/transfer"
          >
            Transferencias
          </Link>
          <Link
            className="BONavbar__downside__menu__links"
            to="/back-office/housing"
          >
            Publicaciones
          </Link>
          <Link
            className="BONavbar__downside__menu__links"
            to="/back-office/users"
          >
            Usuarios
          </Link>
          <Link
            className="BONavbar__downside__menu__links"
            to="/back-office/locations"
          >
            Ubicaciones
          </Link>
          <Link
            className="BONavbar__downside__menu__links"
            to="/back-office/reservations"
          >
            Reservas
          </Link>
          <Link
            className="BONavbar__downside__menu__links"
            to="/back-office/reservations/confirmed"
          >
            Reservas Confirmadas
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
