// Librerías
import React from 'react'
import { ScreenClassProvider } from 'react-grid-system';
import {connect} from 'react-redux'
// Componentes
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer'
import MainUserProfile from '../components/UserProfilePage/MainUserProfile';
// Assets
import '../assets/styles/UserProfilePage/_user_profile.sass';
import SideMenu from '../components/Utils/SideMenu';
import ProfileCard from '../components/ProfileCard/ProfileCard';

const UserProfilePage = () => {
    return(
        <ScreenClassProvider>
            <div className="user_profile_page">
                <Navbar isLogged  />
                <div className="UserContainer">
                    <div className='UserContainer__sideBar'>
                        <SideMenu />
                    </div>  
                        <ProfileCard />
                </div> 
            </div>
            <Footer/>
        </ScreenClassProvider>
    );
}

export default connect()(UserProfilePage)
