// Librerías
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../Helpers';
// Componentes
import ProgressBar from './PropertyProgressBar';
// Recursos
import '../../../assets/styles/PropertyPage/Utils/_reviews.sass';
import star from '../../../assets/static/star.svg';
import ValuationContainer from '../../Utils/ValuationContainer';
import propertyPageJson from "../tradPropertyPage.json";
import useCurrentLanguage from "../../../services/currentlenguaje";


const PropertyReviews = ({housing}) => {
  const { handleFilterLang } = useCurrentLanguage(); 

  const [valuations, setValuations] = useState([]);
  const [avgValues, setAvgValues] = useState([]);
  // const [realAverage, setRealAverage] = useState(0);

  const getValuations = (housingId) =>{
    const url = `${BASE_URL}/housing/${housingId}/valuations`
    axios.get(url).then((response)=>{
      const val = response.data;
      setValuations(val);
    }).catch((e)=>{console.log(e)})
  }

  const getAvgValuations = (housingId) =>{
    const url = `${BASE_URL}/housing/${housingId}/valuations/average`
    axios.get(url).then((response)=>{
      const values = response.data;
      setAvgValues(values);
    }).catch((e)=>{console.log(e)})
  }

  const average = obj => {
    let count = obj.length;
    let sum = obj.reduce((a, c) => a + parseInt(c.avg), 0);
    let res = sum / count;
    if(res % 1 === 0){
      return res
    } else {
      return parseFloat(res).toFixed(2);
    }
  }

  const listReviews = valuations.map((item) => <ValuationContainer valuation={ item } key={ item.reservation.id }/>);

  const mappedData = avgValues.map((item) => {

    return(
      <div className="PropertyReviews__progressBars__container" key={ item.topic }>
        <p className="">
          {item.topic}
        </p>
        <ProgressBar progress={ parseInt(item.avg) } />
        <p className="PropertyReviews__progressBars__container__number">
          { parseInt(item.avg)}
        </p>
      </div>
    );
  });

  useEffect(() => {
    getValuations(housing);
    getAvgValuations(housing);
  }
  , []);

  return(
    <React.Fragment>
      <div className="PropertyReviews">
        <h2 className="PropertyReviews__title">
          {
            avgValues && avgValues.length !== 0 
            ?
              <>
                <img src={ star } height="16" alt=
            "evaluaciones"
            
            />&nbsp;{average(avgValues)}&nbsp;({ valuations.length } { (valuations.length === 1) ? handleFilterLang(propertyPageJson).evaluacion
              : handleFilterLang(propertyPageJson).evaluaciones})
              </>
            :
            handleFilterLang(propertyPageJson).sinEvaluaciones
          }
        </h2>
        {
          avgValues && avgValues.length !== 0 
          ?
            <>
              <div className="PropertyReviews__progressBars">
                { mappedData }
              </div>
              <div className="PropertyReviews__reviews">
                { listReviews }
              </div>
            </>
          :
            ""
        }
        {/* 
        <div className="PropertyReviews__more">
          <div className="PropertyReviews__more__separator"></div>
          <button className="PropertyReviews__more__btn">Mostrar más evaluaciones</button>
          <div className="PropertyReviews__more__separator"></div>
        </div> 
        */}
      </div>
    </React.Fragment>
  );
}

export default PropertyReviews;