import React, { useState } from "react";
import MessagesBackOffice from "../Messages";
import InputGlobal from "../../../components/ui/input-global";
import ButtonPrimary from "../../../components/ui/button-primary";
import AxiosAuth from "../../../services/requestApi";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import { BASE_URL } from "../../../Helpers";
import LayoutMessages from "../../../components/MessengersPage";
import Navbar from "../../../components/BackOffice/Navbar/Navbar";
import { DataProvider } from "../../../store/data-context";

const ViewMessages = () => {
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const verifyExistUser = () => {
    setLoading(true);
    AxiosAuth.get(`${BASE_URL}/user/get-profile/${userId}`)
      .then((response) => {
        setUserId(response.data.id);
        setUserData(response.data);
        setLoading("success");
      })
      .catch((error) => {
        setLoading("error");
      });
  };
  return (
    <DataProvider>
      <Navbar />
      <div
        className={`${
          loading === "success"
            ? "hidden "
            : "flex flex-col absolute top-[50%]  left-[50%] translate-y-[-50%] translate-x-[-50%]  rounded-3xl px-6 pt-14 w-96 gap-4 m-auto h-80 border-2 border-black"
        }`}
      >
        <InputGlobal
          prefix={"Ingresar Id del usuario"}
          name={"userId"}
          value={userId}
          type={"text"}
          onChange={(e) => setUserId(e.target.value)}
        />
        <ButtonPrimary
          loading={loading === "success" || "error" || false ? false : true}
          title={"Ingresar"}
          className={"mx-auto "}
          onClick={() => verifyExistUser()}
        />
        {loading === "error" && (
          <ErrorAlert message={"Usuario no encontrado"} />
        )}
      </div>
      {loading === "success" && (
        <div className="absolute top-0 left-0 w-full">
          <LayoutMessages userId={Number(userId)} userData={userData} />
        </div>
      )}
    </DataProvider>
  );
};

export default ViewMessages;
