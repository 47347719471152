// Librerías
import React from 'react';
// Componentes
import View from '../../../components/BackOffice/Housing/View';

const HousingView = () => {
  return(
    <View />
  );
}

export default HousingView;